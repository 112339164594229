const utils = window.optlyUtils;

function editOptionLabels(durationSelect, placeholderOption) {

    utils.poll([() => { 
        const durationOptions = durationSelect.querySelectorAll('option');
        return durationOptions.length > 1 ? durationOptions : false;
    }], (durationOptions) => {
            placeholderOption.selected = false  ;
            const durationOptionsList = durationOptions[0];
            for (let index = 1; index < durationOptionsList.length; index++) {
                const option = durationOptionsList[index];
                const numOfNights = option.label;

                if (numOfNights.indexOf('night') === -1) {
                    numOfNights === "1" ? option.innerHTML = `${numOfNights} night` : option.innerHTML = `${numOfNights} nights`;
                }
        
            }
        }
    )
}

module.exports =  editOptionLabels;
