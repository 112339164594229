(() => {
    const utils = window["optimizely"].get('utils');

    utils.waitForElement('.login-flex').then((loginContainerElement) => {
        const isLoggedIn = document.body.classList.contains('sas-logged-in');
        const isApp = document.body.getAttribute('data-channel-name') === 'mobileapp';
        const title = isLoggedIn ? 'You\'re logged in to myJet2' : 'Log in to myJet2';
        const bookingsPageLink = isApp ? 'https://my-app.jet2.com/bookings' : 'https://my.jet2.com/bookings';
        const dynamicMessage = isLoggedIn
            ? `Log in to myJet2 to make <a href="${bookingsPageLink}">managing your bookings</a> even easier.`
            : `Logging in to myJet2 will make it easier to manage <a href="${bookingsPageLink}">your bookings</a> within your account.`;
        const bannerContent = `<aside class="info-box"><div class="info-box__icon"></div><h2 class="info-box__heading title-m">${title}</h2><p class="info-box__para">${dynamicMessage}</p><p class="info-box__para">Plus, we’ve recently enabled myJet2 members to request access to bookings even if they’re not the lead passenger.</p></aside>`;

        insertDivBeforeNode(loginContainerElement, bannerContent);
        pushToDatalayer('myJet2 Info Banner', 'Impression', title);
        document.querySelector('.cro-container a').addEventListener('click', () => pushToDatalayer('myJet2 Info Banner', 'Click', `${title} - ${isLoggedIn ? 'manage ' : ''}your bookings`));
    })

    function insertDivBeforeNode(node, htmlString) {
        const div = document.createElement('div');
        div.classList.add('cro-container');
        div.innerHTML = htmlString;
        node.parentNode.insertBefore(div, node);
    }

    function pushToDatalayer(cat = '', action = '', label = '') {
        window.dataLayer.push({
            event: 'mmb',
            category: cat,
            action: action,
            label: label,
        });
    }
})();
