const searchFilters = require("./searchFilters");
const holidayType = require("./getHolidayType");

function isChildrenSelected(childSelector, childrenSelected) {
    const filtersWrapper = document.querySelector('.search-box__filters');
    const childSelectorInput = childSelector.querySelector('input');
    const numOfChildren = parseInt(childSelectorInput.value);
    numOfChildren > 0 ? childrenSelected = true : childrenSelected = false;
    filtersWrapper.innerHTML = '';
    searchFilters(holidayType(), childrenSelected);
}

module.exports =  isChildrenSelected;
