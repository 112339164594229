console.log(
  '%c BF226 In resort fee info on SR v27',

  'background-color: #50BFE6; color: white; font-weight: bold;'
);

const utils = window.optlyUtils || window.globalUtils;
const opUtils = window.optimizely.get('utils');
let topScrollOffset = 0;
let closeIconClicked = false;
let modalOpened = false;

utils.poll(
  [
    '.detailed-card-wrapper .detailed-card',
    '[data-component*="ResultsContainer"]',
  ],
  ([SearchResultCards, ResultsContainer]) => {
    if (SearchResultCards) {
      SearchResultCards.forEach((card) => {
        card
          .querySelector('p.price-column__supplement-text')
          .classList.add('hide-for-control');
      });
    }

    if (ResultsContainer) {
      const ResultsObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            const targetElement = mutation.target;
            if (targetElement.classList.contains('show-loader')) {
              opUtils
                .waitForElement(
                  '.full-screen-loader[data-spin]:not(.full-screen-loader--active)'
                )
                .then(() => {
                  utils.poll(
                    [
                      '.detailed-card-wrapper .detailed-card',
                      '.resort-fees-modal',
                      '[data-component*="ResultsContainer"]',
                    ],
                    ([SearchResultCards, FeesModal, ResultsContainer]) => {
                      SearchResultCards.forEach((card) => {
                        card
                          .querySelector('p.price-column__supplement-text')
                          .classList.add('hide-for-control');
                      });
                    }
                  );
                });
            }
          }
        });
      });

      ResultsObserver.observe(ResultsContainer, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    document.body.classList.add('bf226');
  }
);

function pushToDatalayer(cat = '', action = '', label = '') {
  window.dataLayer.push({
    event: 'SR_Card',
    category: cat,
    action: action,
    label: label,
  });
}
