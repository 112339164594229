var utils = window.optlyUtils || window.globalUtils;
var opUtils = window.optimizely.get('utils');

utils.poll(['.r-desktop-header'], ([navBar]) => {
  main();
});

function main() {
  let navBarTabs = document.querySelector('.nav-bar__tabs');
  clonePhone(navBarTabs);
  cloneMMB(navBarTabs);
  moveSignIn(navBarTabs);
  moveNavSearch();
  rearrangeMobileHeader();

  document.body.classList.add('bf155--nav-bar');
}

function clonePhone(navBarTabs) {
  let phone = document.querySelector('.r-desktop-header__phone');
  let clonedPhone = phone.cloneNode(true);
  clonedPhone.classList.add('cloned-phone');

  navBarTabs.appendChild(clonedPhone);
}

function cloneMMB(navBarTabs) {
  let mmb = document.querySelector('.r-desktop-header__mmb');
  if (mmb) {
    let clonedmmb = mmb.cloneNode(true);
    clonedmmb.classList.add('cloned-mmb');
    mmb.insertAdjacentElement('afterend', clonedmmb);

    navBarTabs.appendChild(mmb);
    let mmbText = document.querySelector(
      '.r-desktop-header__mmb:not(.cloned-mmb) a.r-desktop-header__mmb-btn'
    );
    if (mmbText) {
      replaceMyText(mmbText);
    }
  }
}

function moveMMB(navBarTabs) {
  let mmb = document.querySelector('.r-desktop-header__mmb');
  if (mmb) {
    navBarTabs.appendChild(mmb);
    let mmbText = document.querySelector('a.r-desktop-header__mmb-btn');
    if (mmbText) {
      replaceMyText(mmbText);
    }
  }
}

function moveSignIn(navBarTabs) {
  let signInBtn = document.querySelector(
    '.r-desktop-header  .nav-menu__dropdown-container'
  );
  navBarTabs.appendChild(signInBtn);
}

function moveNavSearch() {
  let searchDestinationBtn = document.querySelector(
    '.r-desktop-header  .nav-bar__tabs-list'
  );
  let clonedPhone = document.querySelector(
    '.r-desktop-header__phone.cloned-phone'
  );
  if (searchDestinationBtn && clonedPhone) {
    clonedPhone.insertAdjacentElement('afterend', searchDestinationBtn);
  }
}

function rearrangeMobileHeader() {
  let mobileHeader = document.querySelector('.header-mobile-v2');
  let iconContainer = '<div class="header-mobile-v2__icon-container"></div>';
  mobileHeader.insertAdjacentHTML('beforeend', iconContainer);
  iconContainer = document.querySelector('.header-mobile-v2__icon-container');
  let accountIcon = document.querySelector(
    '.header-mobile-v2 .nav-menu__dropdown-container'
  );
  let burgerIcon = document.querySelector(
    '.header-mobile-v2 .header-mobile-v2__btn'
  );
  iconContainer.appendChild(accountIcon);
  iconContainer.appendChild(burgerIcon);
}

// Function to replace 'My' with '' in the text nodes
function replaceMyText(node) {
  if (node.nodeType === Node.TEXT_NODE) {
    node.nodeValue = node.nodeValue.replace(/\bMy\b/g, '');
  } else {
    node.childNodes.forEach(replaceMyText);
  }
}
