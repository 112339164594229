function selectDestinations(type = 'beach', cb) {
  const utils = window.optlyUtils;

  utils.poll([
    '.checkbox-button-group__item--indent'
  ], ([
    destinations
  ]) => {
    for (let i = 1; i < destinations.length; i++) {
      const destination = destinations[i];

      if (type === 'beach') {
        if (destination.querySelector('.checkbox-button-group__label-link>a')) {
          destination.click();
        }
      } else {
        if (!destination.querySelector('.checkbox-button-group__label-link>a')) {
          destination.click();
        }
      }
    }
    cb();
  });
}

module.exports = selectDestinations;
