const utils = window.optlyUtils || window.globalUtils;
utils.poll(['select#cancellation-actions:not([v-validate])', '.r-desktop-header__phone-subtext'], ([cancelSelect, callEl]) => {
  const parentSection = cancelSelect.closest('section');
  let callTimeCopy = callEl.innerText.split(' ')
  parentSection.classList.add('mvt-elective-options');
  callTimeCopy = callTimeCopy[callTimeCopy.length - 1];

  cancelSelect.addEventListener('change', function () {
    const selectValue = cancelSelect.options[cancelSelect.selectedIndex].text;
    const innerGrid = parentSection.querySelector('.grid-layout');
    if (selectValue === "Cancel holiday") {
      const insertionPoint = parentSection.querySelector('.grid-layout + div');
      const anchorPoint = parentSection.querySelector('.grid-layout + div + div + div');
      if (!parentSection.classList.contains('mvt-elective-options')) parentSection.classList.add('mvt-elective-options');
      if (!anchorPoint.classList.contains('mvt-elective-options__anchor')) anchorPoint.classList.add('mvt-elective-options__anchor');
      if (!innerGrid.classList.contains('margin--bottom-half')) innerGrid.classList.add('margin--bottom-half')
      insertionPoint.insertAdjacentHTML('afterend', getHtml());
      const optionCTA = parentSection.querySelector('.block-container .bttn--global-primary-outline');
      optionCTA.addEventListener('click', function(e) {
        e.preventDefault();
        if (parentSection.classList.contains('mvt-elective-options')) parentSection.classList.remove('mvt-elective-options');
        Velocity(anchorPoint, "scroll", { duration: 750, easing: "ease-in" });
      })
    } else {
      if (parentSection.classList.contains('mvt-elective-options')) parentSection.classList.remove('mvt-elective-options');
      if (innerGrid.classList.contains('margin--bottom-half')) innerGrid.classList.remove('margin--bottom-half')
      if (parentSection.querySelector('.block-container')) parentSection.querySelector('.block-container').remove();
    }
  });

  function getHtml() {
    return `<div class="block-container block-container--shaded">
  <div class="title-and-text">
    <h2 class="margin--bottom-half">Are you sure you want to cancel?</h2>
    <div class="title-and-text__content">
      <p>Please note, if you cancel your booking, you’ll be subject to cancellation costs.</p>
      <p><strong>Before you cancel your well-deserved holiday, speak to one of our expert travel advisors to see what other options we have for you.</strong></p>
    </div>
  </div>
  <div class="hide-large">
    <img src="/-/media/jet2holidays/images/components/info cards/beach/whatsapp/whatsapp_logo_.jpg?h=896&amp;w=1534&amp;la=en&amp;hash=246E470F2B5669E241071BA9A66260594B3F063D">
    <div class="title-and-text">
      <h3 class="margin--bottom-half">Message our whats app team</h3>
      <a href="https://wa.me/+441133860493" class="bttn bttn--global-confirm">Message now</a>
    </div>
  </div>
  <div class="grid-layout grid-layout--fluid login-flex">
    <div class="background--white block-container hide-small large--10 medium--12">
      <div class="large--4 medium--6">
        <img src="/-/media/jet2holidays/images/components/info cards/beach/whatsapp/whatsappQR-cancel.jpg">
      </div>
      <div class="large--8 margin--none medium--6">
        <div class="title-and-text">
          <h4 class="margin--bottom-half"><strong>Scan to WhatsApp us on your mobile device</strong></h4>
          <div class="title-and-text__content">
            <p>Scan the QR code with the camera on your mobile device or follow the link below to chat on the desktop version.</p>
          </div>
        </div>
        <a href="https://wa.me/441133860493" class="bttn bttn--global-confirm">WhatsApp on desktop</a>
      </div>
    </div>
  </div>
  <hr class="hide-large">
  <div class="title-and-text">
    <h3 class="margin--bottom-half">Alternatively, you can call us today from ${callTimeCopy} on: <strong class="hide-small"><a href="tel:0333 300 0702">0333 300 0702</a></strong></h3>
    <a href="tel:0333 300 0702" class="bttn bttn--global-secondary hide-large margin--bottom-half">0333 300 0702</a>
    <div class="title-and-text__content">
      <p></p>
    </div>
  </div>
  <a href="#" class="bttn bttn--global-primary-outline">No thanks, I'd like to cancel</a>
  </div>`
  }
});

