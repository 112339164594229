const averageWeather = {
    cities: [
      {
        city: 'Formentera', 
        temperatureHighs: { January: 16, February: 16, March: 18, April: 20, May: 23, June: 27, July: 30, August: 31, September: 28, October: 24, November: 20, December: 17 },
        temperatureLows: { January: 8, February: 8, March: 9, April: 12, May: 15, June: 18, July: 21, August: 22, September: 20, October: 16, November: 12, December: 9 },
        rainyDays: { January: 10, February: 7, March: 7, April: 7, May: 7, June: 4, July: 3, August: 4, September: 8, October: 10, November: 10, December: 11 },
        sunnyHours: { January: 4, February: 5, March: 6, April: 7, May: 7, June: 9, July: 10, August: 9, September: 7, October: 6, November: 4, December: 4 }
      },
      {
        city: 'Ibiza', 
        temperatureHighs: { January: 15, February: 16, March: 17, April: 19, May: 22, June: 26, July: 29, August: 30, September: 28, October: 24, November: 19, December: 16 },
        temperatureLows: { January: 10, February: 7, March: 7, April: 11, May: 14, June: 18, July: 21, August: 22, September: 20, October: 17, November: 13, December: 10 },
        rainyDays: { January: 10, February: 7, March: 7, April: 7, May: 7, June: 4, July: 3, August: 4, September: 8, October: 10, November: 10, December: 11 },
        sunnyHours: { January: 7, February: 8, March: 9, April: 10, May: 12, June: 13, July: 13, August: 13, September: 10, October: 8, November: 8, December: 7 }
      },
      {
        city: 'Majorca', 
        temperatureHighs: { January: 16, February: 16, March: 18, April: 20, May: 23, June: 27, July: 30, August: 30, September: 28, October: 24, November: 20, December: 17 },
        temperatureLows: { January: 9, February: 9, March: 10, April: 12, May: 16, June: 19, July: 22, August: 23, September: 20, October: 17, November: 13, December: 10 },
        rainyDays: { January: 6, February: 6, March: 5, April: 5, May: 4, June: 2, July: 1, August: 2, September: 5, October: 7, November: 6, December: 7 },
        sunnyHours: { January: 7, February: 8, March: 9, April: 10, May: 12, June: 12, July: 13, August: 12, September: 10, October: 8, November: 7, December: 6 }
      },
      {
        city: 'Menorca', 
        temperatureHighs: { January: 14, February: 14, March: 15, April: 18, May: 22, June: 26, July: 29, August: 29, September: 26, October: 23, November: 18, December: 15 },
        temperatureLows: { January: 8, February: 8, March: 9, April: 11, May: 14, June: 18, July: 21, August: 21, September: 19, October: 16, November: 12, December: 9 },
        rainyDays: { January: 13, February: 10, March: 9, April: 11, May: 8, June: 5, July: 3, August: 4, September: 10, October: 12, November: 13, December: 13 },
        sunnyHours: { January: 5, February: 6, March: 8, April: 8, May: 9, June: 11, July: 12, August: 10, September: 8, October: 6, November: 5, December: 5 }
      },
      {
        city: 'Fuerteventura', 
        temperatureHighs: { January: 21, February: 21, March: 22, April: 23, May: 24, June: 26, July: 27, August: 28, September: 28, October: 26, November: 24, December: 22 },
        temperatureLows: { January: 15, February: 15, March: 16, April: 16, May: 17, June: 19, July: 21, August: 22, September: 21, October: 20, November: 18, December: 16 },
        rainyDays: { January: 3, February: 2, March: 2, April: 1, May: 0, June: 0, July: 0, August: 0, September: 1, October: 2, November: 3, December: 3 },
        sunnyHours: { January: 6, February: 7, March: 8, April: 8, May: 9, June: 10, July: 10, August: 9, September: 8, October: 7, November: 7, December: 6 }
      },
      {
        city: 'Gran Canaria', 
        temperatureHighs: { January: 21, February: 22, March: 23, April: 23, May: 24, June: 26, July: 27, August: 28, September: 28, October: 27, November: 25, December: 23 }, 
        temperatureLows: { January: 15, February: 15, March: 16, April: 16, May: 17, June: 19, July: 20, August: 21, September: 21, October: 20, November: 18, December: 16 }, 
        rainyDays: { January: 3, February: 3, March: 2, April: 1, May: 0, June: 0, July: 0, August: 0, September: 1, October: 2, November: 4, December: 5 }, 
        sunnyHours: { January: 6, February: 7, March: 7, April: 8, May: 9, June: 9, July: 10, August: 10, September: 8, October: 7, November: 6, December: 6 }
      },
      {
        city: 'Lanzarote', 
        temperatureHighs: { January: 21, February: 21, March: 23, April: 24, May: 25, June: 26, July: 28, August: 29, September: 29, October: 27, November: 24, December: 22 }, 
        temperatureLows: { January: 14, February: 14, March: 15, April: 16, May: 17, June: 19, July: 20, August: 21, September: 21, October: 19, November: 17, December: 15 }, 
        rainyDays: { January: 3, February: 3, March: 2, April: 1, May: 0, June: 0, July: 0, August: 0, September: 0, October: 2, November: 3, December: 4 }, 
        sunnyHours: { January: 7, February: 7, March: 8, April: 9, May: 10, June: 10, July: 10, August: 10, September: 8, October: 9, November: 8, December: 6 }
      },
      {
        city: 'Tenerife', 
        temperatureHighs: { January: 20, February: 20, March: 21, April: 22, May: 23, June: 25, July: 27, August: 28, September: 27, October: 25, November: 23, December: 20 }, 
        temperatureLows: { January: 14, February: 14, March: 14, April: 15, May: 16, June: 18, July: 19, August: 20, September: 20, October: 19, November: 17, December: 14 }, 
        rainyDays: { January: 5, February: 5, March: 4, April: 3, May: 2, June: 1, July: 1, August: 1, September: 1, October: 4, November: 5, December: 6 }, 
        sunnyHours: { January: 6, February: 8, March: 8, April: 8, May: 8, June: 10, July: 10, August: 10, September: 7, October: 7, November: 7, December: 6 }
      },
      {
        city: 'Las Palmas City',
        temperatureHighs: { January: 21, February: 21, March: 23, April: 23, May: 24, June: 26, July: 27, August: 28, September: 27, October: 26, November: 24, December: 22 },
        temperatureLows: { January: 15, February: 15, March: 16, April: 16, May: 18, June: 19, July: 21, August: 22, September: 21, October: 20, November: 18, December: 16 },
        rainyDays: { January: 8, February: 7, March: 7, April: 6, May: 3, June: 2, July: 2, August: 0, September: 3, October: 9, November: 10, December: 11 },
        sunnyHours: { January: 6, February: 7, March: 8, April: 8, May: 9, June: 10, July: 11, August: 11, September: 9, October: 7, November: 6, December: 6 }
      },
      {
        city: 'Cyprus', 
        temperatureHighs: { January: 17, February: 17, March: 19, April: 22, May: 25, June: 28, July: 30, August: 30, September: 29, October: 27, November: 22, December: 19 }, 
        temperatureLows: { January: 8, February: 8, March: 9, April: 11, May: 15, June: 18, July: 20, August: 21, September: 19, October: 16, November: 13, December: 10 }, 
        rainyDays: { January: 10, February: 8, March: 6, April: 4, May: 1, June: 0, July: 0, August: 0, September: 1, October: 3, November: 6, December: 9 }, 
        sunnyHours: { January: 5, February: 7, March: 7, April: 9, May: 11, June: 13, July: 13, August: 12, September: 11, October: 9, November: 7, December: 5 }
      },
      {
        city: 'Corfu', 
        temperatureHighs: { January: 14, February: 14, March: 16, April: 19, May: 23, June: 28, July: 31, August: 31, September: 28, October: 24, November: 19, December: 15 }, 
        temperatureLows: { January: 5, February: 6, March: 7, April: 10, May: 13, June: 17, July: 19, August: 19, September: 17, October: 14, November: 10, December: 7 }, 
        rainyDays: { January: 12, February: 12, March: 11, April: 12, May: 8, June: 4, July: 2, August: 3, September: 8, October: 11, November: 15, December: 17 }, 
        sunnyHours: { January: 4, February: 5, March: 6, April: 8, May: 10, June: 10, July: 12, August: 11, September: 9, October: 7, November: 4, December: 3 }
      },
      {
        city: 'Crete', 
        temperatureHighs: { January: 16, February: 16, March: 17, April: 20, May: 24, June: 28, July: 29, August: 29, September: 27, October: 24, November: 21, December: 17 }, 
        temperatureLows: { January: 8, February: 8, March: 10, April: 12, May: 15, June: 18, July: 22, August: 21, September: 19, October: 16, November: 13, December: 10 }, 
        rainyDays: { January: 14, February: 11, March: 8, April: 6, May: 3, June: 1, July: 1, August: 1, September: 2, October: 6, November: 8, December: 9 }, 
        sunnyHours: { January: 3, February: 3, March: 5, April: 6, May: 8, June: 11, July: 13, August: 12, September: 10, October: 6, November: 3, December: 3 }
      },
      {
        city: 'Athens',
        temperatureHighs: { January: 14, February: 14, March: 17, April: 20, May: 26, June: 31, July: 34, August: 34, September: 29, October: 24, November: 19, December: 15 },
        temperatureLows: { January: 7, February: 7, March: 9, April: 12, May: 16, June: 21, July: 24, August: 24, September: 20, October: 16, November: 12, December: 9 },
        rainyDays: { January: 9, February: 7, March: 8, April: 6, May: 4, June: 1, July: 1, August: 1, September: 3, October: 5, November: 8, December: 11 },
        sunnyHours: { January: 5, February: 6, March: 6, April: 7, May: 9, June: 12, July: 12, August: 11, September: 8, October: 6, November: 4, December: 3 }
      },
      {
        city: 'Kefalonia',
        temperatureHighs: { January: 12, February: 12, March: 14, April: 17, May: 22, June: 26, July: 29, August: 29, September: 26, October: 21, November: 17, December: 13 },
        temperatureLows: { January: 5, February: 5, March: 6, April: 9, May: 12, June: 16, July: 18, August: 18, September: 16, October: 13, November: 9, December: 7 },
        rainyDays: { January: 12, February: 11, March: 10, April: 10, May: 7, June: 5, July: 0, August: 4, September: 5, October: 8, November: 10, December: 12 },
        sunnyHours: { January: 6, February: 7, March: 8, April: 10, May: 12, June: 13, July: 14, August: 13, September: 10, October: 8, November: 7, December: 6 }
      },
      {
        city: 'Kos',
        temperatureHighs: { January: 13, February: 12, March: 14, April: 18, May: 23, June: 27, July: 29, August: 29, September: 26, October: 22, November: 18, December: 14 },
        temperatureLows: { January: 8, February: 8, March: 9, April: 12, May: 16, June: 19, July: 21, August: 22, September: 19, October: 16, November: 12, December: 9 },
        rainyDays: { January: 12, February: 10, March: 9, April: 5, May: 7, June: 3, July: 3, August: 5, September: 3, October: 6, November: 8, December: 12 },
        sunnyHours: { January: 5, February: 6, March: 6, April: 7, May: 9, June: 10, July: 10, August: 10, September: 8, October: 7, November: 6, December: 4 }
      },
      {
        city: 'Rhodes',
        temperatureHighs: { January: 15, February: 15, March: 17, April: 20, May: 23, June: 27, July: 29, August: 29, September: 27, October: 24, November: 19, December: 16 },
        temperatureLows: { January: 10, February: 10, March: 11, April: 14, May: 17, June: 21, July: 23, August: 24, September: 22, October: 18, November: 14, December: 12 },
        rainyDays: { January: 11, February: 10, March: 8, April: 6, May: 3, June: 0, July: 0, August: 0, September: 1, October: 5, November: 8, December: 13 },
        sunnyHours: { January: 4, February: 5, March: 7, April: 9, May: 10, June: 11, July: 12, August: 12, September: 11, October: 8, November: 6, December: 4 }
      },
      {
        city: 'Zante',
        temperatureHighs: { January: 14, February: 14, March: 16, April: 19, May: 23, June: 28, July: 31, August: 31, September: 28, October: 24, November: 19, December: 16 },
        temperatureLows: { January: 8, February: 8, March: 9, April: 11, May: 14, June: 18, July: 20, August: 21, September: 19, October: 16, November: 12, December: 10 },
        rainyDays: { January: 12, February: 11, March: 10, April: 9, May: 6, June: 4, July: 2, August: 3, September: 4, October: 7, November: 10, December: 12 },
        sunnyHours: { January: 5, February: 5, March: 6, April: 8, May: 10, June: 12, July: 13, August: 12, September: 10, October: 7, November: 5, December: 4 }
      },
      {
        city: 'Santorini',
        temperatureHighs: { January: 14, February: 14, March: 16, April: 18, May: 23, June: 27, July: 29, August: 29, September: 26, October: 23, November: 19, December: 15 },
        temperatureLows: { January: 10, February: 10, March: 11, April: 13, May: 17, June: 21, July: 23, August: 23, September: 21, October: 18, November: 14, December: 11 },
        rainyDays: { January: 10, February: 9, March: 7, April: 4, May: 3, June: 0, July: 1, August: 0, September: 2, October: 4, November: 8, December: 11 },
        sunnyHours: { January: 7, February: 7, March: 9, April: 9, May: 11, June: 12, July: 13, August: 14, September: 11, October: 9, November: 8, December: 6 }
      },
      {
        city: 'Skiathos',
        temperatureHighs: { January: 10, February: 11, March: 13, April: 18, May: 23, June: 27, July: 29, August: 29, September: 26, October: 20, November: 16, December: 11 },
        temperatureLows: { January: 2, February: 3, March: 4, April: 7, May: 11, June: 15, July: 18, August: 18, September: 15, October: 11, November: 8, December: 4 },
        rainyDays: { January: 10, February: 9, March: 7, April: 4, May: 3, June: 0, July: 1, August: 0, September: 2, October: 4, November: 8, December: 11 },
        sunnyHours: { January: 4, February: 5, March: 7, April: 9, May: 10, June: 10, July: 12, August: 10, September: 9, October: 8, November: 7, December: 5 }
      },
      {
        city: 'Halkidiki',
        temperatureHighs: { January: 14, February: 14, March: 17, April: 21, May: 26, June: 30, July: 33, August: 33, September: 29, October: 24, November: 19, December: 15 },
        temperatureLows: { January: 8, February: 8, March: 10, April: 13, May: 18, June: 22, July: 24, August: 25, September: 22, October: 17, November: 13, December: 10 },
        rainyDays: { January: 10, February: 9, March: 7, April: 6, May: 4, June: 2, July: 1, August: 2, September: 4, October: 7, November: 8, December: 11 },
        sunnyHours: { January: 4, February: 5, March: 8, April: 9, May: 11, June: 12, July: 13, August: 13, September: 10, October: 8, November: 7, December: 5 }
      },
      {
        city: 'Montenegro',
        temperatureHighs: { January: 9, February: 11, March: 15, April: 19, May: 23, June: 28, July: 32, August: 32, September: 27, October: 21, November: 15, December: 10 },
        temperatureLows: { January: 1, February: 2, March: 6, April: 9, May: 13, June: 17, July: 20, August: 19, September: 16, October: 11, November: 6, December: 2 },
        rainyDays: { January: 11, February: 9, March: 10, April: 12, May: 8, June: 5, July: 4, August: 4, September: 8, October: 10, November: 13, December: 13 },
        sunnyHours: { January: 3, February: 3, March: 5, April: 7, May: 9, June: 10, July: 11, August: 10, September: 7, October: 5, November: 4, December: 3 }
      },
      {
        city: 'Agadir',
        temperatureHighs: { January: 23, February: 23, March: 26, April: 25, May: 26, June: 28, July: 30, August: 30, September: 29, October: 28, November: 25, December: 22 },
        temperatureLows: { January: 8, February: 9, March: 11, April: 13, May: 14, June: 17, July: 18, August: 18, September: 17, October: 15, November: 12, December: 9 },
        rainyDays: { January: 5, February: 3, March: 4, April: 2, May: 1, June: 1, July: 0, August: 1, September: 1, October: 3, November: 3, December: 5 },
        sunnyHours: { January: 7, February: 8, March: 9, April: 10, May: 11, June: 12, July: 12, August: 11, September: 11, October: 10, November: 9, December: 9 }
      },
      {
        city: 'Marrakech',
        temperatureHighs: { January: 19, February: 22, March: 25, April: 27, May: 30, June: 35, July: 39, August: 38, September: 33, October: 29, November: 24, December: 20 },
        temperatureLows: { January: 7, February: 9, March: 11, April: 13, May: 15, June: 19, July: 21, August: 22, September: 20, October: 17, November: 12, December: 8 },
        rainyDays: { January: 8, February: 7, March: 8, April: 8, May: 5, June: 1, July: 1, August: 1, September: 3, October: 6, November: 7, December: 7 },
        sunnyHours: { January: 7, February: 8, March: 8, April: 8, May: 9, June: 11, July: 11, August: 11, September: 9, October: 8, November: 7, December: 7 }
      },
      {
        city: 'Algarve',
        temperatureHighs: { January: 16, February: 18, March: 21, April: 23, May: 25, June: 28, July: 33, August: 32, September: 29, October: 25, November: 20, December: 17 },
        temperatureLows: { January: 6, February: 7, March: 9, April: 11, May: 14, June: 17, July: 19, August: 19, September: 17, October: 14, November: 11, December: 9 },
        rainyDays: { January: 9, February: 5, March: 6, April: 8, May: 7, June: 2, July: 1, August: 0, September: 5, October: 8, November: 7, December: 12 },
        sunnyHours: { January: 5, February: 6, March: 7, April: 9, May: 12, June: 12, July: 12, August: 12, September: 9, October: 7, November: 6, December: 5 }
      },
      {
        city: 'Madeira',
        temperatureHighs: { January: 18, February: 18, March: 19, April: 19, May: 21, June: 23, July: 24, August: 25, September: 25, October: 23, November: 21, December: 20 },
        temperatureLows: { January: 14, February: 14, March: 14, April: 14, May: 16, June: 18, July: 19, August: 20, September: 20, October: 19, November: 17, December: 15 },
        rainyDays: { January: 13, February: 10, March: 10, April: 10, May: 8, June: 5, July: 4, August: 4, September: 9, October: 12, November: 12, December: 16 },
        sunnyHours: { January: 5, February: 5, March: 6, April: 7, May: 7, June: 7, July: 8, August: 8, September: 8, October: 7, November: 6, December: 5 }
      },
      {
        city: 'Barcelona',
        temperatureHighs: { January: 14, February: 14, March: 17, April: 19, May: 22, June: 26, July: 29, August: 29, September: 27, October: 23, November: 18, December: 14 },
        temperatureLows: { January: 6, February: 6, March: 8, April: 11, May: 14, June: 19, July: 22, August: 21, September: 19, October: 15, November: 9, December: 6 },
        rainyDays: { January: 3, February: 5, March: 4, April: 5, May: 5, June: 2, July: 2, August: 5, September: 5, October: 6, November: 4, December: 5 },
        sunnyHours: { January: 5, February: 7, March: 7, April: 8, May: 8, June: 9, July: 10, August: 9, September: 8, October: 6, November: 5, December: 4 }
      },
      {
        city: 'Costa Blanca',
        temperatureHighs: { January: 16, February: 17, March: 19, April: 21, May: 24, June: 27, July: 31, August: 32, September: 28, October: 25, November: 19, December: 17 },
        temperatureLows: { January: 5, February: 6, March: 8, April: 10, May: 13, June: 17, July: 20, August: 21, September: 18, October: 14, November: 9, December: 7 },
        rainyDays: { January: 4, February: 4, March: 4, April: 4, May: 3, June: 2, July: 1, August: 1, September: 4, October: 4, November: 6, December: 5 },
        sunnyHours: { January: 6, February: 7, March: 7, April: 8, May: 10, June: 10, July: 11, August: 10, September: 9, October: 7, November: 6, December: 6 }
      },
      {
        city: 'Antalya',
        temperatureHighs: { January: 15, February: 15, March: 18, April: 21, May: 26, June: 31, July: 34, August: 33, September: 31, October: 26, November: 20, December: 16 },
        temperatureLows: { January: 5, February: 6, March: 7, April: 10, May: 14, June: 18, July: 21, August: 21, September: 18, October: 14, November: 9, December: 7 },
        rainyDays: { January: 11, February: 10, March: 9, April: 8, May: 6, June: 3, July: 1, August: 1, September: 2, October: 6, November: 8, December: 12 },
        sunnyHours: { January: 5, February: 7, March: 7, April: 9, May: 11, June: 12, July: 13, August: 12, September: 11, October: 9, November: 7, December: 5 }
      },
      {
        city: 'Bodrum',
        temperatureHighs: { January: 15, February: 15, March: 17, April: 21, May: 26, June: 30, July: 34, August: 33, September: 31, October: 25, November: 19, December: 16 },
        temperatureLows: { January: 8, February: 8, March: 9, April: 13, May: 16, June: 20, July: 23, August: 22, September: 20, October: 16, November: 12, December: 9 },
        rainyDays: { January: 10, February: 8, March: 6, April: 6, May: 5, June: 1, July: 3, August: 1, September: 1, October: 4, November: 7, December: 10 },
        sunnyHours: { January: 5, February: 6, March: 6, April: 7, May: 9, June: 10, July: 10, August: 10, September: 8, October: 7, November: 6, December: 4 }
      },
      {
        city: 'Dalaman',
        temperatureHighs: { January: 14, February: 14, March: 17, April: 19, May: 24, June: 29, July: 31, August: 31, September: 28, October: 24, November: 19, December: 16 },
        temperatureLows: { January: 5, February: 5, March: 6, April: 9, May: 13, June: 17, July: 21, August: 21, September: 17, October: 13, November: 9, December: 7 },
        rainyDays: { January: 14, February: 12, March: 10, April: 8, May: 5, June: 4, July: 7, August: 7, September: 4, October: 6, November: 9, December: 14 },
        sunnyHours: { January: 4, February: 5, March: 7, April: 9, May: 10, June: 12, July: 13, August: 12, September: 11, October: 8, November: 6, December: 4 }
      },
      {
        city: 'Izmir',
        temperatureHighs: { January: 11, February: 13, March: 16, April: 20, May: 25, June: 30, July: 32, August: 32, September: 29, October: 23, November: 18, December: 13 },
        temperatureLows: { January: 4, February: 5, March: 6, April: 9, May: 13, June: 17, July: 20, August: 20, September: 16, October: 12, November: 9, December: 6 },
        rainyDays: { January: 12, February: 11, March: 10, April: 9, May: 6, June: 4, July: 7, August: 6, September: 5, October: 6, November: 9, December: 13 },
        sunnyHours: { January: 4, February: 6, March: 6, April: 8, May: 10, June: 12, July: 13, August: 12, September: 10, October: 8, November: 6, December: 4 }
      }
    ]
  };

  module.exports = averageWeather;