
//window.addEventListener('load', () => {

    var myutils = window.optlyUtils || window.globalUtils;
    let isUpdating = false;
    
    myutils.poll(['.full-screen-loader:not(.full-screen-loader--active)'], ([loader]) => {

        updateCalendars();
    
        const calendar2Wrapper = document.querySelector('[data-component="Search/Results/ResultsContainer/FcpResultsContainer"] > .calendar-view--fcp > div:not(.section):not(.inpage-loader)');
    
        const calendar2WrapperObserver = new MutationObserver((mutationsList) => checkDisplayStyle(mutationsList, calendar2Wrapper));
        const config = { attributes: true, attributeFilter: ['style'] };
    
        if (calendar2Wrapper) {
            calendar2WrapperObserver.observe(calendar2Wrapper, config);
        }
    
        const calendarLoader = document.querySelector('[data-component="Search/Results/ResultsContainer/FcpResultsContainer"]');
    
        if (calendarLoader) {
            const calendarLoaderObserver = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        const classList = calendarLoader.classList;
                        if (classList.contains('wrapper') && classList.contains('show-loader') && classList.contains('show-loader--fullscreen')) {
                            isUpdating = true;
                        } else {
                            if (isUpdating === true) {
                               
                                updateCalendars();
                                isUpdating = false;
                            }
                        }
                    }
                }
            });

            calendarLoaderObserver.observe(calendarLoader, {
                attributes: true,
                attributeFilter: ['class'],
            });
        }

        moveKey();

        

        const listItems = document.querySelectorAll('.calendar-view__dates .calendar-view__date');

        listItems.forEach(item => {
            item.addEventListener('click', (event) => {

                // Find the closest `li` element with the required class
                const dayElement = event.target.closest("li.calendar-view__date");

                if (!dayElement) return;

                // Extract month and year information
                const monthAndYearElement = document.querySelector(".calendar-week-view__back-title");
                const monthAndYear = monthAndYearElement && monthAndYearElement.textContent ? monthAndYearElement.textContent.trim() : "";


                const topElement = document.querySelector('.stack-select__top');

                if (topElement) {

                    // Get the day number from `.stack-select__top` element
                    const topElement = dayElement.querySelector(".stack-select__top");
                    const topElementValue = dayElement.querySelector(".stack-select__top-price");
                    const topElementText = topElement.innerText.replace(topElementValue.innerText,"")
                    const dateNumberMatch = topElementText && topElementText.match(/\d+/);
                    const dateNumber = dateNumberMatch ? dateNumberMatch[0] : null;
                    
                    // Get availability information
                    const availabilityElement = dayElement.querySelector(".calendar-view__date--availablility");
                    const availabilityText = availabilityElement && availabilityElement.textContent ? availabilityElement.textContent.trim() : "No places available";

                    // Determine availability status
                    let availability = "No places available";
                    if (availabilityText.includes("Available")) {
                        availability = "Available";
                    } 
                    else if (availabilityText.includes("Limited")) {
                        availability = "Limited";
                    }
                    else if (availabilityText.includes("Last few")) {
                        availability = "Last few";
                    }

                    // If both date and availability are valid, track the modal data
                    if (dateNumber && availability) {
                        const date = dateNumber + " " + monthAndYear;
                        modalTracking(date, availability);
                    }
                }
            });
        });

        
        document.body.classList.add("bf215");
    });
    
    function checkDisplayStyle(mutationsList, calendar2Wrapper) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                updateCalendars();
            }
        }
    }

    function calculateAvailability(item) {
        if (item) {
            let quantityWrappers = item.querySelectorAll(".stack-select:not(.is-disabled) .stack-select__top-price");
            if (quantityWrappers) {
                const thresholds = hasMultipleDestinationsOrAirports()
                    ? { available: 51, limitedMin: 10, limitedMax: 50, lastFewMax: 9 }
                    : { available: 21, limitedMin: 10, limitedMax: 21, lastFewMax: 9 };


                quantityWrappers.forEach(wrapper => {
                    
                    let quantity = Number(wrapper.innerText);
                    if (quantity) {
                        updateItemClassAndText(quantity, thresholds, wrapper);
                    }
                });
            }
        }
    }

    function updateCalendars() {

        let calendar1 = document.querySelectorAll(".calendar-month-week-wrapper:not(.is-empty) .alternative-dates-airports__calendar .calendar-view__date");
        let calendar2 = document.querySelectorAll(".calendar-week-view__calendar");

        if (calendar1) {
            for (let i = 0; i < calendar1.length; i++) {
                calculateAvailability(calendar1[i]);
            }
        }

        if (calendar2) {
            for (let i = 0; i < calendar2.length; i++) {
                calculateAvailability(calendar2[i]);
            }
        }
    }

    function hasMultipleDestinationsOrAirports() {
        const url = window.location.href;
        const urlObj = new URL(url);
        const destinationParam = urlObj.searchParams.get('destination');
        const airportParam = urlObj.searchParams.get('airport');

        return checkUrlParam(destinationParam) || checkUrlParam(airportParam);
    }

    function checkUrlParam(parameter) {
        if (parameter) {
            const underscoreCount = (parameter.match(/_/g) || []).length;
            return underscoreCount >= 4;
        }
        return false;
    }

    function updateItemClassAndText(quantity, thresholds, wrapper) {
        const { available, limitedMin, limitedMax, lastFewMax } = thresholds;
        const item = wrapper.closest(".stack-select");
        const elementTop = item.querySelector(".stack-select__top");
        const elementBottom = item.querySelector(".stack-select__bottom");

        if (quantity >= available) {
            if (elementTop && elementBottom) {
                removeClass(elementTop);
                removeClass(elementBottom);
                elementTop.classList.add("calendar-view__date--available");
                elementBottom.classList.add("calendar-view__date--available");
                //wrapper.innerText = "Available";
                if(wrapper.parentElement.querySelector(".calendar-view__date--availablility")){
                    wrapper.parentElement.querySelector(".calendar-view__date--availablility").remove();
                }
                wrapper.parentElement.insertAdjacentHTML("beforeend",'<div class="calendar-view__date--availablility">Available<span> Free Child Places</span><span class="stack-select__selected"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.90858 9.5C3.63149 9.5 3.36492 9.36973 3.19656 9.14439L0.906163 6.10936C0.608026 5.71501 0.685191 5.15167 1.07803 4.85239C1.47087 4.55311 2.03207 4.63057 2.33021 5.02491L3.83492 7.02127L7.62301 0.923042C7.88257 0.504053 8.43324 0.373779 8.85414 0.634327C9.27153 0.894875 9.40131 1.44766 9.13825 1.87017L4.6662 9.07749C4.51187 9.32747 4.24179 9.48592 3.95067 9.5C3.93313 9.5 3.92261 9.5 3.90858 9.5Z" fill="#525252"></path></svg> Selected</span></div>');
            }
        } else if (quantity >= limitedMin && quantity <= limitedMax) {
            if (elementTop && elementBottom) {
                removeClass(elementTop);
                removeClass(elementBottom);
                elementTop.classList.add("calendar-view__date--limited");
                elementBottom.classList.add("calendar-view__date--limited");
                if(wrapper.parentElement.querySelector(".calendar-view__date--availablility")){
                    wrapper.parentElement.querySelector(".calendar-view__date--availablility").remove();
                }
                wrapper.parentElement.insertAdjacentHTML("beforeend",'<div class="calendar-view__date--availablility">Limited<span> Free Child Places</span><span class="stack-select__selected"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.90858 9.5C3.63149 9.5 3.36492 9.36973 3.19656 9.14439L0.906163 6.10936C0.608026 5.71501 0.685191 5.15167 1.07803 4.85239C1.47087 4.55311 2.03207 4.63057 2.33021 5.02491L3.83492 7.02127L7.62301 0.923042C7.88257 0.504053 8.43324 0.373779 8.85414 0.634327C9.27153 0.894875 9.40131 1.44766 9.13825 1.87017L4.6662 9.07749C4.51187 9.32747 4.24179 9.48592 3.95067 9.5C3.93313 9.5 3.92261 9.5 3.90858 9.5Z" fill="#525252"></path></svg> Selected</span></div>');
                //wrapper.innerText = "Limited";
            }
        } else if (quantity > 0 && quantity <= lastFewMax) {
            if (elementTop && elementBottom) {
                removeClass(elementTop);
                removeClass(elementBottom);
                elementTop.classList.add("calendar-view__date--last-few");
                elementBottom.classList.add("calendar-view__date--last-few");
                if(wrapper.parentElement.querySelector(".calendar-view__date--availablility")){
                    wrapper.parentElement.querySelector(".calendar-view__date--availablility").remove();
                }
                wrapper.parentElement.insertAdjacentHTML("beforeend",'<div class="calendar-view__date--availablility">Last few<span> Free Child Places</span><span class="stack-select__selected"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.90858 9.5C3.63149 9.5 3.36492 9.36973 3.19656 9.14439L0.906163 6.10936C0.608026 5.71501 0.685191 5.15167 1.07803 4.85239C1.47087 4.55311 2.03207 4.63057 2.33021 5.02491L3.83492 7.02127L7.62301 0.923042C7.88257 0.504053 8.43324 0.373779 8.85414 0.634327C9.27153 0.894875 9.40131 1.44766 9.13825 1.87017L4.6662 9.07749C4.51187 9.32747 4.24179 9.48592 3.95067 9.5C3.93313 9.5 3.92261 9.5 3.90858 9.5Z" fill="#525252"></path></svg> Selected</span></div>');
                //wrapper.innerText = "Last few";
            }
        }
    }

    function removeClass(element) {
        for (const cls of element.classList) {
            if (cls.startsWith("calendar-view__date--")) {
                element.classList.remove(cls);
            }
        }
    }

    function moveKey() {
        const key = document.querySelector(".calendar-view--fcp .block-container__footer .key-list");
        const newKey = '<li class="key-list__item"> <div class="green-dot"></div>Available</li><li class="key-list__item"> <div class="amber-dot"></div>Limited</li><li class="key-list__item"> <div class="red-dot"></div>Last few</li>';
        const keyTitle = '<h3 class="key-list__title">Free child places</h3>';
        key.insertAdjacentHTML("beforeend", newKey);
        const keyContainer = key.parentElement;
        keyContainer.insertAdjacentHTML("afterbegin", keyTitle);
        const calendarWrapper = document.querySelector(".calendar-month-week-wrapper");
        const smileyIcon = key.querySelector(".badge--fcp");

        if (smileyIcon) {
            const smileyIconWrapper = smileyIcon.closest(".key-list__item");
            if (smileyIconWrapper) {
                smileyIconWrapper.remove();
            }
        }

        if (keyContainer && calendarWrapper) {
            calendarWrapper.insertAdjacentElement("afterend", keyContainer);
        }
    }

    function modalTracking(date, availability) {
        window.dataLayer.push({
            event: "FCP_Calendar",
            category: date,
            action: "Date Selected",
            label: availability
        });
    }
//});
