function filtersItemTemplate(props) {
    const template = () => {
        
        return `
            <div class="filter-modal-v2__option-wrapper">
                <div class="filter-modal-v2-option checkbox-button-group__item">
                    <input
                        id="${props.id}"
                        type="${props.type}"
                        name="${props.name}"
                        class="checkbox-button-group__input"
                        ${isChecked(props.isChecked)}
                    />
                    <label
                        for="${props.id}"
                        class="${labelClasses()}"
                    >
                        <span class="checkbox-button-group__text-highlighted">${props.content}</span>
                    </label>
                </div>
            </div>
        `;
    };

    function isChecked(isChecked) {
        return isChecked ? 'checked' : '';
    }

    function labelClasses() {
        return props.type === 'checkbox' ? 'checkbox-button-group__label' : 'checkbox-button-group__label checkbox-button-group__label--tripadvisor checkbox-button-group__label--tripadvisor-last';
    }

    return template();
}

module.exports =  filtersItemTemplate;
