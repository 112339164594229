/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
  // console.log(
  //   "%c| BF218 - FLEXI SEARCH VARIATION 1 v1.19 |",
  //   "background-color: #50BFE6; color: white; font-weight: bold;"
  // );
  var utils = window.optlyUtils || window.globalUtils;
  utils.poll(["#search-box-leaving"], ([searchBoxLeaving]) => {
    (function () {
      let foundFlexiParam = "";
      let flexiWrapper;
      let prevDisplayState = null; // Track the last known display state of the modal

      const calendarDateModalEl = Array.from(
        document.querySelectorAll(".modal")
      ).find((modal) => modal.querySelector(".calendar-date-selector"));

      function hideFlexiCheckboxEl() {
        const flexiCheckBoxEl = calendarDateModalEl.querySelector(
          ".checkbox-button-group__item"
        );
        flexiCheckBoxEl.classList.add("hidden");
      }

      function checkForFlexiParams() {
        const flexiParams = ["notFlexi", "&flexi=1", "&flexi=2", "&flexi=3"];
        const url = window.location.href;

        return flexiParams.some((param) => {
          if (url.includes(param)) {
            foundFlexiParam = param;
            return true;
          }
          return false;
        });
      }

      function setFlexiParamInURL() {
        const url = window.location.href;
        const dateFlexibility = localStorage.getItem("dateFlexibility");

        if (!dateFlexibility) return;

        if (checkForFlexiParams()) {
          if (dateFlexibility === "notFlexi") {
            // If "notFlexi" is set, reload without any flexi parameter
            // console.log("Removing flexi parameters from URL");
            const newUrl = url
              .replace(foundFlexiParam, "")
              .replace(/(\?&|\?$)/, "");
            if (newUrl !== url) {
              window.location.href = newUrl;
            }
          } else if (foundFlexiParam !== dateFlexibility) {
            // If a different flexi param is set, replace it
            // console.log(`Reloading with parameter: ${dateFlexibility}`);
            window.location.href = url.replace(
              foundFlexiParam,
              dateFlexibility
            );
          }
        } else {
          if (dateFlexibility !== "notFlexi") {
            // Append flexi parameter if not present
            // console.log(`Appending parameter: ${dateFlexibility}`);
            window.location.href = url + dateFlexibility;
          }
        }
      }

      function disableFlexiCheckbox() {
        const liveFlexiContainerEl = document.querySelector(
          ".calendar-date-selector .checkbox-button-group__label"
        );
        const liveFlexiCheckboxEl = document.querySelector(
          ".calendar-date-selector [name='flexi-date-checkbox']"
        );
        if (liveFlexiCheckboxEl && liveFlexiCheckboxEl.checked) {
          liveFlexiContainerEl.click();
        }
      }

      function trackModalImpression() {
        if (isModalVisible() && isSmartSearch()) {
          // console.log("Modal is visible and smart search is active.");
          const currentDisplayState = calendarDateModalEl.style.display;

          // Push to dataLayer only if the state has changed to `block`
          if (
            currentDisplayState === "block" &&
            (prevDisplayState !== "block" || prevDisplayState === null)
          ) {
            // console.log("Radio buttons reset to localStorage state");
            resetModalState();
            window.dataLayer.push({
              event: "search_panel",
              category: "Calendar Modal",
              action: "Impression",
              label: "Modal Open - Flexible Search",
            });
          }
          // Update the previous state
          prevDisplayState = currentDisplayState;
        } else {
          prevDisplayState = null;
        }
      }

      function resetModalState() {
        const flexiValue =
          localStorage.getItem("dateFlexibility") || "notFlexi";
        const selectedRadioButtonEl = document.querySelector(
          `.flexi-wrapper input[value="${flexiValue}"]`
        );
        if (selectedRadioButtonEl) selectedRadioButtonEl.checked = true;
      }

      function isSmartSearch() {
        const calendarDateSelectorEl = document.querySelector(
          ".calendar-date-selector"
        );

        if (!calendarDateSelectorEl) {
          return false;
        }

        // Get all children with class 'checkbox-button-group__item'
        const children = calendarDateSelectorEl.querySelectorAll(
          ".checkbox-button-group__item"
        );

        // Check if all children have 'display: none' inline style
        const allChildrenHidden = Array.from(children).every((child) => {
          const displayStyle = child.style.display;
          return displayStyle !== "none";
        });

        if (!allChildrenHidden) {
          return false;
        }

        // All conditions are met
        return true;
      }

      function isModalVisible() {
        return (
          calendarDateModalEl &&
          window.getComputedStyle(calendarDateModalEl).display !== "none"
        );
      }

      function setupRadioButtons() {
        const anchor = calendarDateModalEl.querySelector(
          ".calendar-date-selector"
        );
        if (!anchor) {
          // console.warn("Anchor not found, retrying in 100ms");
          setTimeout(setupRadioButtons, 100); // Retry after 100ms
          return;
        }

        if (document.querySelector(".flexi-wrapper")) return; // Prevent duplicate injection

        anchor.insertAdjacentHTML(
          "afterbegin",
          `<div class="flexi-wrapper">
                  <fieldset>
                  <div class="legend-container">
                    <legend>Is your date flexible?</legend>
                     </div>
                    <label><input type="radio" name="options" value="notFlexi"> No</label>
                    <label><input type="radio" name="options" value="&flexi=1"> ± 1 day</label>
                    <label><input type="radio" name="options" value="&flexi=2"> ± 2 days</label>
                    <label><input type="radio" name="options" value="&flexi=3"> ± 3 days</label>
                  </fieldset>
                </div>`
        );

        flexiWrapper = document.querySelector(".flexi-wrapper");

        let currentFlexiValue = localStorage.getItem("dateFlexibility") || null;

        // Set the previously saved radio button as checked on page load
        if (currentFlexiValue) {
          const selectedRadioButtonEl = document.querySelector(
            `.flexi-wrapper input[value="${currentFlexiValue}"]`
          );
          if (selectedRadioButtonEl) selectedRadioButtonEl.checked = true;
        }

        // Event listener for radio buttons to update temp variable
        document.querySelectorAll(".flexi-wrapper input").forEach((radio) => {
          radio.addEventListener("change", (e) => {
            if (e.isTrusted) {
              let label = e.target.parentElement.textContent.trim();

              const flexibleLabels = ["± 1 day", "± 2 days", "± 3 days"];
              if (flexibleLabels.includes(label)) {
                label = `Flexible ${label}`;
              }

              window.dataLayer.push({
                event: "search_panel",
                category: "Calendar Modal",
                action: "Check",
                label: label,
              });
            }
            currentFlexiValue = e.target.value;
            // console.log(`Temporary selection updated: ${currentFlexiValue}`);
          });
        });

        // Event listener for "Done" button to save selection to localStorage
        calendarDateModalEl
          .querySelector(".modal-box__close .bttn")
          .addEventListener("click", () => {
            if (currentFlexiValue) {
              localStorage.setItem("dateFlexibility", currentFlexiValue);
              // console.log(`Local storage updated: ${currentFlexiValue}`);
            }
          });
      }
      function observeCalendarModal() {
        const observer = new MutationObserver((mutationsList, observer) => {
          mutationsList.forEach((mutation) => {
            // console.log("Mutation observed:", mutation);

            if (!flexiWrapper) return;

            trackModalImpression();

            flexiWrapper.style.display =
              isModalVisible() && isSmartSearch() ? "flex" : "none";
          });
        });

        // Start observing parentElement for mutations
        const modalParentEl = document.querySelector(".js-edit-search-form");
        if (modalParentEl) {
          observer.observe(modalParentEl, { attributes: true, subtree: true });
        } else {
          // console.error("Parent element not found.");
        }
      }

      function init() {
        hideFlexiCheckboxEl();
        disableFlexiCheckbox();
        setupRadioButtons();
        trackModalImpression();
        setFlexiParamInURL();
        observeCalendarModal();
      }

      // Run immediately for console testing
      init();

      // Run automatically on DOM ready
      // document.addEventListener("DOMContentLoaded", init);
    })();
    document.body.classList.add("BF218");
  });
})();
