(() => {
    window.dataLayer = window.dataLayer || [];
    const opUtils = window.optimizely.get('utils');

    opUtils.waitForElement('.board-basis').then((boardBasisElement) => {
        const boardOptionElements = Array.from(
            boardBasisElement.querySelectorAll('.option-item--board-basis')
        );
        let impressionString = !boardOptionElements.length ? "Not available" : "";

        boardOptionElements.forEach((option, i) => {
            const optionLabel = option.querySelector('.option-item__title').innerText.trim();
            const optionMoreInfoLink = option.querySelector('.option-item__see-more');

            if (optionMoreInfoLink) {
                optionMoreInfoLink.addEventListener('click', e => {
                    pushToDatalayer('Board basis - more info link', `${e.target.innerText.trim() === "close" ? "Close" : "Open"}`, optionLabel);
                });
            }

            // Add value to string that is used for tracking
            impressionString += ` ${i === 0 ? '' : '| '}${optionLabel}${option.classList.contains('option-item--selected') ? '(default)' : ''}`;
            impressionString = impressionString.trim();
        });

        runWhenElementInView(boardBasisElement, () => pushToDatalayer('Board basis available', 'Impression', impressionString));
    });

    function pushToDatalayer(cat = '', action = '', label = '') {
        window.dataLayer.push({
            event: 'holiday_options',
            category: cat,
            action: action,
            label: label,
        });
    }

    function runWhenElementInView(element, callback) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback();
                    observer.disconnect();
                }
            });
        });

        observer.observe(element);
    }
})();




