var utils = window.optlyUtils || window.globalUtils;
var opUtils = window.optimizely.get('utils');
var currentIndex = 0;
var intervalId;
var isPaused = false;
var carouselWrapper, carouselTrack, carouselSlides;
var touchstartX = 0;
var touchendX = 0;

const pauseSVG = `<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.6 0.145019C7.48366 0.145019 8.2 0.881465 8.2 1.78992L8.2 14.5001C8.2 15.4086 7.48366 16.145 6.6 16.145C5.71635 16.145 5 15.4086 5 14.5001L5 1.78992C5 0.881465 5.71634 0.145019 6.6 0.145019Z" fill="#203240"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8 0.145019C15.6836 0.145019 16.4 0.881465 16.4 1.78992L16.4 14.5001C16.4 15.4086 15.6836 16.145 14.8 16.145C13.9163 16.145 13.2 15.4086 13.2 14.5001L13.2 1.78992C13.2 0.881465 13.9163 0.145019 14.8 0.145019Z" fill="#203240"/>
</svg>
`;
const playSVG = `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4717 7.07583C19.1761 7.48657 19.1761 8.51343 18.4717 8.92417L6.58491 15.8554C5.8805 16.2662 5 15.7528 5 14.9313L5 1.06873C5 0.247248 5.8805 -0.266181 6.58491 0.144561L18.4717 7.07583Z" fill="#203240"/>
</svg>
`;

utils.poll(['.hero-banner-cards'], ([heroCards]) => {
  main();
  setupBannerLinkClickEvents();
  setupButtonEvents();
  addCardClickListeners();
});

function main() {
  // content containers (banner-v2__slide) from old slick-slider
  var carouselContentContainerList =
    document.querySelectorAll('.banner-v2__slide');
  // add new carousel into hero banner wrapper (".hero-recent-searches-banner-wrapper")
  var carousel = createNewCarousel(carouselContentContainerList);
  carouselWrapper = carousel.carouselWrapper;
  carouselTrack = carousel.carouselTrack;
  carouselSlides = carousel.carouselSlides;

  var heroBanner = document.querySelector(
    "[data-component='Content/ImageElements/HeroBanner']"
  );

  var heroWrapper = document.querySelector(
    '.hero-recent-searches-banner-wrapper'
  );

  if (heroWrapper) {
    heroWrapper.insertAdjacentElement('afterbegin', carouselWrapper);
  } else {
    if (heroBanner) {
      if (heroBanner.parentElement) {
        heroBanner = heroBanner.parentElement;
        heroWrapper = document.createElement('div');
        heroWrapper.classList.add('hero-recent-searches-banner-wrapper');
        heroBanner.insertAdjacentElement('beforebegin', heroWrapper);
        heroWrapper = document.querySelector(
          '.hero-recent-searches-banner-wrapper'
        );
        if (heroWrapper) {
          heroWrapper.insertAdjacentElement('afterbegin', carouselWrapper);
        }
      }
    }
  }

  // remove old banner slider
  if (
    document.querySelector(
      "[data-component='Content/ImageElements/HeroBanner']"
    )
  ) {
    document
      .querySelector("[data-component='Content/ImageElements/HeroBanner']")
      .remove();
  }

  //if (document.querySelectorAll(".bf155-carousel-container .carousel-slide").length > 1) {
  let { pauseBtn, leftBtn, rightBtn } = createNewBtns();

  pauseBtn.addEventListener('click', () => {
    modalTracking('Hero Banner â€“ Carousel CTAs', 'click', 'Pause');
  });

  // play/next/previous events
  pauseBtn.addEventListener('click', togglePlay);
  leftBtn.addEventListener('click', prevSlide);
  rightBtn.addEventListener('click', nextSlide);

  // Swipe events
  carouselWrapper.addEventListener(
    'touchstart',
    function (event) {
      touchstartX = event.changedTouches[0].screenX;
    },
    false
  );
  carouselWrapper.addEventListener(
    'touchend',
    function (event) {
      touchendX = event.changedTouches[0].screenX;
      handleGesture();
    },
    false
  );

  // Start auto-play
  intervalId = setInterval(nextSlide, window.innerWidth < 768 ? 6000 : 8500);
  //}

  if (
    document.querySelectorAll('.bf155-carousel-container .carousel-slide')
      .length <= 1
  ) {
    let bannerControls = document.querySelector(
      '.bf155-carousel-container .btn-container'
    );
    if (bannerControls) {
      bannerControls.style.opacity = 0;
    }
  }

  moveCards();
  document.body.classList.add('bf155--hero-banner');
}

function createNewBtns() {
  var currentImageNum = currentIndex + 1;
  var totalImageCount = carouselSlides.length;

  // create buttons html
  const buttonsMarkup = `
    <div class="btn-container">
        <button class="control-btns pause-btn" tabindex="0">${pauseSVG}</button>
        <button class="chevron-btn left" tabindex="0">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.0275 15.7089C9.43537 15.1463 3.67485 9.23272 3.67485 9.23272C3.36031 8.93266 3.19995 8.53884 3.19995 8.14502C3.19995 7.7512 3.36031 7.35738 3.67485 7.05732C3.67485 7.05732 9.43537 1.14375 10.0275 0.581149C10.6195 0.0185475 11.6804 -0.0189593 12.3095 0.581149C12.9385 1.18126 12.9879 2.01891 12.3095 2.75654L7.02385 8.14502L12.3095 13.5335C12.9879 14.2711 12.9385 15.1088 12.3095 15.7089C11.6742 16.309 10.6134 16.2715 10.0275 15.7089Z"
                fill="#203240" />
            </svg>
        </button>

        <div class="control-bar__text-container"><span id="current-img-num">${currentImageNum}</span><span>/</span><span>${totalImageCount}</span></div>
        <button class="chevron-btn right" tabindex="0">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.97255 15.7089C6.56463 15.1463 12.3251 9.23272 12.3251 9.23272C12.6397 8.93266 12.8 8.53884 12.8 8.14502C12.8 7.7512 12.6397 7.35738 12.3251 7.05732C12.3251 7.05732 6.56463 1.14375 5.97255 0.581149C5.38046 0.0185475 4.31964 -0.0189593 3.69055 0.581149C3.06145 1.18126 3.01211 2.01891 3.69055 2.75654L8.97615 8.14502L3.69055 13.5335C3.01211 14.2711 3.06145 15.1088 3.69055 15.7089C4.3258 16.309 5.38663 16.2715 5.97255 15.7089Z"
                fill="#203240" />
            </svg>
        </button>
    </div>
    `;
  // add html to container
  var carouselContainer = document.querySelector('.bf155-carousel-container');
  carouselContainer.insertAdjacentHTML('beforeend', buttonsMarkup);
  var pauseBtn = document.querySelector('.control-btns.pause-btn');
  var leftBtn = document.querySelector('.chevron-btn.left');
  var rightBtn = document.querySelector('.chevron-btn.right');

  return {
    pauseBtn: pauseBtn,
    leftBtn: leftBtn,
    rightBtn: rightBtn,
  };
}

function createNewCarousel(carouselContentContainerList) {
  // create new carousel container
  var carouselWrapper = document.createElement('div');
  carouselWrapper.classList.add('bf155-carousel-wrapper');
  carouselWrapper.classList.add('wrapper');
  var carouselContainer = document.createElement('div');
  carouselContainer.classList.add('bf155-carousel-container');
  // create new carousel track and add to container
  var carouselTrack = document.createElement('div');
  carouselTrack.classList.add('carousel-track');
  carouselContainer.appendChild(carouselTrack);
  carouselWrapper.appendChild(carouselContainer);
  var carouselSlides = [];

  // Create new slides and add them into the slide track
  carouselContentContainerList.forEach(function (container, index) {
    // create a new slider div
    var newSlide = document.createElement('div');
    newSlide.classList.add('carousel-slide');

    // Create a DocumentFragment and append cloned child nodes to it
    const fragment = document.createDocumentFragment();
    Array.from(container.childNodes).forEach((node) => {
      fragment.appendChild(node.cloneNode(true));
    });

    // Append the DocumentFragment to the new slide
    newSlide.appendChild(fragment);
    // add new slider to track
    carouselTrack.appendChild(newSlide);
    // add new slider to slider list
    carouselSlides.push(newSlide);
    var anchorEl = newSlide.querySelector('.banner-v2-slide__content>a');

    // for the first slider (default)
    if (index === 0) {
      // add active state
      newSlide.classList.add('active');
      // add tabbable attribute
      if (anchorEl) {
        anchorEl.setAttribute('tabindex', 0);
      }
    } else {
      if (anchorEl) {
        anchorEl.setAttribute('tabindex', -1);
      }
    }
  });
  return {
    carouselWrapper: carouselWrapper,
    carouselTrack: carouselTrack,
    carouselSlides: carouselSlides,
  };
}

function updateTabIndex(previousSlide, nextSlide) {
  var nextAnchor = nextSlide.querySelector('.banner-v2-slide__content>a');
  var previousAnchor = previousSlide.querySelector(
    '.banner-v2-slide__content>a'
  );
  if (nextAnchor && previousAnchor) {
    nextAnchor.setAttribute('tabindex', 0);
    previousAnchor.setAttribute('tabindex', -1);
  }
}

function nextSlide() {
  var currentSlide = carouselSlides[currentIndex];
  currentSlide.classList.remove('active');
  currentIndex = (currentIndex + 1) % carouselSlides.length;
  var nextSlide = carouselSlides[currentIndex];
  nextSlide.classList.add('active');
  updateTabIndex(currentSlide, nextSlide);
  updateImageCount('next', carouselSlides, currentIndex);
}

function prevSlide() {
  var currentSlide = carouselSlides[currentIndex];
  currentSlide.classList.remove('active');
  currentIndex =
    (currentIndex - 1 + carouselSlides.length) % carouselSlides.length;
  var prevSlide = carouselSlides[currentIndex];
  prevSlide.classList.add('active');
  updateTabIndex(currentSlide, prevSlide);
  updateImageCount('prev', carouselSlides, currentIndex);
}

function updateImageCount(control, carouselSlides, i) {
  document.querySelector('#current-img-num').innerHTML = currentIndex + 1;
}

function togglePlay() {
  var pauseBtn = document.querySelector('.control-btns.pause-btn');
  if (isPaused) {
    intervalId = setInterval(nextSlide, window.innerWidth < 768 ? 6000 : 8500);
    pauseBtn.innerHTML = pauseSVG;
    pauseBtn.classList.add('pause-btn');

    pauseBtn.addEventListener('click', () => {
      modalTracking('Hero Banner â€“ Carousel CTAs', 'click', 'Pause');
    });
  } else {
    clearInterval(intervalId);
    pauseBtn.innerHTML = playSVG;
    pauseBtn.classList.add('play-btn');

    pauseBtn.addEventListener('click', () => {
      modalTracking('Hero Banner â€“ Carousel CTAs', 'click', 'Play');
    });
  }
  isPaused = !isPaused;
}

function handleGesture() {
  if (touchendX < touchstartX) {
    nextSlide();
  }
  if (touchendX > touchstartX) {
    prevSlide();
  }
}

function moveCards() {
  var carouselContainer = document.querySelector('.bf155-carousel-container');
  var heroBannerCards = document.querySelector('.hero-banner-cards');
  carouselContainer.after(heroBannerCards);
}

function setupBannerLinkClickEvents() {
  // Select all anchor elements within .banner-v2-slide__content
  const bannerLinks = document.querySelectorAll('.banner-v2-slide__content a');

  // Iterate over the NodeList and attach the click event listener to each anchor element
  bannerLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      const url = link.getAttribute('href');
      let child = link.closest('.carousel-slide');
      let parent = child.parentNode;
      let index = Array.prototype.indexOf.call(parent.children, child) + 1;
      modalTracking('Hero Banner - Carousel', 'click', index + ':' + url);
    });
  });
}

// Function to set up click events for buttons within .btn-container
function setupButtonEvents() {
  // Select the left chevron button
  const leftChevronButton = document.querySelector('.chevron-btn.left');
  // Select the right chevron button
  const rightChevronButton = document.querySelector('.chevron-btn.right');

  // Add click event listener to the left chevron button
  if (leftChevronButton) {
    leftChevronButton.addEventListener('click', () => {
      modalTracking('Hero Banner â€“ Carousel CTAs', 'click', 'Back');
    });
  }

  // Add click event listener to the right chevron button
  if (rightChevronButton) {
    rightChevronButton.addEventListener('click', () => {
      modalTracking('Hero Banner â€“ Carousel CTAs', 'click', 'Next');
    });
  }
}

function addCardClickListeners() {
  document.querySelectorAll('.hero-banner-card').forEach((card) => {
    card.addEventListener('click', (event) => {
      let text = card.querySelector('.hero-banner-card--text h4').innerText;
      let parent = card.closest('.hero-banner-cards');
      let index = Array.prototype.indexOf.call(parent.children, card) + 1;
      modalTracking('Hero Banner â€“ Side Cards ', 'click', index + ':' + text);
    });
  });
}

function modalTracking(category, action, label) {
  window.dataLayer.push({
    event: 'homepage',
    category: category,
    action: action,
    label: label,
  });
}
