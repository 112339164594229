var utils = window.optlyUtils || window.globalUtils;
var opUtils = window.optimizely.get('utils');

utils.poll(['.hero-banner-cards'], ([heroCards]) => {
  setupBannerLinkClickEvents();
});

function modalTracking(category, action, label) {
  window.dataLayer.push({
    event: 'homepage',
    category: category,
    action: action,
    label: label,
  });
}

function setupBannerLinkClickEvents() {
  // Select all anchor elements within .banner-v2-slide__content
  const bannerLinks = document.querySelectorAll('.banner-v2-slide__content a');

  // Iterate over the NodeList and attach the click event listener to each anchor element
  bannerLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      if (link) {
        const url = link.getAttribute('href');
        let child = link.closest('.banner-v2-slide');
        if (child) {
          let parent = child.parentNode;
          if (parent) {
            let index =
              Array.prototype.indexOf.call(parent.children, child) + 1;
            modalTracking('Hero Banner - Carousel', 'click', index + ':' + url);
          }
        }
      }
    });
  });
}
