/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
    console.log(
      "%c| BF142 - Holiday Details Hide Footer v2 v1.1 |",
      "background-color: #50BFE6; color: white; font-weight: bold;"
    );
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll(
      [".footer-links", ".footer-v2__info-columns"],
      ([footerLinks, footerInfoColumns]) => {
        document.body.classList.add("BF142");
      }
    );
  })();
  