function holidayTypeContent() {
    const beachIcon = `<svg width="40" height="31" xmlns="http://www.w3.org/2000/svg" class="search-box-group__icons-item" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M25.053 12.003c.26.034.442.272.408.53-.015.113-1.479 11.268-1.169 15.894a.475.475 0 01-.441.503l-.033.001a.475.475 0 01-.473-.441c-.315-4.72 1.115-15.618 1.177-16.08a.469.469 0 01.531-.407zm-12.618 3.79c.262 0 .474.211.474.472v12.193a.473.473 0 01-.948 0V16.265c0-.26.212-.472.474-.472zM8.395 9.34c2.63-.03 4.514-3.89 3.025-.186-.316.785 5.883.363 6.299.728.414.366-1.422 1.339-1.422 2.187 0 .402-1.461 5.693-1.64 5.338 0 0-.018-.914-.67-1.453-.576-.475-1.344-.514-1.516-.516h-.036c-1.588.258-5.675 7.267-5.675 7.267-2.902-1.24-1.217-5.583-2.343-5.76-1.125-.177-4.389 3.18-4.389 3.18-.297-2.245 1.832-5.407 2.942-6.283 1.175-.928 2.566-1.34 2.448-1.753-.119-.414-2.547-.473-3.613-.533C.739 11.5 3.334 9.553 4.199 9.355c2.036-.465 2.537.005 4.196-.014zm20.479-5.623c6.79-.945 9.003 2.206 8.844 2.679-.158.473-3 .473-4.422.945.008.065.172.75 3.48 2.82 3.332 2.086 2.206 7.108 2.206 7.108-.616.222-4.852-2.977-5.61-3.767l-.012-.013.001.01c.074 1.157-1.722 7.816-2.592 7.237-.948-.63-2.843-8.824-5.686-8.982-2.843-.158-8.846 5.673-8.846 5.673.158-5.042 4.423-8.195 2.686-8.51-.492-.09-6.792-.157-6.477-.63.317-.473.474-2.679 3.791-3.782 3.317-1.102 5.845-.788 5.845-.788-2.528-1.26-3.16-3.624-3.16-3.624 7.109-.788 9.952 3.624 9.952 3.624z" id="a"/><path id="c" d="M19.158 1.87H0L7.465.182 12.316 0z"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(.4 .2)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><use fill="#989797" xlink:href="#a"/><g mask="url(#b)" fill="#FFF"><path d="M0-.973h40v32.12H0z"/></g></g><g transform="translate(9.2 28.6)"><mask id="d" fill="#fff"><use xlink:href="#c"/></mask><use fill="#989797" xlink:href="#c"/><g mask="url(#d)" fill="#FFF"><path d="M-2.94-5.6h24.5v8.4h-24.5z"/></g></g></g></svg>`;
    const cityBreaksIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100" id="icon-city" class="search-box-group__icons-item"><path d="M11.288 33.597h1.769v33.915c0 1.938 1.572 3.488 3.537 3.488 1.965 0 3.537-1.55 3.537-3.488V33.597l13.755-.039v33.954c0 1.938 1.573 3.488 3.538 3.488 1.965 0 3.537-1.55 3.537-3.488V33.519l18.078-.038v34.03c0 1.939 1.572 3.489 3.537 3.489 1.965 0 3.538-1.55 3.538-3.488v-34.07l13.755-.039v34.109c0 1.938 1.572 3.488 3.537 3.488 1.965 0 3.537-1.55 3.537-3.488V33.403h1.769c3.458 0 6.288-2.79 6.288-6.201C95 23.79 92.17 21 88.712 21l-77.424.194C7.83 21.194 5 23.984 5 27.395a6.264 6.264 0 0 0 6.288 6.202zM12.481 75A3.476 3.476 0 0 0 9 78.5c0 1.944 1.547 3.5 3.481 3.5H87.52A3.476 3.476 0 0 0 91 78.5c0-1.944-1.547-3.5-3.481-3.5H12.48zM93.75 85H6.25C2.812 85 0 87.7 0 91s2.813 6 6.25 6h87.5c3.438 0 6.25-2.7 6.25-6s-2.813-6-6.25-6zM82.729 11.831L50 0 17.31 11.831 17 16h66z" fill="currentColor" fill-rule="evenodd"></path></svg>`;
    return `
        <div class="search-box__top-inner">
            <span class="search-box-group--info-wrapper  search-box-group--info-wrapper-no-divider">I'm looking for a</span>
            <div class="search-box-group search-box-group--holiday-type">
                <div class="search-box-group__icons">
                    ${beachIcon}
                    ${cityBreaksIcon}
                </div>

                <div class="search-box-group__field search-box-group__switch-btn">
                    <input type="radio" name="holiday-type" checked data-holiday-type="beach"></input>
                    <div class="search-box-group__input">beach holiday</div>
                    <input type="radio" name="holiday-type" data-holiday-type="city"></input>
                    <div class="search-box-group__input">city break</div>
                </div>
            </div>
        </div>
    `;
}

module.exports =  holidayTypeContent();
