/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/

(function () {
  var utils = window.optlyUtils || window.globalUtils;

  // Caching DOM elements
  const recentSearchesElement = document.querySelector(
    '[data-modal-id="recent-searches"]'
  );
  const viewAllElement = document.querySelector(
    '.hero-recent-searches__view-all'
  );

  // Ensure elements exist before proceeding
  if (!recentSearchesElement || !viewAllElement) {
    console.error('Required elements not found. Exiting script.');
    return;
  }

  // Poll for necessary elements and then execute main logic
  utils.poll(
    [
      '.hero-recent-searches',
      '.hero-recent-searches-card',
      '.hero-recent-searches-card__anchor',
    ],
    ([
      heroRecentSearches,
      heroRecentSearchCards,
      heroRecentSearchCardAnchor,
    ]) => {
      observeHeroRecentSearches();
      main(heroRecentSearches);

      // Attach event listeners
      recentSearchesElement.addEventListener('click', setupMutationObserver);
      viewAllElement.addEventListener('click', setupMutationObserver);

      // Tracking
      window.dataLayer.push({
        event: 'Streamlined_homepage',
        category: 'Recent searches strip',
        action: 'Impression',
        label: document
          .querySelectorAll('.hero-recent-searches-item')
          .length.toString(),
      });
    }
  );

  function repositionRecentSearches(heroRecentSearches) {
    const bannerWrapper = document.querySelector(
      '.hero-recent-searches-banner-wrapper'
    );
    if (heroRecentSearches && bannerWrapper) {
      bannerWrapper.insertBefore(heroRecentSearches, bannerWrapper.firstChild);
    }
  }

  function insertHTML(heroRecentSearches) {
    const heroRecentSearchesAnchor = heroRecentSearches.querySelectorAll(
      '.hero-recent-searches-card__anchor'
    );
    let heroRecentSearchesAnchorItem = document.querySelectorAll(
      '.hero-recent-searches-item'
    );

    // Remove existing items
    if (heroRecentSearchesAnchorItem) {
      heroRecentSearchesAnchorItem.forEach((item) => item.remove());
    }

    // Add the new searches
    heroRecentSearchesAnchor.forEach((item) => {
      item.insertAdjacentHTML('beforeend', getHTML());
    });
  }

  function getHTML() {
    const discountPerPerson = '100';

    //SALES PILL - add back in after hero-recent-searches-item__image - <div class="hero-recent-searches-item__discount-pill">Save £${discountPerPerson}pp with <span>myJet2</span></div>

    return `
          <div class="hero-recent-searches-item">
              <div class="hero-recent-searches-item__image-container">
                  <img src="" alt="" class="hero-recent-searches-item__image">
              </div>
              <div class="hero-recent-searches-item__content">
                  <h2 class="hero-recent-searches-item__content--destinations"></h2>
                  <p class="hero-recent-searches-item__content--departure"></p>
                  <div class="hero-recent-searches-item__footer">
                      <span class="hero-recent-searches-item__footer--date"><i class="fa fa-calendar"></i></span>
                      <span>•</span>
                      <span class="hero-recent-searches-item__footer--nights"></span>
                  </div>
              </div>
              <div class="hero-recent-searches-item__arrow">
                  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.712 15.75L25.288 22L18.712 28.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>
          </div>`;
  }

  function getApiData() {
    const currentSubdomain = window.location.hostname.split('.')[0];
    const apiUrl = `https://${currentSubdomain}.jet2holidays.com/api/jet2/searchresources/get`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const searchData = data.destinations;
        updateImagesPath(searchData);
      })
      .catch((error) => {
        console.error('Error fetching or parsing data:', error);
      });
  }

  function updateImagesPath(searchData) {
    // Select all elements with class hero-recent-searches-item
    const searchItems = document.querySelectorAll('.hero-recent-searches-item');

    searchItems.forEach((searchItem) => {
      // Extract the destination text from each item
      const destinationsElement = searchItem.querySelector(
        '.hero-recent-searches-item__content--destinations'
      );
      const destinationsText = destinationsElement.textContent.trim();
      const firstWordDestinations = destinationsText.split(' ')[0];
      // Remove any special characters at the end of the first word
      const cleanedFirstWordDestinations = firstWordDestinations.replace(
        /[^\w\s]+$/,
        ''
      );

      // Find the matching item in searchData based on the first word match
      let imagePath = getImageSrc(cleanedFirstWordDestinations, searchData);

      if (imagePath) {
        const imageElement = searchItem.querySelector(
          '.hero-recent-searches-item__image'
        );
        imageElement.src = imagePath;
      } else {
        const imageElement = searchItem.querySelector(
          '.hero-recent-searches-item__image'
        );
        imageElement.src =
          'https://www.jet2holidays.com/-/media/jet2holidays/images/optimizely/bf194-slimline-docked-basket/hotel.png';
        console.error(
          'BF130 - No matching destination found for:',
          cleanedFirstWordDestinations,
          'Fallback image Added'
        );
      }
    });
  }

  function populateRecentSearches() {
    const cards = document.querySelectorAll('.hero-recent-searches-card');

    cards.forEach((card) => {
      // Get the next sibling item element
      const item = card.nextElementSibling;

      if (item && item.classList.contains('hero-recent-searches-item')) {
        // Extract information from the card
        const date = card.querySelector(
          '.hero-recent-searches-card__date'
        ).textContent;
        const nights = card.querySelector(
          '.hero-recent-searches-card__nights'
        ).textContent;
        const destination = card.querySelector(
          '.hero-recent-searches-card__destination'
        ).textContent;
        const airportText = card.querySelector(
          '.hero-recent-searches-card__airport'
        ).textContent;
        const airport = airportText.replace('From: ', '');

        // Update the item with the extracted information
        item.querySelector(
          '.hero-recent-searches-item__content--destinations'
        ).textContent = destination;
        item.querySelector(
          '.hero-recent-searches-item__content--departure'
        ).textContent = `Departing from ${airport}`;
        item.querySelector(
          '.hero-recent-searches-item__footer--date'
        ).innerHTML = `<i class="fa fa-calendar"></i> ${date}`;
        item.querySelector(
          '.hero-recent-searches-item__footer--nights'
        ).textContent = nights;
      }
    });
  }

  function getImageSrc(searchName, searchData) {
    let imgSrc = '';
    let searchLevel = 0;
    const getFirstWordLowercase = (str) => str.split(' ')[0].toLowerCase();
    const searchNameFirstWord = getFirstWordLowercase(searchName);

    for (let region of searchData) {
      if (getFirstWordLowercase(region.name) === searchNameFirstWord) {
        imgSrc = `https://www.jet2holidays.com/-/media/jet2holidays/images/homepage%20v2/deals%20list/${region.destinations[0].id}.jpeg`;
        searchLevel = 1;
        break;
      }
    }

    if (searchLevel === 0) {
      for (let region of searchData) {
        for (let area of region.destinations) {
          if (getFirstWordLowercase(area.name) === searchNameFirstWord) {
            imgSrc = `https://www.jet2holidays.com/-/media/jet2holidays/images/homepage%20v2/deals%20list/${area.id}.jpeg`;
            searchLevel = 2;
            break;
          }
        }
        if (searchLevel === 2) break;
      }
    }

    if (searchLevel === 0) {
      for (let region of searchData) {
        for (let area of region.destinations) {
          for (let resort of area.destinations) {
            if (getFirstWordLowercase(resort.name) === searchNameFirstWord) {
              imgSrc = `https://www.jet2holidays.com/-/media/jet2holidays/images/homepage%20v2/deals%20list/${area.id}.jpeg`;
              searchLevel = 3;
              break;
            }
          }
          if (searchLevel === 3) break;
        }
        if (searchLevel === 3) break;
      }
    }

    return imgSrc;
  }

  function setupMutationObserver() {
    const targetNode = document.querySelector('[data-id="recent-searches"]');
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
          getApiData();
          populateRecentSearches();
        }
      });
    });
    observer.observe(targetNode, config);
    removeListeners();
  }

  function removeListeners() {
    recentSearchesElement.removeEventListener('click', setupMutationObserver);
    viewAllElement.removeEventListener('click', setupMutationObserver);
  }

  function main(heroRecentSearches) {
    repositionRecentSearches(heroRecentSearches);
    insertHTML(heroRecentSearches);
    getApiData();
    populateRecentSearches();
  }

  function observeHeroRecentSearches() {
    const targetNode = document.querySelector('.hero-recent-searches__inner');

    if (!targetNode) {
      console.error('Target node .hero-recent-searches__inner not found!');
      return;
    }

    const observerCallback = function (mutationsList) {
      for (let mutation of mutationsList) {
        // Only process added nodes and ignore deleted nodes
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            // Check if the added node contains the required class
            if (
              node.nodeType === 1 &&
              node.classList.contains('hero-recent-searches-card__anchor')
            ) {
              const heroRecentSearches = document.querySelector(
                '.hero-recent-searches'
              );
              // Call the main function with the updated heroRecentSearches
              main(heroRecentSearches);
            }
          });
        }
      }
    };

    const observer = new MutationObserver(observerCallback);

    const config = {
      childList: true, // Observe additions of child elements
      subtree: true, // Include all child elements within the subtree
    };

    // Start observing the target node with the defined configuration
    observer.observe(targetNode, config);
  }

  document.body.classList.add('bf130');
  document.body.classList.add('bf213');
})();
