/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
const utils = window.optlyUtils

const holidayTypeContent = require('Functions/holidayTypeContent')
const selectDestinations = require('Functions/selectDestinations')
const searchFilters = require('Functions/searchFilters')
const createDomElement = require('Functions/createDomElement')
const editOptionLabels = require('Functions/editOptionLabels')
const checkForGuestsModal = require('Functions/checkForGuestsModal')
const getHolidayType = require('Functions/getHolidayType')

const loader = `
<span class="modal-box__loader inpage-loader inpage-loader--white center-align inpage-loader--vertical-centre js-loading-spinners">
    <span class="inpage-loader__circle inpage-loader__circle--1"></span>
    <span class="inpage-loader__circle inpage-loader__circle--2"></span>
    <span class="inpage-loader__circle inpage-loader__circle--3"></span>
    <img class="inpage-loader__ie-fallback" src="/-/media/assets/images/loaders/loading-dots.png">
</span>
`

utils.poll([
    '#search-box-from+.search-box-group__clear-input',
    '.search-panel--v2',
    '[data-component="Feature/SearchPanels/SmartSearchPanel"]',
    '.search-box-group--from',
    '.search-box-group--to',
    '.search-box-group--leaving',
    '.search-box-group--guests',
    '.search-box-group--nights',
    '.form-select__origin--nights',
    '.search-box-group--from .search-box-group__clear-input',
    '.search-box-group--submit',
    () => {
        return document.querySelector('div.modal-box__content > .calendar-date-selector').parentNode
    },
    '.search-box--horizontal',
], ([
    fromClear,
    searchPanel,
    form,
    fromInput,
    toInput,
    leavingInput,
    guestsInput,
    nightsInput,
    durationSelect,
    clearAllDepartures,
    submitBtn,
    dateModalContent
]) => {
    window.addEventListener('load', () => {
        fromClear.click();
        searchFilters(getHolidayType(), guestsInput.querySelector('input').value.indexOf('child') > -1)
    });
    const doneButton = createDomElement('a', ['bttn', 'bttn--primary', 'bttn--3d', 'button--base'], 'Done')
    doneButton.addEventListener('click', () => {
        dateModalContent.parentNode.querySelector('.modal-box__button-close').click()
    })
    const doneButtonForDates = createDomElement('div', ['modal-box__close'])
    doneButtonForDates.appendChild(doneButton)
    const dateIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" class="icon icon--calendar modal-box__heading-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-calendar2"></use></svg>`
    dateModalContent.parentNode.querySelector('.modal-box__heading').innerText = 'When would you like to go?'
    dateModalContent.parentNode.querySelector('.modal-box__top').insertAdjacentHTML('beforeend', dateIcon)
    dateModalContent.parentNode.appendChild(doneButtonForDates)

    let guestsInputEventAdded = false
    guestsInput.addEventListener('click', () => {
        if (!guestsInputEventAdded) {
            guestsInputEventAdded = true
            utils.poll(['.sbm-guests--v2'], ([guestsModal]) => {
                const guestsModalBox = guestsModal.parentNode.parentNode,
                    modalTitlePeopleIcon = `<svg width="30" height="22" class="icon icon--calendar modal-box__heading-icon modal-box__heading-icon--v-bottom padding--none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <defs>
                                                <path d="M28 18.936a.868.868 0 01-.875.864h-6.497c.481-.11.853-.536.853-1.05 0 0 0-3.913-2.898-5.345-1.236-.612-1.324-.492-1.936-.634l.022-1.694s-.678-.514-.886-2.11c-.427.12-.558-.492-.59-.875-.023-.371-.241-1.552.273-1.442-.11-.788-.186-1.498-.143-1.87.132-1.312 1.411-2.689 3.38-2.787 2.319.098 3.238 1.475 3.369 2.787.033.372-.044 1.082-.153 1.87.514-.11.295 1.07.262 1.442-.022.394-.164.995-.59.875-.208 1.607-.886 2.11-.886 2.11l.01 1.694s.427.24 2.232.984c1.805.743 1.236.186 2.701.907C28 15.788 28 18.936 28 18.936zm-13.081-5.793c1.98.809 1.356.207 2.975.995 2.548 1.257 2.548 4.711 2.548 4.711a.954.954 0 01-.962.951H.962A.961.961 0 010 18.849s0-3.454 2.548-4.711c1.62-.798.996-.154 2.975-.962 1.98-.81 2.45-1.094 2.45-1.094l.022-1.869s-.743-.557-.973-2.328c-.46.131-.624-.536-.645-.973-.022-.405-.263-1.694.295-1.585-.12-.864-.197-1.64-.153-2.055C6.66 1.829 8.072.309 10.238.2c2.548.11 3.565 1.618 3.707 3.072.044.415-.054 1.191-.164 2.055.569-.11.317 1.17.296 1.585-.022.426-.186 1.093-.646.962-.23 1.76-.973 2.317-.973 2.317l.022 1.859c-.011.01.46.273 2.439 1.093z" id="z"/>
                                            </defs>
                                            <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                                                <mask id="y" fill="#fff">
                                                <use xlink:href="#z"/>
                                                </mask>
                                                <use fill="#000" xlink:href="#z"/>
                                                <g mask="url(#y)" fill="#444">
                                                <path d="M0-4h28v28H0z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        `

                guestsModalBox.querySelector('.modal-box__heading').innerHTML = `Who's going on holiday? ${modalTitlePeopleIcon}`
                // guestsModalBox.querySelector('.modal-box__top').insertAdjacentHTML('beforeend', )
                
                guestsModalBox.querySelector('.js-plus-room-btn').innerText = 'Add another room'
                const guestModalSubtitle = guestsModalBox.querySelector('h5.semibold.margin--bottom-half')
                guestModalSubtitle.parentNode.removeChild(guestModalSubtitle)

                const guestsContainer = guestsModalBox.querySelector('.sbm-guests__container')
                
                const callback = (mutations) => {
                    if (mutations.length === 2) { // Means that either 1st child is added or all the children removed
                        if (mutations[0].addedNodes[0].className === 'js-child-ages-panel') {
                            const parentElement = mutations[0].target
                            parentElement.querySelector('.js-child-box-container .sbm-guests__title').innerHTML = 'Age of child on date of return<br /><span class="age-explanation">*Children: Age 0-17</span>'
                            const description = parentElement.querySelector('.title-and-text--inline-link')
                            if (description) {
                                description.parentNode.removeChild(description)
                            }                            
                        }
                    }
                }

                const observer = new MutationObserver(callback)
                observer.observe(guestsContainer, {
                    attributes: false,
                    subtree: true,
                    childList: true
                })
            })
        }
    })

    const searchPanelContent = searchPanel.querySelector('.search-box--v2'),
        searchPanelImage = searchPanel.querySelector('.search-panel--v2__container'),
        title = createDomElement('h1', ['search-box__title'], 'Your dream holiday starts here...'),
        holidayTypeWrapper = createDomElement('div', ['search-box__top'], holidayTypeContent),
        searchWrapper = createDomElement('div', ['search-box__content']),
        fromLabel = createDomElement('div', ['search-box-group--info-label'], "I'd like to fly from"),
        onLabel = createDomElement('div', ['search-box-group--info-label'], '<span class="hide-medium-large">and would like to go </span>on'),
        forLabel = createDomElement('div', ['search-box-group--info-label'], 'for'),
        withLabel = createDomElement('div', ['search-box-group--info-label'], 'with'),
        planeIcon = `<svg class="search-box-group__icons-item--small" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-plane2" xmlns:xlink="http://www.w3.org/1999/xlink" class=""></use></svg>`,
        planeIconForDepartureModal = `<svg class="modal-box__heading-icon" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-plane2" xmlns:xlink="http://www.w3.org/1999/xlink" class=""></use></svg>`,
        exclamationIcon = `<svg xmlns="http://www.w3.org/2000/svg" class="alert-message__heading-icon" viewBox="0 0 100 100" width="20" height="20"><path d="M100 50c0 27.617-22.383 50-50 50S0 77.617 0 50 22.383 0 50 0s50 22.383 50 50zM57 22H44v35h13V22zm0 44H44v12h13V66z" fill="currentColor" fill-rule="evenodd"></path></svg>`,
        planeWrapper = createDomElement('span', ['search-box-group--info-label', 'search-box-group--info-label-no-divider', 'search-box-group--info-label-plane'], planeIcon),
        filtersWrapper = createDomElement('div', ['search-box__filters']),
        placeholderOption = document.createElement('option'),
        fromLink = fromInput.querySelector('.search-box-group__link'),
        fromWrapper = createDomElement('div', ['search-box-group--info-wrapper'], ''),
        forWrapper = createDomElement('div', ['search-box-group--info-wrapper'], '')

    let childrenSelected = guestsInput.querySelector('input').value.indexOf('child') > -1
    
    submitBtn.querySelector('.search-box__submit-text').innerText = 'Find my perfect holiday'
    searchPanel.classList.add('mvt004')
    toInput.classList.add('hidden')
    fromLink.classList.add('search-box-group__link-text')
    placeholderOption.text = 'Choose nights'
    durationSelect.add(placeholderOption)
    placeholderOption.classList.add('no-indent')
    placeholderOption.value = 'Choose Duration' 
    placeholderOption.selected = true
    durationSelect.parentNode.classList.remove('form-select--light')
    fromLink.innerHTML = ''
    // fromInput.querySelector('input').disabled = true

    searchPanelContent.insertBefore(title, form)
    form.insertBefore(searchWrapper, fromInput)

    searchWrapper.appendChild(holidayTypeWrapper)
    searchWrapper.appendChild(fromWrapper)
    searchWrapper.appendChild(forWrapper)
    fromWrapper.appendChild(fromInput)
    fromWrapper.appendChild(toInput)
    fromWrapper.appendChild(leavingInput)
    forWrapper.appendChild(nightsInput)
    forWrapper.appendChild(guestsInput)
    searchWrapper.appendChild(planeWrapper)
    searchWrapper.appendChild(filtersWrapper)
    searchWrapper.appendChild(submitBtn)
    
    fromWrapper.insertBefore(fromLabel, fromInput)
    fromWrapper.insertBefore(onLabel, leavingInput)
    forWrapper.insertBefore(forLabel, nightsInput)
    forWrapper.insertBefore(withLabel, guestsInput)

    const calendarBtns = document.querySelectorAll('.calendar-view__date')

    const departureValidationMessage = fromInput.querySelector('.validation-message')
    const dateValidationMessage = leavingInput.querySelector('.validation-message')
    const nightsValidationMessage = nightsInput.querySelector('.validation-message')

    departureValidationMessage.insertAdjacentHTML('afterbegin', exclamationIcon)
    dateValidationMessage.insertAdjacentHTML('afterbegin', exclamationIcon)
    nightsValidationMessage.insertAdjacentHTML('afterbegin', exclamationIcon)

    let textAdded = false
    submitBtn.addEventListener('click', () => {
        if (!fromInput.querySelector('input').value) {
            departureValidationMessage.classList.add('validation-message--active')
            departureValidationMessage.style.display = 'block'
            if (textAdded) {
                const span = departureValidationMessage.querySelector('span')
                span.innerText = 'At least one airport must be selected'
            } else {
                const text = createDomElement('span', [], 'At least one airport must be selected')
                departureValidationMessage.appendChild(text)
                textAdded = true
            }
        } else if (!toInput.querySelector('input').value) {
            departureValidationMessage.classList.add('validation-message--active')
            departureValidationMessage.style.display = 'block'

            if (textAdded) {
                const span = departureValidationMessage.querySelector('span')
                span.innerText = 'At least one airport must be selected and Done button clicked'
            } else {
                const text = createDomElement('span', [], 'At least one airport must be selected and Done button clicked')
                departureValidationMessage.appendChild(text)
                textAdded = true
            }
        }
    })

    durationSelect.addEventListener('change', () => {
        if (!durationSelect.value) {
            placeholderOption.selected = true
        }
    })

    fromInput.querySelector('input').placeholder = 'Choose departure airport'
    
    if (clearAllDepartures.getAttribute(['data-is-active']) === 'false') {
        utils.poll([() => { return clearAllDepartures.getAttribute(['data-is-active']) === 'true' }], () => {
            clearAllDepartures.addEventListener('click', () => {
                placeholderOption.selected = true
            })
        })
    }

    editOptionLabels(durationSelect, placeholderOption)
    for (let index = 0; index < calendarBtns.length; index++) {
        const btn = calendarBtns[index]
        btn.addEventListener('click', () => editOptionLabels(durationSelect, placeholderOption));  
    }
    
    checkForGuestsModal(childrenSelected)

    // const holidayType = getHolidayType()

    const holidayTypes = document.querySelectorAll('.search-box-group--holiday-type input')
    for (let i = 0; i < holidayTypes.length; i++) {
        holidayTypes[i].addEventListener('click', (e) => {
            const newholidayType = e.target.getAttribute(['data-holiday-type'])
            newholidayType === 'city'
                ? searchPanelImage.classList.add('search-panel--v2__container--city')
                : searchPanelImage.classList.remove('search-panel--v2__container--city')
            filtersWrapper.innerHTML = ''
            searchFilters(newholidayType, guestsInput.querySelector('input').value.indexOf('child') > -1)
            clearAllDepartures.click()

            departureValidationMessage.classList.remove('validation-message--active')
            departureValidationMessage.style.display = 'none'
            setTimeout(() => {
                placeholderOption.selected = true
            }, 1);
        })
    }

    let eventAdded = false
    let isLoaderIn = false
    let firstTime = true
    fromLink.addEventListener('click', function() {
        const clearAllDestinations = document.querySelector('.search-box-group--to .search-box-group__clear-input')
        if (clearAllDestinations) {
            clearAllDestinations.click()
        }

        utils.poll([
            () => {
                if (document.querySelector('.sbm-departure')) {
                    return document.querySelector('.sbm-departure').parentNode.parentNode.querySelector('.modal-box__close .bttn')
                }
            },
            '.sbm-description'
        ], ([departureDoneButton, departureDesc]) => {
            departureDesc.innerHTML = `Choose the UK departure airports most convenient for you.`
            if (!eventAdded) {
                eventAdded = true
                const departureModalTop = departureDoneButton.parentNode.parentNode.querySelector('.modal-box__top'),
                    departureModalHeading = departureModalTop.querySelector('.modal-box__heading')

                departureModalHeading.innerText = 'Where do you want to fly from?'
                departureModalHeading.insertAdjacentHTML('beforeend', planeIconForDepartureModal)

                departureDoneButton.addEventListener('click', () => {
                    const departureInputs = departureDoneButton.parentNode.parentNode.querySelectorAll('.checkbox-button-group__input')
                    let selectedInputs = []

                    for (let index = 0; index < departureInputs.length; index++) {
                        const input = departureInputs[index];
                        if(input.checked === true) selectedInputs.push(input)
                    }

                    if(selectedInputs.length > 0) {
                        departureValidationMessage.classList.remove('validation-message--active')
                        departureValidationMessage.style.display = 'none'
    
                        setTimeout(() => {
                            if (firstTime) {
                                firstTime = false
                                utils.poll(['.sbm-destination'], ([destinationModal]) => {
                                    if (!isLoaderIn) {
                                        destinationModal.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add('mvt004-destinations-modal')
                                        destinationModal.parentNode.parentNode.insertAdjacentHTML('afterbegin', loader)
                                        isLoaderIn = true
                                    }
            
                                    const holidayType = document.querySelector('.search-box-group--holiday-type input:checked').dataset.holidayType
        
                                    setTimeout(() => {
                                        selectDestinations(holidayType, () => {
                                            document.querySelector('.mvt004-destinations-modal .bttn').click()
                                        })
                                    }, 500);
                                })
                            } else {
                                utils.poll([
                                    () => {
                                        const destinationModal = document.querySelector('.mvt004-destinations-modal')
                                        if (destinationModal && destinationModal.style.display !== 'none') {
                                            return true
                                        }
                                    }
                                ], () => {
                                    const holidayType = document.querySelector('.search-box-group--holiday-type input:checked').dataset.holidayType
            
                                    selectDestinations(holidayType, () => {
                                        document.querySelector('.mvt004-destinations-modal .bttn').click()
                                    })
                                })
                            }    
                        }, 1);
                    }
                })
            }
        })
    })
})

