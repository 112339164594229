(function () {
    try {
      /* main variables */
      var debug = 0;
      var variation_name = "";
      var $;
      /* all Pure helper functions */

      var utils = window.optlyUtils || window.globalUtils;
      var opUtils = window.optimizely.get('utils');
  
      opUtils.waitForElement('.hero-wrapper__inner').then(function () {
        init();
        document.querySelector('body').classList.add('BF208');
      });
  
    } catch (e) {
      if (debug) console.log(e, "error in Test " + variation_name);
    }

    /* Variation Init */
    function init() {
        var descriptionEl = document.querySelector('.description-wrapper .panel--description');
        var ulElement = descriptionEl.querySelector('ul');
        var ulHeading = descriptionEl.querySelector('h2');
        if (descriptionEl && ulElement && ulHeading) {
          descriptionEl.parentNode.insertBefore(ulHeading, descriptionEl.nextSibling);
          descriptionEl.parentNode.insertBefore(ulElement, ulHeading.nextSibling);
        }
        if(!ulElement && descriptionEl){
          descriptionEl.closest(".grid__item").style.display = "none";
          let map = document.querySelector(".grid__item.grid__item--span-tablet-4");
          if(map){
            map.classList.remove("grid__item--span-tablet-4");
          }
          let grid = descriptionEl.closest(".grid");
          if(grid){
            grid.classList.remove("grid--12");
          }
        }
  
        var heroWrapper = document.querySelector('.hero-wrapper__inner');
        if (descriptionEl && heroWrapper) {
          heroWrapper.insertAdjacentElement('afterbegin', descriptionEl);
        }
  
        // Adding scrollbar in Mobile
        const targetElement = document.querySelector('.description-wrapper .container .panel__title + ul');
        if (targetElement && window.innerWidth <= 767) {
          const wrapperDiv = document.createElement('div');
          wrapperDiv.classList.add('eg-scrollbar');
          targetElement.parentNode.insertBefore(wrapperDiv, targetElement);
          wrapperDiv.appendChild(targetElement);
        }

        let travellorsChoiceBadge = document.querySelector("#overview .section.destination-logo");
        let saleBadge = document.querySelector(".hero-wrapper__inner .banner-component__round--poi-hero");

        if(travellorsChoiceBadge && saleBadge){
          saleBadge.insertAdjacentElement('afterend', travellorsChoiceBadge);
        }

        if(saleBadge){
          let container = saleBadge.closest(".container");
          if(container){
            container.classList.remove("container");
          }
        }

      }
  })();
  