
let utils = window.optlyUtils || window.globalUtils;
let opUtils = window.optimizely.get("utils");
const countDownDate = new Date(Date.now() + 1000 * (60 * 18)).getTime(); // Set countdown 18 minutes from now
let timerInterval; // Declare timerInterval globally
opUtils.waitForElement(".full-screen-loader[data-spin]:not(.full-screen-loader--active)").then(function () {
    utils.poll([".basket-summary__totals-block"], ([basket]) => {
        main();
        document.querySelector("body").classList.add("BF187");
    });
});

function main() {
    insertPromos();
    handleTimer();
    addHoverEventListener();
    addCloseEventListener();
}

function insertPromos() {
    let basketTotals = document.querySelectorAll(".basket-summary__totals-top .basket-summary__totals-block");

    //let countdownPromo = '<div class="cta-countdown-block"><div data-tooltip="tooltip-basket-terms" class="component-tooltip component-tooltip--left component-tooltip--open"><div class="component-tooltip__close"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use></svg></div><p class="component-tooltip__text">Prices update hourly and may change</p></div><div class="cta-countdown-block__icon"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.49098 0.000883071C3.74344 0.0615754 -0.0599765 3.96611 0.000716389 8.72035C0.0614093 13.4678 3.96599 17.2712 8.72026 17.2105C13.4745 17.1498 17.2712 13.2453 17.2105 8.49106C17.1498 3.73683 13.2453 -0.0665528 8.49098 0.000883071ZM9.44859 2.86691C10.3253 2.86691 10.5815 3.37268 10.5815 3.95263C10.5815 4.68093 10.0016 5.34855 9.01026 5.34855C8.18079 5.34855 7.78965 4.93045 7.80989 4.2426C7.80989 3.66265 8.29543 2.86691 9.44859 2.86691ZM7.20297 13.9803C6.60278 13.9803 6.16445 13.6162 6.58255 12.0247L7.27041 9.1924C7.39179 8.74058 7.41202 8.5585 7.27041 8.5585C7.08833 8.5585 6.31281 8.86871 5.85424 9.17891L5.55752 8.68663C7.01415 7.47278 8.68657 6.75796 9.4014 6.75796C10.0016 6.75796 10.096 7.46604 9.79928 8.55176L9.01701 11.5257C8.8754 12.0517 8.93609 12.2338 9.07771 12.2338C9.25978 12.2338 9.84648 12.018 10.4197 11.5594L10.7569 12.0112C9.34745 13.4409 7.80315 13.9803 7.20297 13.9803Z" fill="#0F61E6"/></svg></div><div class="cta-countdown-block__text"><div class="cta-countdown-block__heading">Prices may go up, book now to secure this price!</div><div class="cta-countdown-block__sub-heading">This page will time out in <span class="cta-countdown-block__timer">18:00</span></div><div></div>';
    let countdownPromo = '<div class="cta-countdown-block"><div class="cta-countdown-block__text"><div class="cta-countdown-block__heading">Prices may go up, book now to secure this price!</div><div class="cta-countdown-block__sub-heading">This page will time out in <span class="cta-countdown-block__timer">18:00</span></div><div></div>';
    
    if (basketTotals.length > 0) {
        for (let i = 0; i < basketTotals.length; i++) {
            basketTotals[i].insertAdjacentHTML("beforeBegin", countdownPromo);
        }
    }

    let header = document.querySelector(".header-details .header-details__bottom:last-child");
    if (header) {
        header.insertAdjacentHTML("afterend", countdownPromo);
    }
}

function handleTimer() {
    if (timerInterval) {
        clearInterval(timerInterval); // Clear the previous interval if it exists
    }

    timerInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        // Time calculations for minutes and seconds
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Add leading zero if necessary
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

        // Display the result in the timer element
        let timerDisplays = document.querySelectorAll('.cta-countdown-block__timer');

        for (let i = 0; i < timerDisplays.length; i++) {
            timerDisplays[i].textContent = formattedMinutes + ":" + formattedSeconds;

            // Check if the countdown is over and stop the timer if it is
            if (distance < 0) {
                clearInterval(timerInterval);
                timerDisplays[i].textContent = "00:00";
            }
        }

    }, 1000);
}

function addHoverEventListener() {
    const svgElements = document.querySelectorAll('.cta-countdown-block__icon svg');

    svgElements.forEach(svg => {
        svg.addEventListener('mouseenter', () => {
            const tooltip = svg.closest('.cta-countdown-block').querySelector('.component-tooltip');
            if (tooltip) {
                tooltip.classList.add('active');
                tooltip.style.removeProperty('opacity');
                tooltip.style.removeProperty('display');
            }
        });

        svg.addEventListener('mouseleave', () => {
            const tooltip = svg.closest('.cta-countdown-block').querySelector('.component-tooltip');
            if (tooltip) {
                tooltip.classList.remove('active');
                tooltip.style.removeProperty('opacity');
                tooltip.style.removeProperty('display');
            }
        });
    });
}

function addCloseEventListener() {
    const closeElements = document.querySelectorAll('.cta-countdown-block .component-tooltip__close');

    closeElements.forEach(close => {
        close.addEventListener('click', () => {
            const tooltip = close.closest('.component-tooltip');
            if (tooltip) {
                tooltip.classList.remove('active');
            }
        });
    });
}