/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function() {
    console.log("%c| JD109 - Rooms left tooltip V2.3", "background-color: #50BFE6; color: white; font-weight: bold;");
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll([".detailed-card",".detailed-card-container",".price-column"],([detailedCard, detailedCardContainer, priceColumn]) => {

        const targetNode = document.querySelector('.detailed-card-wrapper');

        if (targetNode) {
            const observer = new MutationObserver((mutationsList) => {
                let mutationOccurred = false;
        
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        mutationOccurred = true;
                        break;
                    }
                }
        
                if (mutationOccurred) {
                    setTimeout(() => {
                        console.log('Child nodes have changed:', mutationsList);
                        addNewRoomsLeftTooltip();
                        addTooltipEventListener();
                    }, 100); 
                }
            });
        
            observer.observe(targetNode, { childList: true});
        }
                    
        addNewRoomsLeftTooltip();
        utils.poll([".inclusive-list__item--with-badge"],([badgeContainer]) => {
            addTooltipEventListener();
        });
        document.body.classList.add('jd109');
    });
    function addNewRoomsLeftTooltip() {
        let badges = document.querySelectorAll('.inclusive-list__item.inclusive-list__item--with-badge .badge.badge--urgent');
        badges.forEach(badge => {
            // Remove existing tooltip SVG and content if they exist
            const existingSvg = badge.nextElementSibling;
            const existingContent = badge.parentElement.nextElementSibling;
            if(existingSvg && existingContent) {
                if (existingSvg.classList.contains("js-component-tooltip-svg") && existingContent.classList.contains('js-component-tooltip-content')) {
                    existingSvg.remove();
                    existingContent.remove();
                }
            }
            // Add new tooltip SVG and content
            badge.insertAdjacentHTML('afterend', getTooltipSvgHtml());
            let badgeParent = badge.parentElement;
            badgeParent.insertAdjacentHTML('afterend', getTooltipContentHtml());
            wrapContainer(badge);
        });
    }
    function getTooltipSvgHtml() {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"  class="price-column__secure-icon js-component-tooltip component-tooltip--rooms-left-svg js-component-tooltip-svg"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-info"></use></svg>`;
    }
    function getTooltipContentHtml() {
        return `<div class="component-tooltip component-tooltip--rooms-left-content js-component-tooltip-content"> <p class="component-tooltip__text">We’ve almost sold out of this room type. Other rooms may be available or more may become available at a later date.</p></div>`;
    }
    function addTooltipEventListener() {
        document.querySelectorAll('.js-component-tooltip-svg').forEach((element, index) => {
            
            const toggleItem = document.querySelectorAll('.js-component-tooltip-content')[index];
            
            element.addEventListener('mouseenter', function() {
                toggleItem.style.display = 'block';
                toggleItem.classList.add('component-tooltip--open');
                toggleItem.style.opacity = '1';
                toggleItem.style.top = getTopDistance(this) + 'px';
                toggleItem.style.left = getRightSideDistance(element) + 'px';
                toggleItem.style.transform = "unset";
                toggleItem.querySelector("p").style.transform = "unset";
                //Tracking
                window.dataLayer = window.dataLayer || []; 
                window.dataLayer.push({ 
                    "event": "SR_Card", 
                    "category": "Tooltip Interaction", 
                    "action": "Open", 
                    "label": "Room Availability"
                });
                // Hide tooltip if it goes out of view
                checkTooltipVisibility(toggleItem);
            });

             // Hide tooltip on mouseleave
            element.addEventListener('mouseleave', function() {
                console.log(`Element ${index} mouseleave`);
                toggleItem.style.display = 'none';
                toggleItem.classList.remove('component-tooltip--open');
                toggleItem.style.opacity = '0';
            });
            
            element.addEventListener('click', function (event) {
                event.stopPropagation(); // Prevents closing immediately when clicking the element itself
                
                // Check if tooltip is currently open
                const isVisible = toggleItem.classList.contains('component-tooltip--open');
            
                if (isVisible) {
                    // Hide tooltip
                    console.log("// Hide tooltip");
                    toggleItem.style.opacity = '0';
                    toggleItem.style.display = 'none';
                    toggleItem.classList.remove('component-tooltip--open');

                } else {
                    // Show tooltip
                    console.log("// Show tooltip")
                    toggleItem.style.display = 'block';
                    toggleItem.style.opacity = '1';
                    toggleItem.classList.add('component-tooltip--open');

                    toggleItem.style.top = getTopDistance(this) + 'px';
                    if (window.innerWidth < 600) {
                        if (element.getBoundingClientRect().x > (window.innerWidth - element.getBoundingClientRect().right)) {
                            toggleItem.style.left = getRightSideDistance(element) + 'px';
                            toggleItem.style.transform = "unset";
                            toggleItem.querySelector("p").style.transform = "unset";
                        }
                        else {
                            toggleItem.style.left = '0px';
                            toggleItem.style.transform = `translateX(${element.getBoundingClientRect().x - 40}px) scaleX(-1)`;
                            toggleItem.querySelector("p").style.transform = "scaleX(-1)";
                        }
                    } else {
                        toggleItem.style.left = getRightSideDistance(element) + 'px';
                        toggleItem.style.transform = "unset";
                        toggleItem.querySelector("p").style.transform = "unset";
                    }

                    //Tracking
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({ 
                        "event": "SR_Card", 
                        "category": "Tooltip Interaction", 
                        "action": "Open", 
                        "label": "Room Availability"
                    });
                    
                    checkTooltipVisibility(toggleItem);
                }
            });
            
            
        });
        
        // Close tooltip when clicking anywhere else on the document
        document.addEventListener('click', function(event) {
            document.querySelectorAll('.js-component-tooltip-content').forEach((tooltip) => {
                if (!tooltip.contains(event.target) && !event.target.classList.contains('js-component-tooltip-svg')) {
                    console.log('Click outside tooltip');
                    tooltip.style.display = 'none';
                    tooltip.classList.remove('component-tooltip--open');
                    tooltip.style.opacity = '0';
                }
            });
        });
        
    }
    function addEventListenerJquery() {
        console.log('addEventListenerJquery()');
        $('.js-component-tooltip-svg').each(function(index) {
            $(this).on('click', function() {
              const $toggleItem = $('.js-component-tooltip-content').eq(index);
              const isHidden = $toggleItem.css('display') === 'none';
          
              if (isHidden) {
                console.log(`Element ${index} is hidden`);
                                
                $toggleItem.velocity('slideDown', { 
                  duration: 300, 
                  complete: function() {
                    $toggleItem.addClass('component-tooltip--open').css('opacity', '1');
                  }
                });
              } else {
                console.log(`Element ${index} is visible`);
                $toggleItem.css({
                    'top': getTopDistance(this),
                    'left': getRightSideDistance(this)
                  });
          
                $toggleItem.velocity('slideUp', { 
                  duration: 300, 
                  complete: function() {
                    $toggleItem.removeClass('component-tooltip--open').css('opacity', '0');
                  }
                });
              }
            });
          });
          
    }
    function getRightSideDistance(icon) {
        console.log("Icon:", icon);
        if (icon) {
            const iconRect = icon.getBoundingClientRect();
            const iconParent = icon.closest(".inclusive-list__item.inclusive-list__item--with-badge");
        
            if (iconParent) {
                const parentRect = iconParent.getBoundingClientRect();
        
                // Calculate the left distance of the icon from the left edge of the viewport (screen width)
                let leftDistance = iconRect.left - parentRect.left;
                leftDistance = -(leftDistance - 180);
                leftDistance = -leftDistance;
        
                return leftDistance;
            } else {
                console.error("Parent element not found.");
            }
        } else {
            console.error("Icon element not found.");
        }
    }
    function getTopDistance(icon) {
        console.log("Icon:", icon);
        const element = icon.closest(".inclusive-list__item.inclusive-list__item--with-badge");
        // Get the element's height including padding top and bottom
        const height = element.offsetHeight;
        const totalHeight = height + 6;
        return totalHeight;

    }
    function checkTooltipVisibility(tooltip) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    tooltip.style.display = 'none';
                    tooltip.classList.remove('component-tooltip--open');
                    tooltip.style.opacity = '0';
                }
            });
        }, { threshold: 0.1 });
    
        observer.observe(tooltip);
    }
    function wrapContainer(badge) {
        console.log("wrapContainer()");
        const svg = badge.nextElementSibling;
        if (badge && svg) {
            // Create a new div element
            const badgeContainer = document.createElement("div");
            badgeContainer.classList.add("inclusive-list__badge-wrapper");
    
            // Insert the badge and SVG inside the new div
            badge.parentNode.insertBefore(badgeContainer, badge);
            badgeContainer.appendChild(badge);
            badgeContainer.appendChild(svg);
        }
    }
})();