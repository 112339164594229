/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function() {
    //console.log("%c| JD109 - Rooms left tooltip V2.8", "background-color: #50BFE6; color: white; font-weight: bold;");
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll([".detailed-card",".detailed-card-container",".price-column"],([detailedCard, detailedCardContainer, priceColumn]) => {

        const targetNode = document.querySelector('.detailed-card-wrapper');

        if (targetNode) {
            const observer = new MutationObserver((mutationsList) => {
                let mutationOccurred = false;
        
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        mutationOccurred = true;
                        break;
                    }
                }
        
                if (mutationOccurred) {
                    setTimeout(() => {
                        addNewRoomsLeftTooltip();
                        addTooltipEventListener();
                    }, 100); 
                }
            });
        
            observer.observe(targetNode, { childList: true});
        }
                    
        addNewRoomsLeftTooltip();
        utils.poll([".inclusive-list__item--with-badge"],([badgeContainer]) => {
            addTooltipEventListener();
        });
        window.addEventListener("resize", addTooltipEventListener);
        document.body.classList.add('jd109');
    });
    function addNewRoomsLeftTooltip() {
        let badges = document.querySelectorAll('.inclusive-list__item.inclusive-list__item--with-badge .badge.badge--urgent');
        badges.forEach(badge => {
            /* Remove existing tooltip SVG and content if they exist
            const existingSvg = badge.nextElementSibling;
            const existingContent = badge.parentElement.nextElementSibling;
            if(existingSvg && existingContent) {
                if (existingSvg.classList.contains("js-component-tooltip-svg") && existingContent.classList.contains('js-component-tooltip-content')) {
                    existingSvg.remove();
                    existingContent.remove();
                }
            }*/
            const badgeWrapper = badge.parentElement;

            // Remove all existing SVGs next to the badge
            const existingSvgs = badgeWrapper.querySelectorAll(".js-component-tooltip-svg");
            existingSvgs.forEach(svg => svg.remove());
            
            // Remove all existing content elements next to the badge
            const existingContents = badgeWrapper.parentElement.parentElement.querySelectorAll(".js-component-tooltip-content");
            existingContents.forEach(content => content.remove());
                
            // Add new tooltip SVG and content
            badge.insertAdjacentHTML('afterend', getTooltipSvgHtml());
            let badgeParent = badge.parentElement;
            badgeParent.insertAdjacentHTML('afterend', getTooltipContentHtml());
            wrapContainer(badge);
        });
    }

    function getTooltipSvgHtml() {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"  class="price-column__secure-icon js-component-tooltip component-tooltip--rooms-left-svg js-component-tooltip-svg"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-info"></use></svg>`;
    }

    function getTooltipContentHtml() {
        return `<div class="component-tooltip component-tooltip--rooms-left-content js-component-tooltip-content"> <p class="component-tooltip__text">We’ve almost sold out of this room type. Other rooms may be available or more may become available later.</p></div>`;
    }

    function handleMouseEnter(event) {
        //console.log("Tooltip is visible, mouseenter event triggered.");
        const toggleItem = event.target.parentElement.parentElement.nextElementSibling; // Assuming tooltip is next sibling
        if (!toggleItem) return;
        
        toggleItem.style.display = 'block';
        toggleItem.classList.add('component-tooltip--open');
        toggleItem.style.opacity = '1';
        toggleItem.style.top = getTopDistance(this) + 'px';
        toggleItem.style.left = getRightSideDistance(this) + 'px';
        toggleItem.style.transform = "unset";
        toggleItem.querySelector("p").style.transform = "unset";
    
        // Hide tooltip if it goes out of view
        checkTooltipVisibility(toggleItem);

        //Tracking
        window.dataLayer = window.dataLayer || []; 
        window.dataLayer.push({ 
            "event": "SR_Card", 
            "category": "Tooltip Interaction", 
            "action": "Open", 
            "label": "Room Availability"
        });
    }
    
    function handleMouseLeave(event) {
        //console.log("Tooltip hidden, mouseleave event triggered.");
        //console.log(event.target.nextElementSibling);
        //console.log(event.target);
        const toggleItem = event.target.parentElement.parentElement.nextElementSibling; // Assuming tooltip is next sibling
        if (!toggleItem) return;
    
        toggleItem.style.display = 'none';
        toggleItem.classList.remove('component-tooltip--open');
        toggleItem.style.opacity = '0';
    }
    
    function handleClick(event) {
        //console.log("handleClick triggered.");
        event.stopPropagation();
        //console.log(event.target);
        let toggleItem = null;
        if (event.target.classList.contains("js-component-tooltip-svg")) {
            toggleItem = event.target.parentElement.parentElement.nextElementSibling;
        } else {
            toggleItem = event.target.parentElement.parentElement.parentElement.nextElementSibling;
        }
             
        //console.log(toggleItem);
        if (!toggleItem) {
            //console.log("No toggleItem found.");
            return;
        }
    
        const isVisible = toggleItem.classList.contains('component-tooltip--open');
        //console.log("handleClick triggered. - isVisible: " + isVisible);
        //console.log({toggleItem});
        setTimeout(() => {
            if (isVisible) {
                // Hide tooltip
                //console.log("Tooltip is visible, hiding it now.");
                toggleItem.style.opacity = '0';
                toggleItem.style.display = 'none';
                toggleItem.classList.remove('component-tooltip--open');

            } else {
                // Show tooltip
                //console.log("Tooltip is not visible, showing it now.");
                toggleItem.style.display = 'block';
                toggleItem.style.opacity = '1';
                toggleItem.classList.add('component-tooltip--open');

                toggleItem.style.top = getTopDistance(this) + 'px';
                if (window.innerWidth < 600) {
                    //console.log("Tooltip is visible, If condition");
                    if (event.target.getBoundingClientRect().x > (window.innerWidth - event.target.getBoundingClientRect().right)) {
                        //console.log("Tooltip is visible, If - IF condition");
                        toggleItem.style.left = getRightSideDistance(event.target) + 'px';
                        toggleItem.style.transform = "unset";
                        toggleItem.querySelector("p").style.transform = "unset";
                    }
                    else {
                        //console.log("Tooltip is visible, If -else condition");
                        toggleItem.style.left = '0px';
                        toggleItem.style.transform = `translateX(${event.target.getBoundingClientRect().x - 40}px) scaleX(-1)`;
                        toggleItem.querySelector("p").style.transform = "scaleX(-1)";
                    }
                } else {
                    //console.log("Tooltip is visible, else condition");
                    toggleItem.style.left = getRightSideDistance(event.target) + 'px';
                    toggleItem.style.transform = "unset";
                    toggleItem.querySelector("p").style.transform = "unset";
                }

                //Tracking
                window.dataLayer = window.dataLayer || []; 
                window.dataLayer.push({ 
                    "event": "SR_Card", 
                    "category": "Tooltip Interaction", 
                    "action": "Open", 
                    "label": "Room Availability"
                });
                
                checkTooltipVisibility(toggleItem);
            }
        }, 0);
    }

    function addTooltipEventListener() {
        const isMobile = window.innerWidth < 1024; // Adjust breakpoint as needed
        document.querySelectorAll('.js-component-tooltip-svg').forEach((element) => {
            // Remove old event listeners
            element.removeEventListener("mouseenter", handleMouseEnter);
            element.removeEventListener("mouseleave", handleMouseLeave);
            element.removeEventListener("click", handleClick);
    
            if (!isMobile) {
                //console.log("Adding mouseenter & mouseleave events for desktop.");
                element.addEventListener("mouseenter", handleMouseEnter);
                element.addEventListener("mouseleave", handleMouseLeave);
            } else {
                //console.log("Adding click event for mobile.");
                element.addEventListener("click", handleClick);
            }
        });
    
        // Close tooltip when clicking anywhere else on the document
        document.addEventListener("click", function (event) {
            document.querySelectorAll(".js-component-tooltip-content").forEach((tooltip) => {
                if (!tooltip.contains(event.target) && !event.target.classList.contains("js-component-tooltip-svg")) {
                    tooltip.style.display = "none";
                    tooltip.classList.remove("component-tooltip--open");
                    tooltip.style.opacity = "0";
                }
            });
        });
    }

    function getRightSideDistance(icon) {
        if (icon) {
            const iconRect = icon.getBoundingClientRect();
            const iconParent = icon.closest(".inclusive-list__item.inclusive-list__item--with-badge");
        
            if (iconParent) {
                const parentRect = iconParent.getBoundingClientRect();
        
                // Calculate the left distance of the icon from the left edge of the viewport (screen width)
                let leftDistance = iconRect.left - parentRect.left;
                leftDistance = -(leftDistance - 180);
                leftDistance = -leftDistance;
        
                return leftDistance;
            } else {
                console.error("Parent element not found.");
            }
        } else {
            console.error("Icon element not found.");
        }
    }

    function getTopDistance(icon) {
        const element = icon.closest(".inclusive-list__item.inclusive-list__item--with-badge");
        // Get the element's height including padding top and bottom
        const height = element.offsetHeight;
        const totalHeight = height + 6;
        return totalHeight;

    }

    function checkTooltipVisibility(tooltip) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    tooltip.style.display = 'none';
                    tooltip.classList.remove('component-tooltip--open');
                    tooltip.style.opacity = '0';
                }
            });
        }, { threshold: 0.1 });
    
        observer.observe(tooltip);
    }

    function wrapContainer(badge) {
        const svg = badge.nextElementSibling;
        if (badge && svg) {
            // Check if the badge already has a wrapper
            if (!badge.parentNode.classList.contains("inclusive-list__badge-wrapper")) {
                // Create a new div element
                const badgeContainer = document.createElement("div");
                badgeContainer.classList.add("inclusive-list__badge-wrapper");
        
                // Insert the badge and SVG inside the new div
                badge.parentNode.insertBefore(badgeContainer, badge);
                badgeContainer.appendChild(badge);
                badgeContainer.appendChild(svg);
            }
        }
    }

})();