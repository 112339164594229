(() => {
    window.dataLayer = window.dataLayer || [];
    const opUtils = window.optimizely.get('utils');

    const boardOptionDetails = {
        allinclusiveplus:
            '<li>Breakfast</li><li>Lunch</li><li>Evening Meal</li><li>Snacks</li><li>Branded Drinks</li>',
        allinclusive:
            '<li>Breakfast</li><li>Lunch</li><li>Evening Meal</li><li>Snacks</li><li>Drinks</li>',
        fullboardplus: '<li>Breakfast</li><li>Lunch</li><li>Evening Meal</li><li>Plus Extras</li>',
        fullboard: '<li>Breakfast</li><li>Lunch</li><li>Evening Meal</li>',
        halfboardplus: '<li>Breakfast</li><li>Evening Meal</li><li>Plus Extras</li>',
        halfboard: '<li>Breakfast</li><li>Evening Meal</li>',
        bedandbreakfast: '<li>Breakfast</li>',
        selfcatering: '<span>Catering facilities available in your room.</span>',
        // roomonly: '<li>test content</li>',
    };

    opUtils.waitForElement('.board-basis').then((boardBasisElement) => {
        const boardOptionElements = Array.from(
            boardBasisElement.querySelectorAll('.option-item--board-basis')
        );

        let impressionString = !boardOptionElements.length ? "Not available" : "";

        boardOptionElements.forEach((option, i) => {
            const optionLabel = option.querySelector('.option-item__title').innerText.trim();
            const optionLabelKey = optionLabel.replaceAll(' ', '').toLowerCase();
            const optionDetailsSection = option.querySelector('.option-item__details');
            const optionMoreInfoLink = option.querySelector('.option-item__see-more');

            // check if the board label has a matching description
            if (boardOptionDetails[optionLabelKey] && optionDetailsSection) {
                const containerElement = document.createElement(optionLabel === 'selfcatering' ? 'p' : 'ul');
                containerElement.classList.add('board-details-container');
                containerElement.innerHTML = boardOptionDetails[optionLabelKey];
                optionDetailsSection.insertBefore(
                    containerElement,
                    optionDetailsSection.firstChild
                );
            }

            if (optionMoreInfoLink) {
                optionMoreInfoLink.addEventListener('click', e => {
                    pushToDatalayer('Board basis - more info link', `${e.target.innerText.trim() === "close" ? "Close" : "Open"}`, optionLabel);
                });
            }

            // Add value to string that is used for tracking
            impressionString += ` ${i === 0 ? '' : '| '}${optionLabel}${option.classList.contains('option-item--selected') ? '(default)' : ''}`;
            impressionString = impressionString.trim();
        });

        runWhenElementInView(boardBasisElement, () => pushToDatalayer('Board basis available', 'Impression', impressionString));
    });



    function pushToDatalayer(cat = '', action = '', label = '') {
        window.dataLayer.push({
            event: 'holiday_options',
            category: cat,
            action: action,
            label: label,
        });
    }

    function runWhenElementInView(element, callback) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback();
                    observer.disconnect();
                }
            });
        });

        observer.observe(element);
    }

})();




