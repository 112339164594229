/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
    // console.log(
    //   "%c| BF218 - FLEXI HP CONTROL V1.0 |",
    //   "background-color: #50BFE6; color: white; font-weight: bold;"
    // );
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll(["#search-box-leaving"], ([searchBoxLeaving]) => {
      let prevDisplayState = null; // Track the last known display state of the modal
  
      const calendarDateModalEl = Array.from(
        document.querySelectorAll(".modal")
      ).find((modal) => modal.querySelector(".calendar-date-selector"));
  
      function trackModalImpression() {
        if (isModalVisible() && isSmartSearch()) {
          // console.log("Modal is visible and smart search is active.");
          const currentDisplayState = calendarDateModalEl.style.display;
  
          // Push to dataLayer only if the state has changed to `block`
          if (
            currentDisplayState === "block" &&
            (prevDisplayState !== "block" || prevDisplayState === null)
          ) {
            window.dataLayer.push({
              event: "search_panel",
              category: "Calendar Modal",
              action: "Impression",
              label: "Modal Open - Flexible Search",
            });
          }
          // Update the previous state
          prevDisplayState = currentDisplayState;
        } else {
          prevDisplayState = null;
        }
      }
  
      function isSmartSearch() {
        const calendarDateSelectorEl = document.querySelector(
          ".calendar-date-selector"
        );
  
        if (!calendarDateSelectorEl) {
          return false;
        }
  
        // Get all children with class 'checkbox-button-group__item'
        const children = calendarDateSelectorEl.querySelectorAll(
          ".checkbox-button-group__item"
        );
  
        // Check if all children have 'display: none' inline style
        const allChildrenHidden = Array.from(children).every((child) => {
          const displayStyle = child.style.display;
          return displayStyle !== "none";
        });
  
        if (!allChildrenHidden) {
          return false;
        }
  
        // All conditions are met
        return true;
      }
  
      function isModalVisible() {
        return (
          calendarDateModalEl &&
          window.getComputedStyle(calendarDateModalEl).display !== "none"
        );
      }
  
      function observeCalendarModal() {
        const observer = new MutationObserver((mutationsList, observer) => {
          mutationsList.forEach((mutation) => {
            trackModalImpression();
          });
        });
  
        // Start observing parentElement for mutations
        const modalParentEl = document.querySelector(".js-edit-search-form");
        if (modalParentEl) {
          observer.observe(modalParentEl, { attributes: true, subtree: true });
        } else {
          // console.error("Parent element not found.");
        }
      }
  
      init();
  
      function init() {
        trackModalImpression();
        observeCalendarModal();
      }
    });
  })();
  