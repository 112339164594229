var FormHTML = `<div data-name="filter-search-results" class="filter-search-results">
<div class="filter-search-results__header">Advanced filters</div>
<div class="filter-search-results__inner">
  <div class="filter-modal-v2__item js-filter-modal-v2-panel">
    <div class="clearfix">
      <h2 class="filter-modal-v2-content__heading">Board basis</h2>
      <div class="filter-modal-v2-options checkbox-button-group checkbox-button-group--list">
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-99" type="checkbox" name="checkbox" class="checkbox-button-group__input" checked />
            <label for="filter-boardbasis-99" class="checkbox-button-group__label" style="display:none">
              <span class="checkbox-button-group__text-highlighted">Any</span>
            </label>
          </div>
        </div>
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-5" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
            <label for="filter-boardbasis-5" class="checkbox-button-group__label">
              <span class="checkbox-button-group__text-highlighted"> All Inclusive </span>
            </label>
          </div>
        </div>
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-1" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
            <label for="filter-boardbasis-1" class="checkbox-button-group__label">
              <span class="checkbox-button-group__text-highlighted"> Bed and Breakfast </span>
            </label>
          </div>
        </div>
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-3" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
            <label for="filter-boardbasis-3" class="checkbox-button-group__label">
              <span class="checkbox-button-group__text-highlighted"> Full Board</span>
            </label>
          </div>
        </div>
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-2" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
            <label for="filter-boardbasis-2" class="checkbox-button-group__label">
              <span class="checkbox-button-group__text-highlighted"> Half Board </span>
            </label>
          </div>
        </div>
        <div class="filter-modal-v2__option-wrapper">
          <div class="filter-modal-v2-option checkbox-button-group__item">
            <input id="filter-boardbasis-4" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
            <label for="filter-boardbasis-4" class="checkbox-button-group__label">
              <span class="checkbox-button-group__text-highlighted"> Self Catering </span>
            </label>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="filter-modal-v2__item js-filter-modal-v2-panel">
  <div class="filter-modal-v2-content clearfix">
    <h2 class="filter-modal-v2-content__heading">Our rating</h2>
    <div class="filter-modal-v2-options checkbox-button-group checkbox-button-group--list">
      <div class="filter-modal-v2__option-wrapper">
        <div class="filter-modal-v2-option checkbox-button-group__item">
          <input id="filter-starrating-99" type="checkbox" name="checkbox" class="checkbox-button-group__input" checked />
          <label for="filter-starrating-99" class="checkbox-button-group__label" style="display:none">
            <span class="checkbox-button-group__text-highlighted">
              <div class="our-ratings">
                <span class="checkbox-button-group__text-highlighted"> Any </span>
              </div>
            </span>
          </label>
        </div>
      </div>
      <div class="filter-modal-v2__option-wrapper">
        <div class="filter-modal-v2-option checkbox-button-group__item">
          <input id="filter-starrating-2" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
          <label for="filter-starrating-2" class="checkbox-button-group__label">
            <span class="checkbox-button-group__text-highlighted">
              <div class="our-ratings">
                <div class="our-ratings__stars">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </span>
          </label>
        </div>
      </div>
      <div class="filter-modal-v2__option-wrapper">
        <div class="filter-modal-v2-option checkbox-button-group__item">
          <input id="filter-starrating-3" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
          <label for="filter-starrating-3" class="checkbox-button-group__label">
            <span class="checkbox-button-group__text-highlighted">
              <div class="our-ratings">
                <div class="our-ratings__stars">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </span>
          </label>
        </div>
      </div>
      <div class="filter-modal-v2__option-wrapper">
        <div class="filter-modal-v2-option checkbox-button-group__item">
          <input id="filter-starrating-4" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
          <label for="filter-starrating-4" class="checkbox-button-group__label">
            <span class="checkbox-button-group__text-highlighted">
              <div class="our-ratings">
                <div class="our-ratings__stars">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </span>
          </label>
        </div>
      </div>
      <div class="filter-modal-v2__option-wrapper">
        <div class="filter-modal-v2-option checkbox-button-group__item">
          <input id="filter-starrating-5" type="checkbox" name="checkbox" class="checkbox-button-group__input" />
          <label for="filter-starrating-5" class="checkbox-button-group__label">
            <span class="checkbox-button-group__text-highlighted">
              <div class="our-ratings">
                <div class="our-ratings__stars">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon">
                      <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.133 11.25l-4.408 2.317.841-4.908L0 5.183l4.929-.717L7.133 0l2.204 4.466 4.929.717-3.567 3.476.842 4.908z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </span>
          </label>
        </div>
      </div>
  </div>
</div>
</div>
<div class="filter-modal-v2__item js-filter-modal-v2-panel">
<div class="filter-modal-v2-content js-dropdown clearfix">
  <h2 class="filter-modal-v2-content__heading">TripAdvisor rating</h2>
  <div class="filter-modal-v2-options checkbox-button-group checkbox-button-group--list">
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-tripadvisor-99" type="radio" name="tripAdvisorRadio" class="checkbox-button-group__input" checked />
        <label for="filter-tripadvisor-99" class="checkbox-button-group__label checkbox-button-group__label--tripadvisor checkbox-button-group__label--tripadvisor-last" style="display:none">
          <span>Any</span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-tripadvisor-2" type="radio" name="tripAdvisorRadio" class="checkbox-button-group__input" />
        <label for="filter-tripadvisor-2" class="checkbox-button-group__label checkbox-button-group__label--tripadvisor">
          <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/2.0-24283-5.svg" alt="TripAdvisor Rating" class="tripadvisor-ratings-filters__img" />
          <div class="tripadvisor-ratings-filters__amount">
            <span>and above</span>
          </div>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-tripadvisor-3" type="radio" name="tripAdvisorRadio" class="checkbox-button-group__input" />
        <label for="filter-tripadvisor-3" class="checkbox-button-group__label checkbox-button-group__label--tripadvisor">
          <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.0-24283-5.svg" alt="TripAdvisor Rating" class="tripadvisor-ratings-filters__img" />
          <div class="tripadvisor-ratings-filters__amount">
            <span>and above</span>
          </div>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-tripadvisor-4" type="radio" name="tripAdvisorRadio" class="checkbox-button-group__input" />
        <label for="filter-tripadvisor-4" class="checkbox-button-group__label checkbox-button-group__label--tripadvisor">
          <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-24283-5.svg" alt="TripAdvisor Rating" class="tripadvisor-ratings-filters__img" />
          <div class="tripadvisor-ratings-filters__amount">
            <span>and above</span>
          </div>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-tripadvisor-5" type="radio" name="tripAdvisorRadio" class="checkbox-button-group__input" />
        <label for="filter-tripadvisor-5" class="checkbox-button-group__label checkbox-button-group__label--tripadvisor checkbox-button-group__label--tripadvisor-last">
          <img src="https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/5.0-24283-5.svg" alt="TripAdvisor Rating" class="tripadvisor-ratings-filters__img" />
        </label>
      </div>
    </div>
</div>
</div>
</div>
<div class="filter-modal-v2__item js-filter-modal-v2-panel">
<div class="filter-modal-v2-content js-dropdown clearfix">
  <h2 class="filter-modal-v2-content__heading">Sort by</h2>
  <div class="filter-modal-v2-options checkbox-button-group checkbox-button-group--list">
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-5" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-5" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> Recommended </span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-1" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-1" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> Price - low to high </span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-2" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-2" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> Price - high to low </span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-3" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-3" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> Our Rating - low to high </span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-4" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-4" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> Our Rating - high to low </span>
        </label>
      </div>
    </div>
    <div class="filter-modal-v2__option-wrapper">
      <div class="filter-modal-v2-option checkbox-button-group__item">
        <input id="filter-sortorder-8" type="radio" name="sortorderRadio" class="checkbox-button-group__input" />
        <label for="filter-sortorder-8" class="checkbox-button-group__label">
          <span class="checkbox-button-group__text-highlighted"> TripAdvisor rating - high to low </span>
        </label>
      </div>
    </div>
</div>
</div>
</div>
</div>
<div class="close-button-wrapper">
    <a class="clear-all">Clear all</a>
    <a class="bttn bttn--outline">Done</a>
</div>
</div>`;

var AdvancedSearchHTML = `<div class="advanced-search__wrapper">
<div class="selected-filters__wrapper"></div>
<a class="text-white advanced-search-button" id="js-advanced-search">
  <span class="underline">Advanced Search</span>
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="basket-summary__included-icon js-basket-icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-arrow-down-bold">
      </use>
    </svg>
  </span>
</a>
</div>`;

var TripAdvisor2 = `<div class='trip-ratings'>
<div class='trip-ratings__stars'>
  <span>
    <svg width="87" height="12" viewBox="0 0 87 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22164 6.54134C6.22164 7.40284 5.493 8.10118 4.59419 8.10118C3.69538 8.10118 2.96675 7.40284 2.96675 6.54134C2.96675 5.67986 3.69538 4.98152 4.59419 4.98152C5.49286 4.98089 6.22186 5.67866 6.22249 6.53997V6.54134H6.22164ZM13.7699 4.98153C12.871 4.98153 12.1424 5.67986 12.1424 6.54134C12.1424 7.40284 12.871 8.10118 13.7699 8.10118C14.6687 8.10118 15.3973 7.40284 15.3973 6.54134C15.3973 5.67986 14.6687 4.98153 13.7699 4.98153ZM13.7699 9.51688C12.0553 9.51688 10.6654 8.18471 10.6654 6.54134C10.6654 4.898 12.0553 3.5658 13.7699 3.5658C15.4844 3.5658 16.8743 4.898 16.8743 6.54134C16.8743 8.18471 15.4844 9.51688 13.7699 9.51688ZM9.18345 6.45556C9.18345 4.49754 7.69668 2.8174 5.73786 2.09831C7.94292 1.21793 10.4235 1.21793 12.6285 2.09831C10.6691 2.81767 9.18345 4.49754 9.18345 6.45556ZM4.59505 9.51688C2.88049 9.51688 1.49056 8.18471 1.49056 6.54134C1.49056 4.898 2.88049 3.5658 4.59505 3.5658C6.30961 3.5658 7.69954 4.898 7.69954 6.54134C7.69954 8.18471 6.30961 9.51688 4.59505 9.51688ZM16.8629 3.29176L18.364 1.72644H15.0362C11.5071 -0.575479 6.86841 -0.575479 3.3393 1.72644H0.00292885L1.504 3.29176C-0.371909 4.92403 -0.512066 7.70481 1.19094 9.50279C2.89396 11.3009 5.79525 11.4351 7.67115 9.80287C7.6851 9.79073 7.69891 9.77853 7.71269 9.76628L9.18345 11.3009L10.6534 9.76817C11.5002 10.5224 12.6138 10.9409 13.7699 10.9394C16.3034 10.9394 18.3591 8.97071 18.3591 6.54244C18.361 5.30514 17.8176 4.1246 16.8629 3.29176Z" fill="white" />
      <mask id="mask0_324_13066" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="36" y="0" width="12" height="12">
        <path d="M36 0H47.2488V11.2487H36V0Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask0_324_13066)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 5.62448C36 2.52314 38.5229 0 41.6243 0C44.726 0 47.2488 2.52314 47.2488 5.62448C47.2488 8.72602 44.726 11.2488 41.6243 11.2488C38.5229 11.2488 36 8.72602 36 5.62448Z" fill="white" />
      </g>
      <mask id="mask1_324_13066" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="0" width="12" height="12">
        <path d="M23.1309 0H34.3796V11.2487H23.1309V0Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask1_324_13066)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1309 5.62448C23.1309 2.52314 25.654 0 28.7553 0C31.8569 0 34.3796 2.52314 34.3796 5.62448C34.3796 8.72602 31.8569 11.2488 28.7553 11.2488C25.654 11.2488 23.1309 8.72602 23.1309 5.62448Z" fill="white" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M84.8509 5.62448C84.8509 3.29441 82.9545 1.39825 80.6247 1.39825C78.2942 1.39825 76.398 3.29441 76.398 5.62448C76.398 7.95475 78.2942 9.83486 80.6247 9.83486C82.9545 9.83486 84.8509 7.95475 84.8509 5.62448ZM75 5.62448C75 2.52314 77.5229 0 80.6247 0C83.726 0 86.249 2.52314 86.249 5.62448C86.249 8.72602 83.726 11.2488 80.6247 11.2488C77.5229 11.2488 75 8.72602 75 5.62448Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M71.8509 5.62448C71.8509 3.29441 69.9545 1.39825 67.6247 1.39825C65.2942 1.39825 63.398 3.29441 63.398 5.62448C63.398 7.95475 65.2942 9.83486 67.6247 9.83486C69.9545 9.83486 71.8509 7.95475 71.8509 5.62448ZM62 5.62448C62 2.52314 64.5229 0 67.6247 0C70.726 0 73.249 2.52314 73.249 5.62448C73.249 8.72602 70.726 11.2488 67.6247 11.2488C64.5229 11.2488 62 8.72602 62 5.62448Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M58.8509 5.62448C58.8509 3.29441 56.9545 1.39825 54.6247 1.39825C52.2942 1.39825 50.398 3.29441 50.398 5.62448C50.398 7.95475 52.2942 9.83486 54.6247 9.83486C56.9545 9.83486 58.8509 7.95475 58.8509 5.62448ZM49 5.62448C49 2.52314 51.5229 0 54.6247 0C57.726 0 60.249 2.52314 60.249 5.62448C60.249 8.72602 57.726 11.2488 54.6247 11.2488C51.5229 11.2488 49 8.72602 49 5.62448Z" fill="white" />
    </svg>
  </span>
</div>
</div>`;

var TripAdvisor3 = `<div class='trip-ratings'>
<div class='trip-ratings__stars'>
  <span>
    <svg width="87" height="12" viewBox="0 0 87 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22164 6.54134C6.22164 7.40284 5.493 8.10118 4.59419 8.10118C3.69538 8.10118 2.96675 7.40284 2.96675 6.54134C2.96675 5.67986 3.69538 4.98152 4.59419 4.98152C5.49286 4.98089 6.22186 5.67866 6.22249 6.53997V6.54134H6.22164ZM13.7699 4.98153C12.871 4.98153 12.1424 5.67986 12.1424 6.54134C12.1424 7.40284 12.871 8.10118 13.7699 8.10118C14.6687 8.10118 15.3973 7.40284 15.3973 6.54134C15.3973 5.67986 14.6687 4.98153 13.7699 4.98153ZM13.7699 9.51688C12.0553 9.51688 10.6654 8.18471 10.6654 6.54134C10.6654 4.898 12.0553 3.5658 13.7699 3.5658C15.4844 3.5658 16.8743 4.898 16.8743 6.54134C16.8743 8.18471 15.4844 9.51688 13.7699 9.51688ZM9.18345 6.45556C9.18345 4.49754 7.69668 2.8174 5.73786 2.09831C7.94292 1.21793 10.4235 1.21793 12.6285 2.09831C10.6691 2.81767 9.18345 4.49754 9.18345 6.45556ZM4.59505 9.51688C2.88049 9.51688 1.49056 8.18471 1.49056 6.54134C1.49056 4.898 2.88049 3.5658 4.59505 3.5658C6.30961 3.5658 7.69954 4.898 7.69954 6.54134C7.69954 8.18471 6.30961 9.51688 4.59505 9.51688ZM16.8629 3.29176L18.364 1.72644H15.0362C11.5071 -0.575479 6.86841 -0.575479 3.3393 1.72644H0.00292885L1.504 3.29176C-0.371909 4.92403 -0.512066 7.70481 1.19094 9.50279C2.89396 11.3009 5.79525 11.4351 7.67115 9.80287C7.6851 9.79073 7.69891 9.77853 7.71269 9.76628L9.18345 11.3009L10.6534 9.76817C11.5002 10.5224 12.6138 10.9409 13.7699 10.9394C16.3034 10.9394 18.3591 8.97071 18.3591 6.54244C18.361 5.30514 17.8176 4.1246 16.8629 3.29176Z" fill="white" />
      <mask id="mask0_324_13067" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="49" y="0" width="12" height="12">
        <path d="M49 0.206055H60.2488V11.4547H49V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask0_324_13067)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M49 5.83053C49 2.7292 51.5229 0.206055 54.6245 0.206055C57.726 0.206055 60.2488 2.7292 60.2488 5.83053C60.2488 8.93208 57.726 11.4548 54.6245 11.4548C51.5229 11.4548 49 8.93208 49 5.83053Z" fill="white" />
      </g>
      <mask id="mask1_324_13067" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="36" y="0" width="12" height="12">
        <path d="M36 0.206055H47.2488V11.4547H36V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask1_324_13067)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 5.83053C36 2.7292 38.5229 0.206055 41.6243 0.206055C44.726 0.206055 47.2488 2.7292 47.2488 5.83053C47.2488 8.93208 44.726 11.4548 41.6243 11.4548C38.5229 11.4548 36 8.93208 36 5.83053Z" fill="white" />
      </g>
      <mask id="mask2_324_13067" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="0" width="12" height="12">
        <path d="M23.1309 0.206055H34.3796V11.4547H23.1309V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask2_324_13067)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1309 5.83053C23.1309 2.7292 25.654 0.206055 28.7553 0.206055C31.8569 0.206055 34.3796 2.7292 34.3796 5.83053C34.3796 8.93208 31.8569 11.4548 28.7553 11.4548C25.654 11.4548 23.1309 8.93208 23.1309 5.83053Z" fill="white" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M84.8509 5.83053C84.8509 3.50047 82.9545 1.6043 80.6247 1.6043C78.2942 1.6043 76.398 3.50047 76.398 5.83053C76.398 8.16081 78.2942 10.0409 80.6247 10.0409C82.9545 10.0409 84.8509 8.16081 84.8509 5.83053ZM75 5.83053C75 2.7292 77.5229 0.206055 80.6247 0.206055C83.726 0.206055 86.249 2.7292 86.249 5.83053C86.249 8.93208 83.726 11.4548 80.6247 11.4548C77.5229 11.4548 75 8.93208 75 5.83053Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M71.8509 5.83053C71.8509 3.50047 69.9545 1.6043 67.6247 1.6043C65.2942 1.6043 63.398 3.50047 63.398 5.83053C63.398 8.16081 65.2942 10.0409 67.6247 10.0409C69.9545 10.0409 71.8509 8.16081 71.8509 5.83053ZM62 5.83053C62 2.7292 64.5229 0.206055 67.6247 0.206055C70.726 0.206055 73.249 2.7292 73.249 5.83053C73.249 8.93208 70.726 11.4548 67.6247 11.4548C64.5229 11.4548 62 8.93208 62 5.83053Z" fill="white" />
    </svg>
  </span>
</div>
</div>`;

var TripAdvisor4 = `<div class='trip-ratings'>
<div class='trip-ratings__stars'>
  <span>
    <svg width="87" height="12" viewBox="0 0 87 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22164 6.54134C6.22164 7.40284 5.493 8.10118 4.59419 8.10118C3.69538 8.10118 2.96675 7.40284 2.96675 6.54134C2.96675 5.67986 3.69538 4.98152 4.59419 4.98152C5.49286 4.98089 6.22186 5.67866 6.22249 6.53997V6.54134H6.22164ZM13.7699 4.98153C12.871 4.98153 12.1424 5.67986 12.1424 6.54134C12.1424 7.40284 12.871 8.10118 13.7699 8.10118C14.6687 8.10118 15.3973 7.40284 15.3973 6.54134C15.3973 5.67986 14.6687 4.98153 13.7699 4.98153ZM13.7699 9.51688C12.0553 9.51688 10.6654 8.18471 10.6654 6.54134C10.6654 4.898 12.0553 3.5658 13.7699 3.5658C15.4844 3.5658 16.8743 4.898 16.8743 6.54134C16.8743 8.18471 15.4844 9.51688 13.7699 9.51688ZM9.18345 6.45556C9.18345 4.49754 7.69668 2.8174 5.73786 2.09831C7.94292 1.21793 10.4235 1.21793 12.6285 2.09831C10.6691 2.81767 9.18345 4.49754 9.18345 6.45556ZM4.59505 9.51688C2.88049 9.51688 1.49056 8.18471 1.49056 6.54134C1.49056 4.898 2.88049 3.5658 4.59505 3.5658C6.30961 3.5658 7.69954 4.898 7.69954 6.54134C7.69954 8.18471 6.30961 9.51688 4.59505 9.51688ZM16.8629 3.29176L18.364 1.72644H15.0362C11.5071 -0.575479 6.86841 -0.575479 3.3393 1.72644H0.00292885L1.504 3.29176C-0.371909 4.92403 -0.512066 7.70481 1.19094 9.50279C2.89396 11.3009 5.79525 11.4351 7.67115 9.80287C7.6851 9.79073 7.69891 9.77853 7.71269 9.76628L9.18345 11.3009L10.6534 9.76817C11.5002 10.5224 12.6138 10.9409 13.7699 10.9394C16.3034 10.9394 18.3591 8.97071 18.3591 6.54244C18.361 5.30514 17.8176 4.1246 16.8629 3.29176Z" fill="white" />
      <mask id="mask0_324_13068" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="49" y="0" width="12" height="12">
        <path d="M49 0.206055H60.2488V11.4547H49V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask0_324_13068)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M49 5.83053C49 2.7292 51.5229 0.206055 54.6245 0.206055C57.726 0.206055 60.2488 2.7292 60.2488 5.83053C60.2488 8.93208 57.726 11.4548 54.6245 11.4548C51.5229 11.4548 49 8.93208 49 5.83053Z" fill="white" />
      </g>
      <mask id="mask1_324_13068" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="62" y="0" width="12" height="12">
        <path d="M62 0H73.2488V11.2487H62V0Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask1_324_13068)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62 5.62448C62 2.52314 64.5229 0 67.6245 0C70.726 0 73.2488 2.52314 73.2488 5.62448C73.2488 8.72602 70.726 11.2488 67.6245 11.2488C64.5229 11.2488 62 8.72602 62 5.62448Z" fill="white" />
      </g>
      <mask id="mask2_324_13068" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="36" y="0" width="12" height="12">
        <path d="M36 0.206055H47.2488V11.4547H36V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask2_324_13068)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 5.83053C36 2.7292 38.5229 0.206055 41.6243 0.206055C44.726 0.206055 47.2488 2.7292 47.2488 5.83053C47.2488 8.93208 44.726 11.4548 41.6243 11.4548C38.5229 11.4548 36 8.93208 36 5.83053Z" fill="white" />
      </g>
      <mask id="mask3_324_13068" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="0" width="12" height="12">
        <path d="M23.1309 0.206055H34.3796V11.4547H23.1309V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask3_324_13068)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1309 5.83053C23.1309 2.7292 25.654 0.206055 28.7553 0.206055C31.8569 0.206055 34.3796 2.7292 34.3796 5.83053C34.3796 8.93208 31.8569 11.4548 28.7553 11.4548C25.654 11.4548 23.1309 8.93208 23.1309 5.83053Z" fill="white" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M84.8509 5.83053C84.8509 3.50047 82.9545 1.6043 80.6247 1.6043C78.2942 1.6043 76.398 3.50047 76.398 5.83053C76.398 8.16081 78.2942 10.0409 80.6247 10.0409C82.9545 10.0409 84.8509 8.16081 84.8509 5.83053ZM75 5.83053C75 2.7292 77.5229 0.206055 80.6247 0.206055C83.726 0.206055 86.249 2.7292 86.249 5.83053C86.249 8.93208 83.726 11.4548 80.6247 11.4548C77.5229 11.4548 75 8.93208 75 5.83053Z" fill="white" />
    </svg>
  </span>
</div>
</div>`;

var TripAdvisor5 = `<div class='trip-ratings'>
<div class='trip-ratings__stars'>
  <span>
    <svg width="87" height="12" viewBox="0 0 87 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_324_13069" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="49" y="0" width="12" height="12">
        <path d="M49 0.206055H60.2488V11.4547H49V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask0_324_13069)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M49 5.83053C49 2.7292 51.5229 0.206055 54.6245 0.206055C57.726 0.206055 60.2488 2.7292 60.2488 5.83053C60.2488 8.93208 57.726 11.4548 54.6245 11.4548C51.5229 11.4548 49 8.93208 49 5.83053Z" fill="white" />
      </g>
      <mask id="mask1_324_13069" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="62" y="0" width="12" height="12">
        <path d="M62 0H73.2488V11.2487H62V0Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask1_324_13069)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62 5.62448C62 2.52314 64.5229 0 67.6245 0C70.726 0 73.2488 2.52314 73.2488 5.62448C73.2488 8.72602 70.726 11.2488 67.6245 11.2488C64.5229 11.2488 62 8.72602 62 5.62448Z" fill="white" />
      </g>
      <mask id="mask2_324_13069" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="75" y="0" width="12" height="12">
        <path d="M75 0H86.2488V11.2487H75V0Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask2_324_13069)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M75 5.62448C75 2.52314 77.5229 0 80.6245 0C83.726 0 86.2488 2.52314 86.2488 5.62448C86.2488 8.72602 83.726 11.2488 80.6245 11.2488C77.5229 11.2488 75 8.72602 75 5.62448Z" fill="white" />
      </g>
      <mask id="mask3_324_13069" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="36" y="0" width="12" height="12">
        <path d="M36 0.206055H47.2488V11.4547H36V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask3_324_13069)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 5.83053C36 2.7292 38.5229 0.206055 41.6243 0.206055C44.726 0.206055 47.2488 2.7292 47.2488 5.83053C47.2488 8.93208 44.726 11.4548 41.6243 11.4548C38.5229 11.4548 36 8.93208 36 5.83053Z" fill="white" />
      </g>
      <mask id="mask4_324_13069" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="23" y="0" width="12" height="12">
        <path d="M23.1309 0.206055H34.3796V11.4547H23.1309V0.206055Z" fill="#00AA6C" />
      </mask>
      <g mask="url(#mask4_324_13069)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1309 5.83053C23.1309 2.7292 25.654 0.206055 28.7553 0.206055C31.8569 0.206055 34.3796 2.7292 34.3796 5.83053C34.3796 8.93208 31.8569 11.4548 28.7553 11.4548C25.654 11.4548 23.1309 8.93208 23.1309 5.83053Z" fill="white" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22164 6.54134C6.22164 7.40284 5.493 8.10118 4.59419 8.10118C3.69538 8.10118 2.96675 7.40284 2.96675 6.54134C2.96675 5.67986 3.69538 4.98152 4.59419 4.98152C5.49286 4.98089 6.22186 5.67866 6.22249 6.53997V6.54134H6.22164ZM13.7699 4.98153C12.871 4.98153 12.1424 5.67986 12.1424 6.54134C12.1424 7.40284 12.871 8.10118 13.7699 8.10118C14.6687 8.10118 15.3973 7.40284 15.3973 6.54134C15.3973 5.67986 14.6687 4.98153 13.7699 4.98153ZM13.7699 9.51688C12.0553 9.51688 10.6654 8.18471 10.6654 6.54134C10.6654 4.898 12.0553 3.5658 13.7699 3.5658C15.4844 3.5658 16.8743 4.898 16.8743 6.54134C16.8743 8.18471 15.4844 9.51688 13.7699 9.51688ZM9.18345 6.45556C9.18345 4.49754 7.69668 2.8174 5.73786 2.09831C7.94292 1.21793 10.4235 1.21793 12.6285 2.09831C10.6691 2.81767 9.18345 4.49754 9.18345 6.45556ZM4.59505 9.51688C2.88049 9.51688 1.49056 8.18471 1.49056 6.54134C1.49056 4.898 2.88049 3.5658 4.59505 3.5658C6.30961 3.5658 7.69954 4.898 7.69954 6.54134C7.69954 8.18471 6.30961 9.51688 4.59505 9.51688ZM16.8629 3.29176L18.364 1.72644H15.0362C11.5071 -0.575479 6.86841 -0.575479 3.3393 1.72644H0.00292885L1.504 3.29176C-0.371909 4.92403 -0.512066 7.70481 1.19094 9.50279C2.89396 11.3009 5.79525 11.4351 7.67115 9.80287C7.6851 9.79073 7.69891 9.77853 7.71269 9.76628L9.18345 11.3009L10.6534 9.76817C11.5002 10.5224 12.6138 10.9409 13.7699 10.9394C16.3034 10.9394 18.3591 8.97071 18.3591 6.54244C18.361 5.30514 17.8176 4.1246 16.8629 3.29176Z" fill="white" />
    </svg>
  </span>
</div>
</div>`;

var moreFiltersList = []; //array holds all of the selected filters that are hidden

function polyfill() {
    // aliases
    var w = window;
    var d = document;
  
    // return if scroll behavior is supported and polyfill is not forced
    if (
      'scrollBehavior' in d.documentElement.style &&
      w.__forceSmoothScrollPolyfill__ !== true
    ) {
      return;
    }
  
    // globals
    var Element = w.HTMLElement || w.Element;
    var SCROLL_TIME = 468;
  
    // object gathering original scroll methods
    var original = {
      scroll: w.scroll || w.scrollTo,
      scrollBy: w.scrollBy,
      elementScroll: Element.prototype.scroll || scrollElement,
      scrollIntoView: Element.prototype.scrollIntoView,
    };
  
    // define timing method
    var now =
      w.performance && w.performance.now
        ? w.performance.now.bind(w.performance)
        : Date.now;
  
    /**
     * indicates if a the current browser is made by Microsoft
     * @method isMicrosoftBrowser
     * @param {String} userAgent
     * @returns {Boolean}
     */
    function isMicrosoftBrowser(userAgent) {
      var userAgentPatterns = ['MSIE ', 'Trident/', 'Edge/'];
  
      return new RegExp(userAgentPatterns.join('|')).test(userAgent);
    }
  
    /*
     * IE has rounding bug rounding down clientHeight and clientWidth and
     * rounding up scrollHeight and scrollWidth causing false positives
     * on hasScrollableSpace
     */
    var ROUNDING_TOLERANCE = isMicrosoftBrowser(w.navigator.userAgent) ? 1 : 0;
  
    /**
     * changes scroll position inside an element
     * @method scrollElement
     * @param {Number} x
     * @param {Number} y
     * @returns {undefined}
     */
    function scrollElement(x, y) {
      this.scrollLeft = x;
      this.scrollTop = y;
    }
  
    /**
     * returns result of applying ease math function to a number
     * @method ease
     * @param {Number} k
     * @returns {Number}
     */
    function ease(k) {
      return 0.5 * (1 - Math.cos(Math.PI * k));
    }
  
    /**
     * indicates if a smooth behavior should be applied
     * @method shouldBailOut
     * @param {Number|Object} firstArg
     * @returns {Boolean}
     */
    function shouldBailOut(firstArg) {
      if (
        firstArg === null ||
        typeof firstArg !== 'object' ||
        firstArg.behavior === undefined ||
        firstArg.behavior === 'auto' ||
        firstArg.behavior === 'instant'
      ) {
        // first argument is not an object/null
        // or behavior is auto, instant or undefined
        return true;
      }
  
      if (typeof firstArg === 'object' && firstArg.behavior === 'smooth') {
        // first argument is an object and behavior is smooth
        return false;
      }
  
      // throw error when behavior is not supported
      throw new TypeError(
        'behavior member of ScrollOptions ' +
          firstArg.behavior +
          ' is not a valid value for enumeration ScrollBehavior.'
      );
    }
  
    /**
     * indicates if an element has scrollable space in the provided axis
     * @method hasScrollableSpace
     * @param {Node} el
     * @param {String} axis
     * @returns {Boolean}
     */
    function hasScrollableSpace(el, axis) {
      if (axis === 'Y') {
        return el.clientHeight + ROUNDING_TOLERANCE < el.scrollHeight;
      }
  
      if (axis === 'X') {
        return el.clientWidth + ROUNDING_TOLERANCE < el.scrollWidth;
      }
    }
  
    /**
     * indicates if an element has a scrollable overflow property in the axis
     * @method canOverflow
     * @param {Node} el
     * @param {String} axis
     * @returns {Boolean}
     */
    function canOverflow(el, axis) {
      var overflowValue = w.getComputedStyle(el, null)['overflow' + axis];
  
      return overflowValue === 'auto' || overflowValue === 'scroll';
    }
  
    /**
     * indicates if an element can be scrolled in either axis
     * @method isScrollable
     * @param {Node} el
     * @param {String} axis
     * @returns {Boolean}
     */
    function isScrollable(el) {
      var isScrollableY = hasScrollableSpace(el, 'Y') && canOverflow(el, 'Y');
      var isScrollableX = hasScrollableSpace(el, 'X') && canOverflow(el, 'X');
  
      return isScrollableY || isScrollableX;
    }
  
    /**
     * finds scrollable parent of an element
     * @method findScrollableParent
     * @param {Node} el
     * @returns {Node} el
     */
    function findScrollableParent(el) {
      while (el !== d.body && isScrollable(el) === false) {
        el = el.parentNode || el.host;
      }
  
      return el;
    }
  
    /**
     * self invoked function that, given a context, steps through scrolling
     * @method step
     * @param {Object} context
     * @returns {undefined}
     */
    function step(context) {
      var time = now();
      var value;
      var currentX;
      var currentY;
      var elapsed = (time - context.startTime) / SCROLL_TIME;
  
      // avoid elapsed times higher than one
      elapsed = elapsed > 1 ? 1 : elapsed;
  
      // apply easing to elapsed time
      value = ease(elapsed);
  
      currentX = context.startX + (context.x - context.startX) * value;
      currentY = context.startY + (context.y - context.startY) * value;
  
      context.method.call(context.scrollable, currentX, currentY);
  
      // scroll more if we have not reached our destination
      if (currentX !== context.x || currentY !== context.y) {
        w.requestAnimationFrame(step.bind(w, context));
      }
    }
  
    /**
     * scrolls window or element with a smooth behavior
     * @method smoothScroll
     * @param {Object|Node} el
     * @param {Number} x
     * @param {Number} y
     * @returns {undefined}
     */
    function smoothScroll(el, x, y) {
      var scrollable;
      var startX;
      var startY;
      var method;
      var startTime = now();
  
      // define scroll context
      if (el === d.body) {
        scrollable = w;
        startX = w.scrollX || w.pageXOffset;
        startY = w.scrollY || w.pageYOffset;
        method = original.scroll;
      } else {
        scrollable = el;
        startX = el.scrollLeft;
        startY = el.scrollTop;
        method = scrollElement;
      }
  
      // scroll looping over a frame
      step({
        scrollable: scrollable,
        method: method,
        startTime: startTime,
        startX: startX,
        startY: startY,
        x: x,
        y: y,
      });
    }
  
    // ORIGINAL METHODS OVERRIDES
    // w.scroll and w.scrollTo
    w.scroll = w.scrollTo = function () {
      // avoid action when no arguments are passed
      if (arguments[0] === undefined) {
        return;
      }
  
      // avoid smooth behavior if not required
      if (shouldBailOut(arguments[0]) === true) {
        original.scroll.call(
          w,
          arguments[0].left !== undefined
            ? arguments[0].left
            : typeof arguments[0] !== 'object'
            ? arguments[0]
            : w.scrollX || w.pageXOffset,
          // use top prop, second argument if present or fallback to scrollY
          arguments[0].top !== undefined
            ? arguments[0].top
            : arguments[1] !== undefined
            ? arguments[1]
            : w.scrollY || w.pageYOffset
        );
  
        return;
      }
  
      // LET THE SMOOTHNESS BEGIN!
      smoothScroll.call(
        w,
        d.body,
        arguments[0].left !== undefined
          ? ~~arguments[0].left
          : w.scrollX || w.pageXOffset,
        arguments[0].top !== undefined
          ? ~~arguments[0].top
          : w.scrollY || w.pageYOffset
      );
    };
  
    // w.scrollBy
    w.scrollBy = function () {
      // avoid action when no arguments are passed
      if (arguments[0] === undefined) {
        return;
      }
  
      // avoid smooth behavior if not required
      if (shouldBailOut(arguments[0])) {
        original.scrollBy.call(
          w,
          arguments[0].left !== undefined
            ? arguments[0].left
            : typeof arguments[0] !== 'object'
            ? arguments[0]
            : 0,
          arguments[0].top !== undefined
            ? arguments[0].top
            : arguments[1] !== undefined
            ? arguments[1]
            : 0
        );
  
        return;
      }
  
      // LET THE SMOOTHNESS BEGIN!
      smoothScroll.call(
        w,
        d.body,
        ~~arguments[0].left + (w.scrollX || w.pageXOffset),
        ~~arguments[0].top + (w.scrollY || w.pageYOffset)
      );
    };
  
    // Element.prototype.scroll and Element.prototype.scrollTo
    Element.prototype.scroll = Element.prototype.scrollTo = function () {
      // avoid action when no arguments are passed
      if (arguments[0] === undefined) {
        return;
      }
  
      // avoid smooth behavior if not required
      if (shouldBailOut(arguments[0]) === true) {
        // if one number is passed, throw error to match Firefox implementation
        if (typeof arguments[0] === 'number' && arguments[1] === undefined) {
          throw new SyntaxError('Value could not be converted');
        }
  
        original.elementScroll.call(
          this,
          // use left prop, first number argument or fallback to scrollLeft
          arguments[0].left !== undefined
            ? ~~arguments[0].left
            : typeof arguments[0] !== 'object'
            ? ~~arguments[0]
            : this.scrollLeft,
          // use top prop, second argument or fallback to scrollTop
          arguments[0].top !== undefined
            ? ~~arguments[0].top
            : arguments[1] !== undefined
            ? ~~arguments[1]
            : this.scrollTop
        );
  
        return;
      }
  
      var left = arguments[0].left;
      var top = arguments[0].top;
  
      // LET THE SMOOTHNESS BEGIN!
      smoothScroll.call(
        this,
        this,
        typeof left === 'undefined' ? this.scrollLeft : ~~left,
        typeof top === 'undefined' ? this.scrollTop : ~~top
      );
    };
  
    // Element.prototype.scrollBy
    Element.prototype.scrollBy = function () {
      // avoid action when no arguments are passed
      if (arguments[0] === undefined) {
        return;
      }
  
      // avoid smooth behavior if not required
      if (shouldBailOut(arguments[0]) === true) {
        original.elementScroll.call(
          this,
          arguments[0].left !== undefined
            ? ~~arguments[0].left + this.scrollLeft
            : ~~arguments[0] + this.scrollLeft,
          arguments[0].top !== undefined
            ? ~~arguments[0].top + this.scrollTop
            : ~~arguments[1] + this.scrollTop
        );
  
        return;
      }
  
      this.scroll({
        left: ~~arguments[0].left + this.scrollLeft,
        top: ~~arguments[0].top + this.scrollTop,
        behavior: arguments[0].behavior,
      });
    };
  
    // Element.prototype.scrollIntoView
    Element.prototype.scrollIntoView = function () {
      // avoid smooth behavior if not required
      if (shouldBailOut(arguments[0]) === true) {
        original.scrollIntoView.call(
          this,
          arguments[0] === undefined ? true : arguments[0]
        );
  
        return;
      }
  
      // LET THE SMOOTHNESS BEGIN!
      var scrollableParent = findScrollableParent(this);
      var parentRects = scrollableParent.getBoundingClientRect();
      var clientRects = this.getBoundingClientRect();
  
      if (scrollableParent !== d.body) {
        // reveal element inside parent
        smoothScroll.call(
          this,
          scrollableParent,
          scrollableParent.scrollLeft + clientRects.left - parentRects.left,
          scrollableParent.scrollTop + clientRects.top - parentRects.top
        );
  
        // reveal parent in viewport unless is fixed
        if (w.getComputedStyle(scrollableParent).position !== 'fixed') {
          w.scrollBy({
            left: parentRects.left,
            top: parentRects.top,
            behavior: 'smooth',
          });
        }
      } else {
        // reveal element in viewport
        w.scrollBy({
          left: clientRects.left,
          top: clientRects.top,
          behavior: 'smooth',
        });
      }
    };
  }
  
  if (typeof exports === 'object' && typeof module !== 'undefined') {
    // commonjs
    module.exports = { polyfill: polyfill };
  } else {
    // global
    polyfill();
  }
  const utils = window.optlyUtils;
  window.optimizely = window.optimizely || [];

function swapTripAdvisorImg(numStars) {

  //swap green svg from site with it's corresponding white svg 
  var tripadvisorSvgs = [TripAdvisor2, TripAdvisor3, TripAdvisor4, TripAdvisor5];

  for (var i = 1; i <= tripadvisorSvgs.length; i++) {
    if (i + 1 == numStars) {
      return tripadvisorSvgs[i - 1];
    }
  }
}

function loadFilters() {

  //load all filter pills on page load
  var selection = JSON.parse(window.sessionStorage.getItem('ADVANCED_SEARCH'));
  checkRecommendedFilter();
  var itemsToCheck = [];
  moreFiltersList = []; //set the global list to empty to keep track of which items are within the +n filters

  if (selection) {
    Object.keys(selection).map(function (key) {
      selection[key].map(function (number, index) {
        var itemId = "filter-" + key + "-" + number;
        itemsToCheck.push(itemId); //add all ids of the stored selected filters in a list.
      });
    });

    for (var y = 0; y < itemsToCheck.length; y++) {
      //loop to check the checkboxes for the stored selected filters
      var elementToCheck = document.getElementById(itemsToCheck[y]);
      elementToCheck.checked = true;
    }

    createFilterPills();
  }
}

function reorderCheckedBoxes(checkedBoxes) {

  var tds = checkedBoxes,
      result = [];

  for (var i = 0; i < tds.length; i++) {
    if (tds[i].id.includes("filter-tripadvisor")) {
      result.push(tds[i]); //tds[i].remove();
    }
  }

  for (i = 0; i < tds.length; i++) {
    if (!result.includes(tds[i])) {
      result.push(tds[i]);
    }
  }

  return result;
}

function createFilterPills() {

  //create the filter bubbles in the search bar for the selected filters
  var advancedSearchPillHTML = "";
  var selectedFiltersCount = 0;
  var selectedFiltersTotal = 0;
  var filterTextArray = [];
  var checkedBoxes = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="filter-"]:checked'); //get all checked filters

  checkedBoxes = reorderCheckedBoxes(checkedBoxes);
  document.querySelectorAll('.selected-filters__wrapper div').forEach(function (e) {
    return e.remove();
  }); //remove anything in the selected filters wrapper to avoid duplicted pills

  for (var i = 0; i < checkedBoxes.length; i++) {
    if (!checkedBoxes[i].id.includes("-99")) {
      //ignore 'Any' checkboxes
      if (checkedBoxes[i].id.includes("filter-tripadvisor")) {
        var numStars = parseInt(checkedBoxes[i].id.replace('filter-tripadvisor-', '')); //the number in each tripadvisor id correleates to the number of stars in the image.

        var tripadvisorSvg = swapTripAdvisorImg(numStars); //get the correct svg for the number of tripadvisor stars.

        filterTextArray.push([tripadvisorSvg, checkedBoxes[i].id]); //push the svg and id into the array

        if (selectedFiltersCount < 3) {
          selectedFiltersCount += 1; //create a filter pill element for each item with the filter id as a class and the filter label as the text. Concatenate the elements together.
          //advancedSearchPillHTML += "<div class='selected-filters__pill "+checkedBoxes[i].id+"'>"+tripadvisorSvg+"<span class='svg-wrapper'><svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.3241 4.49726L8.83118 0.990214C9.05627 0.765124 9.05627 0.394815 8.83118 0.169725C8.60606 -0.0553648 8.23575 -0.0553648 8.01066 0.169725L4.50361 3.67677L0.996567 0.166095C0.767847 -0.0553648 0.401168 -0.0553648 0.172484 0.166095C-0.052642 0.391184 -0.052642 0.761493 0.172484 0.986583L3.67949 4.49363L0.168817 8.0043C-0.0562725 8.22939 -0.0562725 8.5997 0.168817 8.82479C0.281399 8.93734 0.430212 8.99539 0.579062 8.99539C0.727912 8.99539 0.876761 8.93734 0.989343 8.82479L4.49635 5.31775L8.00343 8.82479C8.11594 8.93734 8.26479 8.99539 8.41368 8.99539C8.56249 8.99539 8.71138 8.93734 8.82389 8.82479C9.04901 8.5997 9.04901 8.22939 8.82389 8.0043L5.3241 4.49726Z' fill='white'/></svg></div></div>";

          advancedSearchPillHTML = "<div class='selected-filters__pill " + checkedBoxes[i].id + "'>" + tripadvisorSvg + "<span class='svg-wrapper'><svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.3241 4.49726L8.83118 0.990214C9.05627 0.765124 9.05627 0.394815 8.83118 0.169725C8.60606 -0.0553648 8.23575 -0.0553648 8.01066 0.169725L4.50361 3.67677L0.996567 0.166095C0.767847 -0.0553648 0.401168 -0.0553648 0.172484 0.166095C-0.052642 0.391184 -0.052642 0.761493 0.172484 0.986583L3.67949 4.49363L0.168817 8.0043C-0.0562725 8.22939 -0.0562725 8.5997 0.168817 8.82479C0.281399 8.93734 0.430212 8.99539 0.579062 8.99539C0.727912 8.99539 0.876761 8.93734 0.989343 8.82479L4.49635 5.31775L8.00343 8.82479C8.11594 8.93734 8.26479 8.99539 8.41368 8.99539C8.56249 8.99539 8.71138 8.93734 8.82389 8.82479C9.04901 8.5997 9.04901 8.22939 8.82389 8.0043L5.3241 4.49726Z' fill='white'/></svg></div></div>" + advancedSearchPillHTML;
        } else {
          var found = false;

          for (var k = 0; k < moreFiltersList.length; k++) {
            if (moreFiltersList[k][0] == tripadvisorSvg && moreFiltersList[k][1] == checkedBoxes[i].id) {
              //check if the filter is already in the list.
              found = true;
            }
          }

          if (found == false) {
            //for the remaining filters that wont be put into pills, store their ids and labels in an array.
            moreFiltersList.push([tripadvisorSvg, checkedBoxes[i].id]);
          }
        }
      } else {
        if (selectedFiltersCount < 3) {
          selectedFiltersCount += 1;

          if (!checkedBoxes[i].id.includes('filter-sortorder')) {
            //if the item is a sort by filter, dont create a filter pill for it
            advancedSearchPillHTML += "<div class='selected-filters__pill " + checkedBoxes[i].id + "'>" + checkedBoxes[i].parentElement.querySelector('span').innerHTML + "<span class='svg-wrapper'><svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.3241 4.49726L8.83118 0.990214C9.05627 0.765124 9.05627 0.394815 8.83118 0.169725C8.60606 -0.0553648 8.23575 -0.0553648 8.01066 0.169725L4.50361 3.67677L0.996567 0.166095C0.767847 -0.0553648 0.401168 -0.0553648 0.172484 0.166095C-0.052642 0.391184 -0.052642 0.761493 0.172484 0.986583L3.67949 4.49363L0.168817 8.0043C-0.0562725 8.22939 -0.0562725 8.5997 0.168817 8.82479C0.281399 8.93734 0.430212 8.99539 0.579062 8.99539C0.727912 8.99539 0.876761 8.93734 0.989343 8.82479L4.49635 5.31775L8.00343 8.82479C8.11594 8.93734 8.26479 8.99539 8.41368 8.99539C8.56249 8.99539 8.71138 8.93734 8.82389 8.82479C9.04901 8.5997 9.04901 8.22939 8.82389 8.0043L5.3241 4.49726Z' fill='white'/></svg></div></div>";
          }
        } else {
          var _found = false;

          for (var _k = 0; _k < moreFiltersList.length; _k++) {
            if (moreFiltersList[_k][0] == checkedBoxes[i].parentElement.querySelector('span').innerHTML && moreFiltersList[_k][1] == checkedBoxes[i].id) {
              _found = true;
            }
          }

          if (_found == false && !checkedBoxes[i].id.includes("filter-sortorder")) {
            moreFiltersList.push([checkedBoxes[i].parentElement.querySelector('span').innerHTML, checkedBoxes[i].id]);
          }
        }
      }
    }
  }

  var numCheckedFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="filter-"]:checked').length;
  var numCheckedAnyFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="-99"]:checked').length;
  var numCheckedSortByFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="filter-sortorder"]:checked').length;
  selectedFiltersTotal = numCheckedFilters - numCheckedAnyFilters - numCheckedSortByFilters; //remove the 'any' filters and the sort by filters from the total checked filters count

  if (selectedFiltersTotal - selectedFiltersCount > 0) {
    var remainingFilters = selectedFiltersTotal - selectedFiltersCount; //calculate the number of hidden filters that aren't in pills

    if (remainingFilters > 1) {
      advancedSearchPillHTML += "<div class='more-filters' data-value='" + remainingFilters + "'>+" + remainingFilters + " more filters</div>"; //create an element to store the +x more filters text
    } else if (remainingFilters == 1) {
      advancedSearchPillHTML += "<div class='more-filters' data-value='" + remainingFilters + "'>+" + remainingFilters + " more filter</div>";
    }
  }

  document.querySelector('.selected-filters__wrapper').insertAdjacentHTML('afterbegin', advancedSearchPillHTML); //insert the +n filters text into the search bar after the pills.

  var selectedFilters = document.querySelectorAll(".selected-filters__pill");
  selectedFilters.forEach(function (pill) {
    pill.addEventListener('click', uncheckFilter); //add an event listener to each filter pill so that they can be removed by the user
  });
}

function checkRecommendedFilter() {
  
  //checks the recommended filter if no other sorting filter is selected
  storeSelection();
  var selection = JSON.parse(window.sessionStorage.getItem('ADVANCED_SEARCH'));

  if (selection.sortorder.length == 0) {
    document.querySelector("#filter-sortorder-5").checked = true;
    storeSelection(); // update the session storage.
  }
}

function hideAndShowClearAll() {

  var numCheckedFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="filter-"]:checked').length;
  var numCheckedAnyFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="-99"]:checked').length;
  var numCheckedSortByFilters = document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="filter-sortorder"]:checked').length;

  if (numCheckedFilters - numCheckedAnyFilters - numCheckedSortByFilters == 0 && window.innerWidth < 768) {
    document.querySelector(".close-button-wrapper .clear-all").style.visibility = "hidden";
  } else if (numCheckedFilters - numCheckedAnyFilters - numCheckedSortByFilters > 0 && window.innerWidth < 768) {
    document.querySelector(".close-button-wrapper .clear-all").style.removeProperty("visibility");
  }
}

function updateMoreFilters() {

  //adds first item in more filters queue to the selected filter pills and updates the +n filters text
  if (moreFiltersList.length) {
    // create a new filter pill for the first item in the more filters list
    var poppedElement = "<div class='selected-filters__pill " + moreFiltersList[0][1] + "'>" + moreFiltersList[0][0] + "<span class='svg-wrapper'><svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.3241 4.49726L8.83118 0.990214C9.05627 0.765124 9.05627 0.394815 8.83118 0.169725C8.60606 -0.0553648 8.23575 -0.0553648 8.01066 0.169725L4.50361 3.67677L0.996567 0.166095C0.767847 -0.0553648 0.401168 -0.0553648 0.172484 0.166095C-0.052642 0.391184 -0.052642 0.761493 0.172484 0.986583L3.67949 4.49363L0.168817 8.0043C-0.0562725 8.22939 -0.0562725 8.5997 0.168817 8.82479C0.281399 8.93734 0.430212 8.99539 0.579062 8.99539C0.727912 8.99539 0.876761 8.93734 0.989343 8.82479L4.49635 5.31775L8.00343 8.82479C8.11594 8.93734 8.26479 8.99539 8.41368 8.99539C8.56249 8.99539 8.71138 8.93734 8.82389 8.82479C9.04901 8.5997 9.04901 8.22939 8.82389 8.0043L5.3241 4.49726Z' fill='white'/></svg></div></div>";
    var moreFilters = document.querySelector('.selected-filters__wrapper .more-filters');
    var filtersWrapper = document.querySelector('.selected-filters__wrapper');

    if (moreFiltersList[0][1].includes("filter-tripadvisor")) {
      filtersWrapper.insertAdjacentHTML('afterbegin', poppedElement);
    } else {
      moreFilters.insertAdjacentHTML('beforebegin', poppedElement); // insert the new filter pill into the search bar just before the more filters text.
    }

    var selectedFilters = document.querySelectorAll(".selected-filters__pill");
    selectedFilters.forEach(function (pill) {
      pill.addEventListener('click', uncheckFilter); //add an event listener to each of the filter pills so that they can also be removed on click.
    });
    moreFiltersList.shift(); //pop item from stack.

    var moreFiltersPrev = moreFilters.getAttribute('data-value'); //get the previous total of +more filters

    var moreFiltersNew = moreFiltersPrev - 1;

    if (moreFiltersNew > 0) {
      moreFilters.setAttribute('data-value', moreFiltersNew); //set the new total of +more filters to the data value attribute of the more filters element

      if (moreFiltersNew > 1) {
        moreFilters.innerText = "+" + moreFiltersNew + " more filters"; //update the +x more filters text.
      } else if (moreFiltersNew == 1) {
        moreFilters.innerText = "+" + moreFiltersNew + " more filter";
      }
    } else {
      moreFilters.setAttribute('data-value', 0);
      moreFilters.innerText = "";
    }
  }
}

function uncheckFilter(e) {

  //Removes the filter pill from the search bar and unchecks the filter from the advanced search container
  e.preventDefault();
  var item = e.target;
  var checkbox;

  if (item.classList[0] != "selected-filters__pill") {
    //if clicked element isn't the pill wrapper, find the pill so that the entire element can be removed
    item.closest(".selected-filters__pill").parentNode.removeChild(item.closest(".selected-filters__pill")); //remove the selected filter pill.

    var checkboxId = item.closest(".selected-filters__pill").classList[1]; //the checkbox id is stored in the class list of each filter pill. 

    checkbox = document.querySelector('.filter-modal-v2__option-wrapper #' + checkboxId);
  } else {
    item.parentNode.removeChild(item);
    var _checkboxId = item.classList[1];
    checkbox = document.querySelector('.filter-modal-v2__option-wrapper #' + _checkboxId);
  }

  checkbox.checked = false; //uncheck the removed filter from the advanced filters list.

  storeSelection(); // update the session storage.

  updateMoreFilters(); // update the +x more filters count

  checkRecommendedFilter(); //check if there are any sort by filters applied and if not check the recommended filter.

  hideAndShowClearAll();
}

function closeAdvancedSearch(e) {

  //close the advanced search container
  e.preventDefault();
  var ASIcon = document.querySelector('.js-basket-icon');
  var filters = document.querySelector('.filter-search-results');

  if (filters.className.indexOf(' open') > -1) {
    // Is Opened
    ASIcon.style.transform = 'rotate(0deg)';
    filters.classList.remove('open');
    modalTracking("collapse", "Generic close");
  }
}

function clearAllAdvancedSearch(e) {

  //when mobile clear all button is clicked
  e.preventDefault();
  document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input').forEach(function (checkbox) {
    checkbox.checked = false;
  });
  /*document.querySelectorAll('.filter-search-results__inner .checkbox-button-group__input[id*="-99"]').forEach(checkbox => { //check the Any filter
    checkbox.checked = true;
  });*/
}

function modalTracking(type, label) {

  window.dataLayer.push({
    event: "Advanced Search",
    category: "Advanced Search Modal",
    action: type,
    label: label
  });
}

function checkboxTracking() {

  var action;
  var type = this.id.match('-(.*)-')[1]; //ie filter-boardbasis-1 becomes boardbasis

  var option = this.id;
  var name = document.getElementById(this.id).getAttribute("name");

  if (this.checked) {
    action = "Tick";
    hideAndShowClearAll();
    storeSelection();
  } else {
    action = "Untick";

    if (name == "sortorderRadio") {
      //if checked item is a radio button then remove the previous radio filter pill from the search bar.
      document.querySelector(".selected-filters__pill[class*='filter-sortorder']").remove();
    } else if (name == "tripAdvisorRadio") {
      document.querySelector(".selected-filters__pill[class*='filter-tripadvisor']").remove();
    } else {
      // name == checkbox
      if (document.querySelector(".selected-filters__pill." + option)) {
        document.querySelector(".selected-filters__pill." + option).remove(); // when a checkbox is unchecked, it will remove the corresponding filter pill from the search bar
      }
    }

    hideAndShowClearAll();
    storeSelection();
  }

  option = option.replace("filter-", "");

  if (type == "boardbasis" || type == "sortorder") {
    option = type + "-" + this.parentElement.querySelector('span').innerText;
  }

  window.dataLayer.push({
    event: "Advanced Search",
    category: "Advanced Search Options",
    action: action,
    label: option
  });
  createFilterPills();
}

function outsideAdvancedSearchClick(e) {

  //closes the advanced search container if it detects a click outside of the box whilst it is open.
  var advancedSearchContainer = document.querySelector('.filter-search-results.open');

  if (advancedSearchContainer) {
    var isClickInside = advancedSearchContainer.contains(e.target);

    if (e.target.parentNode != null) {
      if (!isClickInside && e.target.parentNode.id != "js-advanced-search" && !e.target.parentNode.classList.contains("selected-filters__pill")) {
        triggerAdvancedSearch(e);
        modalTracking("collapse", "Generic close"); //document.querySelector('.close-button-wrapper .bttn').click();
        //document.querySelector(".selected-filters__wrapper").style.removeProperty("visibility");
      }
    }
  }
}

function triggerAdvancedSearch(e) {

  e.preventDefault();
  var ASIcon = document.querySelector('.js-basket-icon');
  var filters = document.querySelector('.filter-search-results');
  var searchPanel = document.querySelector('[data-component="Search/Panels/Containers/EditSearchContainer"]');

  if (filters.className.indexOf(' open') > -1) {
    // Is Opened
    if (e.target.closest("a") !== null) {
      if (e.target.closest("a").id == "js-advanced-search") {
        //dropdown was closed via the advanced search button
        modalTracking("collapse", "Advanced search");
      }
    }

    if (e.target.closest("div") !== null) {
      if (e.target.closest("div").classList[0] == "close-button-wrapper") {
        //dropdown was closed via the done button
        modalTracking("collapse", "Done");
      }
    }

    ASIcon.style.transform = 'rotate(0deg)';
    filters.classList.remove('open');
    document.querySelector("nav.hub-container").style.removeProperty("display"); // document.querySelector(".selected-filters__wrapper").style.removeProperty("visibility");

    storeSelection(); //populate session storage with any selected checkboxes.

    var selection = JSON.parse(window.sessionStorage.getItem('ADVANCED_SEARCH'));
    moreFiltersList = [];

    if (selection) {
      document.querySelectorAll('.selected-filters__wrapper div').forEach(function (e) {
        return e.remove();
      }); //remove anything in the selected filters wrapper to avoid duplicted pills

      createFilterPills();
    } //scroll back to top


    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  } else {
    // Is Closed
    if (e.target.closest("a") !== null) {
      if (e.target.closest("a").id == "js-advanced-search") {
        //dropdown was opened via the advanced search button
        modalTracking("expand", "Advanced search");
      }
    }

    ASIcon.style.transform = 'rotate(180deg)';
    filters.classList.add('open');
    document.querySelector(".advanced-search__wrapper").classList.add("expand");

    if (window.innerWidth < 768 && document.querySelector('.filter-search-results').className.indexOf(' open') > -1) {
      //if mobile width and advanced container is open
      document.querySelector("nav.hub-container").style.display = "none";
    } //document.querySelector(".selected-filters__wrapper").style.visibility = "hidden";

  }
}

function uncheckOther(items) {

  var isAnyChecked = items[0].checked;

  for (var i = 1; i < items.length; i++) {
    var item = items[i];
    item.checked = !isAnyChecked;
  }
}

function uncheckOtherBoardBasis() {

  var allBoardBasis = document.querySelectorAll('[id^="filter-boardbasis-"]');
  uncheckOther(allBoardBasis);
}

function uncheckOtherStarRating() {

  var allStarRating = document.querySelectorAll('[id^="filter-starrating-"]');
  uncheckOther(allStarRating);
}

function storeSelection() {

  var allBoardBasis = document.querySelectorAll('[id^="filter-boardbasis-"]');
  var allStarRating = document.querySelectorAll('[id^="filter-starrating-"]');
  var allTripadvisorRating = document.querySelectorAll('[id^="filter-tripadvisor-"]');
  var allSortOrder = document.querySelectorAll('[id^="filter-sortorder-"]');
  var boardbasis = [];
  var starrating = [];
  var tripadvisor = [];
  var sortorder = []; //if (!allBoardBasis[0].checked) {

  for (var i = 1; i < allBoardBasis.length; i++) {
    if (allBoardBasis[i].checked) {
      var num = allBoardBasis[i].id.replace('filter-boardbasis-', '');
      boardbasis.push(num);

      switch (num) {
        case 1:
          window.optimizely.push({
            type: 'event',
            eventName: 'as-b-n-b'
          });
          break;

        case 2:
          window.optimizely.push({
            type: 'event',
            eventName: 'as-half-board'
          });
          break;

        case 3:
          window.optimizely.push({
            type: 'event',
            eventName: 'as-full-board'
          });
          break;

        case 4:
          window.optimizely.push({
            type: 'event',
            eventName: 'as-self-catering'
          });
          break;

        case 5:
          window.optimizely.push({
            type: 'event',
            eventName: 'as-all-inclusive'
          });
          break;

        default:
          break;
      }
    }
  } // }
  // if (!allStarRating[0].checked) {


  for (var _i = 1; _i < allStarRating.length; _i++) {
    if (allStarRating[_i].checked) {
      var _num = allStarRating[_i].id.replace('filter-starrating-', '');

      starrating.push(_num);
      window.optimizely.push({
        type: 'event',
        eventName: "as-our-".concat(_num)
      });
    }
  } //}
  // if (!allTripadvisorRating[0].checked) {


  for (var _i2 = 1; _i2 < allTripadvisorRating.length; _i2++) {
    if (allTripadvisorRating[_i2].checked) {
      var _num2 = allTripadvisorRating[_i2].id.replace('filter-tripadvisor-', '');

      tripadvisor.push(_num2);
      window.optimizely.push({
        type: 'event',
        eventName: "as-ta-".concat(_num2)
      });
    }
  } // }


  for (var _i3 = 1; _i3 < allSortOrder.length; _i3++) {
    if (allSortOrder[_i3].checked) {
      var _num3 = allSortOrder[_i3].id.replace('filter-sortorder-', '');

      sortorder.push(_num3);
      window.optimizely.push({
        type: 'event',
        eventName: "as-ta-".concat(_num3)
      });
    }
  }

  window.sessionStorage.setItem('ADVANCED_SEARCH', JSON.stringify({
    boardbasis: boardbasis,
    starrating: starrating,
    tripadvisor: tripadvisor,
    sortorder: sortorder
  }));
}

function uncheckAnyBoardBasis() {

  var boardBasisAny = document.querySelector('#filter-boardbasis-99');
  boardBasisAny.checked = false;
}

function uncheckAnyStarRating() {

  var starRatingAny = document.querySelector('#filter-starrating-99');
  starRatingAny.checked = false;
}

utils.poll(['body div.outer','[data-component="Feature/SearchPanels/SmartSearchPanel"]','form[class=""] .search-box__submit.button.bttn.bttn--primary'],([header, formComponent, submitButton]) => {

  formComponent.insertAdjacentHTML('beforeend', AdvancedSearchHTML);
  document.querySelector('#js-advanced-search').addEventListener('click', triggerAdvancedSearch);
  /*if (window.innerWidth < 768) {
  header.insertAdjacentHTML('afterbegin', FormHTML);
  }else{
    formComponent.insertAdjacentHTML('afterbegin', FormHTML);
  }*/

  formComponent.insertAdjacentHTML('afterbegin', FormHTML);
  setTimeout(function () {
    loadFilters();
    hideAndShowClearAll();
    storeSelection();
  }, 2000);
  document.querySelector('.close-button-wrapper .bttn').addEventListener('click', triggerAdvancedSearch);
  document.querySelector('.close-button-wrapper .clear-all').addEventListener('click', clearAllAdvancedSearch); //when the search bar is clicked and a modal appears the close the advanced search container  

  document.querySelector('#search-box-guests').addEventListener('click', closeAdvancedSearch);
  /* document
   .querySelector('.search-box-group--leaving .search-box-group__field button')
   .addEventListener('click', closeAdvancedSearch);
     document
   .querySelector('.search-box-group--from .search-box-group__field button')
   .addEventListener('click', closeAdvancedSearch);
     document
   .querySelector('.search-box-group--to .search-box-group__field button')
   .addEventListener('click', closeAdvancedSearch);
     document
   .querySelector('.search-box-group--guests .search-box-group__field button')
   .addEventListener('click', closeAdvancedSearch);*/

  document.querySelector('#search-box-leaving').addEventListener('click', closeAdvancedSearch);
  document.querySelector('.search-box-recent').addEventListener('click', closeAdvancedSearch);
  /*document
  .querySelectorAll("input.checkbox-button-group__input")
  .addEventListener('change', checkboxTracking);*/

  document.querySelector('body').addEventListener('click', outsideAdvancedSearchClick);
  var filterCheckboxes = document.querySelectorAll("input.checkbox-button-group__input");

  for (var i = 0; i < filterCheckboxes.length; i++) {
    filterCheckboxes[i].addEventListener("change", checkboxTracking);
  }

  var boardBasisAll = document.querySelector('#filter-boardbasis-99');
  boardBasisAll.addEventListener('click', uncheckOtherBoardBasis);
  var boardBasis = document.querySelectorAll('[id^="filter-boardbasis-"]');

  for (var _i4 = 1; _i4 < boardBasis.length; _i4++) {
    boardBasis[_i4].addEventListener('click', uncheckAnyBoardBasis);
  }

  var starRatingAll = document.querySelector('#filter-starrating-99');
  starRatingAll.addEventListener('click', uncheckOtherStarRating);
  var starRating = document.querySelectorAll('[id^="filter-starrating-"]');

  for (var _i5 = 1; _i5 < starRating.length; _i5++) {
    starRating[_i5].addEventListener('click', uncheckAnyStarRating);
  }

  submitButton.addEventListener('click', storeSelection);
});
