/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function(){
    //console.log("%c| MMB Menu V7.3 |", "background-color: #50BFE6; color: white; font-weight: bold;");
    var utils = window.optlyUtils || window.globalUtils;
    // Create a new click event
    const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
    });

    utils.poll(["nav[data-component*='NavBar']"],([navigationBar])=>{
        populateList();
        togglemenu();
        enterKeyclickSimulation(); // accessibilty - Enter key

        // Mobile version additional changes
        mobileHeaderIcon(); 
        logoutClickSimulate();

        makePhoneNumberBold('.mega-menu__column a', '0333 300 0702');

        // To make sure that the class is removed from Mobile to Desktop
        window.addEventListener('resize', checkViewportWidth);

        // Adding Events Listener to anchor to the specific heading
        anchoringEventListeners();

        //Makeing sure that the Collapsible menu doesnt overflow
        collapsibleMenuOverflow();
    });

    function generateListItemHTML() {
        return `
            <li class="nav-bar__list-item js-navbar-item">
                <div tabindex=0 class="nav-bar__title js-navbar-title js-holiday-types">
                <svg class="nav-bar__icon-up" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 7L7 0.999999L1 7" stroke="#203240" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><svg class="nav-bar__icon-down" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L7 5.93934L12.4697 0.46967C12.7626 0.176777 13.2374 0.176777 13.5303 0.46967C13.8232 0.762563 13.8232 1.23744 13.5303 1.53033L7.53033 7.53033C7.23744 7.82322 6.76256 7.82322 6.46967 7.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z" fill="#203240"/>
                </svg>
                </div>
                <div class="mega-menu nav-bar-collapsible-menu js-collapsible-menu">
                <ul class="mega-menu__wrapper js-mega-menu-wrapper">
                </ul>
                </div>
            </li>
        `;
    }
    function togglemenu() {
      // Get all the title elements
      var itemTitles = document.querySelectorAll('.js-navbar-title');

      // Add click event listener to each title
      itemTitles.forEach(function(title) {
          title.addEventListener('click', function() {
              var titleParent = title.parentElement;

              if (!isSmallScreen()) {
                  // Close all other menus if not on a small screen (desktop)
                  document.querySelectorAll('.js-navbar-item').forEach(function(item) {
                      if (item !== titleParent) {
                          item.classList.remove("nav-bar__list-item--open");
                      }
                  });
              }             

              titleParent.classList.toggle("nav-bar__list-item--open");
          });
      });
    }
    function populateList() {

        const titlesAndSubtitles = {
            "Amend booking": [
                { text: "Add passenger", url: "/mmb/direct/add-passenger" },
                { text: "Change passenger", url: "/mmb/direct/passenger-details" },
                { text: "Change holiday date", url: "/mmb/direct/date-change" },
                { text: "Booking contact details", url: "/mmb/direct/contact-details" },
                { text: "Change flight", url: "/mmb/direct/date-change" }
            ],
            "Amend hotel": [
                { text: "Hotel details", url: "/mmb/direct/hotel-details" },
                { text: "Upgrade room and board", url: "/mmb/direct/amend-accommodation" },
                { text: "Special requests", url: "/mmb/direct/special-request-form" }
            ],
            "Add or amend essentials": [
                { text: "Seats", url: "/mmb/direct/products/essentials#seats" },
                { text: "Meals", url: "/mmb/direct/products/essentials#meals" },
                { text: "Travel insurance", url: "/mmb/direct/products/essentials#insurance" },
                { text: "Car hire", url: "https://www.jet2carhire.com/" },
                { text: "Excursions", url: "/mmb/direct/products/excursions" },
                { text: "Add bags", url: "/mmb/direct/products/essentials#bags" },
                { text: "Guaranteed cabin luggage", url: "/mmb/direct/products/essentials#gcl" }
            ],
            "Documents": [
                { text: "Add travel identification", url: "/mmb/direct/add-travel-details" },
                { text: "Holiday documents", url: "/mmb/direct/important-documents" },
                { text: "Boarding passes", url: "/mmb/direct/important-documents" }
            ],
            "Manage payments": [
                { text: "Make a payment", url: "/mmb/direct/balance/payment" },
                { text: "Change payment method", url: "/mmb/direct/balance/register-payment-method" },
                { text: "Set up payment plan", url: "/mmb/direct/payment-plan/setup" },
                { text: "Your payment plan", url: "/mmb/direct/payment-plan" },
                { text: "Amend payment plan", url: "/mmb/direct/payment-plan/edit-date" },
                { text: "Cancel payment plan", url: "/mmb/direct/payment-plan/cancel" }
            ],
            "Support": [
                { text: "FAQs", url: "/faqs" },
                { text: "Terms and conditions", url: "/mmb/direct/important-documents" },
                { text: "Cancel booking", url: "/mmb/direct/cancel-elective" },
                { text: "Contact us - 0333 300 0702", url: "tel:0333 300 0702" }
            ]
        };
        
        
        const navBarList = document.querySelector('.nav-bar__list');

        for (let index = 0; index < Object.keys(titlesAndSubtitles).length; index++) {
            const listItemHTML = generateListItemHTML();

            // Append the list item
            navBarList.insertAdjacentHTML('beforeend', listItemHTML);   
        }

        const navBarListItems = navBarList.querySelectorAll(".js-navbar-item");

        Object.keys(titlesAndSubtitles).forEach((title, index) => {
            const item = navBarListItems[index];
            if (item) {
                const subTitles = titlesAndSubtitles[title];
                item.querySelector(".js-navbar-title").insertAdjacentHTML('afterbegin', title);
        
                const ul = item.querySelector(".js-mega-menu-wrapper");
                if (ul) {
                    subTitles.forEach(subTitle => {
                        const li = document.createElement('li');
                        li.classList.add('mega-menu__column');
                        
                        const anchor = document.createElement('a');
                        anchor.href = subTitle.url;
                        anchor.textContent = subTitle.text;
                        
                        li.appendChild(anchor);
                        ul.appendChild(li);
                    });
                }
            }
        });
        
        //Sign out For Mobile only
        navBarList.insertAdjacentHTML('beforeend',`<li class="nav-bar__list-item js-navbar-item" id="mobilelogout"><div class="nav-bar__title js-navbar-title">Sign out<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 11.75L14.75 8M14.75 8L11 4.25M14.75 8L5.75 8M5.75 1.25H4.85C3.58988 1.25 2.95982 1.25 2.47852 1.49524C2.05516 1.71095 1.71095 2.05516 1.49524 2.47852C1.25 2.95982 1.25 3.58988 1.25 4.85L1.25 11.15C1.25 12.4101 1.25 13.0402 1.49524 13.5215C1.71095 13.9448 2.05516 14.289 2.47852 14.5048C2.95982 14.75 3.58988 14.75 4.85 14.75H5.75" stroke="#203240" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></div></li>`);
    }
    function mobileHeaderIcon() {
        const mobileHeader = document.querySelector(".r-desktop-header .mobile-menu-header");
        mobileHeader.insertAdjacentHTML("afterbegin",`<svg class="js-mobile-header-icon" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" stroke="#203240" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`);

        document.querySelector(".js-mobile-header-icon").addEventListener('click',function() {
            // Dispatch the event on the second SVG
            this.nextElementSibling.nextElementSibling.dispatchEvent(clickEvent);
        });        
    }
    function logoutClickSimulate() {
        document.getElementById("mobilelogout").addEventListener('click',function() {
            document.getElementById("logout").dispatchEvent(clickEvent);
        });
    }
    function makePhoneNumberBold(selector, phoneNumber) {
        var elements = document.querySelectorAll(selector); 
        var regex = new RegExp(`(${phoneNumber})`, 'g');

        elements.forEach(function(element) {
            var innerHTML = element.innerHTML;
            var newHTML = innerHTML.replace(regex, '<b><u>$1</u></b>');
            element.innerHTML = newHTML;
        });
    }
    function checkViewportWidth() {
        const listItem = document.querySelectorAll('.nav-bar__list-item');

        if (!isSmallScreen()) { // Adjust the width threshold as needed
            listItem.forEach(item => {
                item.classList.remove('nav-bar__list-item--open');
            });
        }
    }
    function isSmallScreen() {
        return window.innerWidth <= 767;
    }
    function anchoringEventListeners() {

        // This part runs on the current page to set a flag before navigation
        const boardingPassLink = document.querySelectorAll('.nav-bar__list-item a[href="/mmb/direct/important-documents"]');
        if (boardingPassLink.length > 1) {
            const boardingPassLinkParent = boardingPassLink[1].parentElement;

            if (boardingPassLinkParent) {
                boardingPassLinkParent.addEventListener('click', (event) => {
                    // Set a flag to identify the navigation
                    window.localStorage.setItem('navigatingToImportantDocuments', 'true');
                });
            }
        }

        // This part runs on every page load to check the flag and execute code if necessary
        window.addEventListener('load', () => {
            if (window.localStorage.getItem('navigatingToImportantDocuments') === 'true') {
                window.localStorage.removeItem('navigatingToImportantDocuments');

                utils.poll([".lifestyle-content__menu"], ([lifestyleContentMenu]) => {

                    const tabs = document.querySelectorAll('.lifestyle-content__tab');
                    tabs.forEach(tab => {
                        const label = tab.querySelector('.lifestyle-content__tab-label');
                        if (label && label.textContent.trim() === 'Boarding passes') {
                            // Remove data-tab-active from all tabs
                            tabs.forEach(t => t.removeAttribute('data-tab-active'));
                            // Set data-tab-active to true for the Boarding passes tab
                            tab.setAttribute('data-tab-active', 'true');
                        }
                    });
                });
            }
        });
      
        
    }
    function enterKeyclickSimulation() {
        // Get all the title elements
        var itemTitles = document.querySelectorAll('.js-navbar-title');
        itemTitles.forEach(function(title) {
            title.addEventListener('keydown', function(event) {
                if (event.type === 'keydown' && event.key === 'Enter') {
                    title.dispatchEvent(clickEvent);
                }
            });
        });
    }
    function collapsibleMenuOverflow() {
        // Assuming you have a click event directly on js-navbar-item to toggle its state
        const navbarItems = document.querySelectorAll('.js-navbar-item');
        navbarItems.forEach(function(navbarItem) {
            navbarItem.addEventListener('click', function(event) {
                event.preventDefault();
                var item = navbarItem.querySelector(".js-collapsible-menu");
                
                // Check if the navbar item goes over the screen width
                var rect = item.getBoundingClientRect();
                if (rect.right > window.innerWidth) {
                    item.style.right = '12px';
                } else {
                    item.style.right = ''; // Reset if no overflow
                }
            });
            navbarItem.querySelectorAll('.mega-menu__column').forEach(function(column) {
                column.addEventListener('click', function(event) {
                    event.stopPropagation();
                });
            });
        });

    }
    document.body.classList.add("CU106");
})();