/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function() {
    //console.log("%c| MJ013 SMS Sign Up Banner J2H Original |", "background-color: #50BFE6; color: white; font-weight: bold;");
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll([".sitecore-form",".email-signup__wrapper"],([sitecoreForm,emailSignUp]) => {
        impressionEvent();
        //document.body.classList.add("MJ013");
    });
    function impressionEvent() {
        const target = document.querySelector('.email-signup__conditions');

        const options = {
        root: null, // Observe relative to the viewport
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the element is visible
        };

        const callback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "email_signup",
                    category: "Email sign up",
                    action: "Impression",
                    label: "Sign up for our emails now!"
                });

            observer.unobserve(entry.target);
            }
        });
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
       
    }
})();