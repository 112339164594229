if (!(window.location.href.includes('boardbasis') || window.location.href.includes('starrating') || window.location.href.includes('tripadvisor') || window.location.href.includes('sortorder'))) {
    let selection = JSON.parse(window.sessionStorage.getItem('ADVANCED_SEARCH'));

    if (selection) {
        var formedQuery = '';
        Object.keys(selection).map((key) => {
        let formQuery = `&${key}=`;

        selection[key].map((number, index) => {
            formQuery += index === 0 ? number : `_${number}`;
        });

        if (formQuery !== `&${key}=`) {
            formedQuery += formQuery;
        }
        });

        if (formedQuery !== '') {
        //window.sessionStorage.removeItem('ADVANCED_SEARCH');
        window.location.href = window.location.href + formedQuery; //console.log("look here -> "+window.location.href + formedQuery);
        //console.log("look here -> "+formedQuery);
        }
    }
}