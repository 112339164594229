const DISCOUNT_CODES = {
  '31/03/2025': '12D775XCD',
  '01/04/2025': '12D775XCE',
  '02/04/2025': '12D775XCF',
  '03/04/2025': '12D775XCG',
  '04/04/2025': '12D775XCH',
  '05/04/2025': '12D775XCI',
  '06/04/2025': '12D775XCJ',
  '07/04/2025': '12D775XCK',
  '08/04/2025': '12D775XCL',
  '09/04/2025': '12D775XCM',
};

module.exports = { DISCOUNT_CODES };
