const filtersItemTemplate = require("./filtersItemTemplate");
const filtersListData = require("./filtersListData");

function filtersTemplate(holidayType = 'beach', childrenSelected = false) {
    const filterLists = getFiltersLists(holidayType, childrenSelected);

    const template = `   
        <div class="text-white advanced-search-button">
            What's important to you?
        </div>    
        <div data-name="filter-search-results" class="filter-search-results">
            <div class="filter-search-results__inner">
                ${filterLists}
            </div>
        </div>
    `;

    function listTemplate(props, holidayType, childrenSelected){
        const filterItems = getFiltersItems(props, holidayType, childrenSelected);

        return `
            <div class="filter-modal-v2__item js-filter-modal-v2-panel">
                <div class="clearfix">
                    <h2 class="filter-modal-v2-content__heading">${props.title}</h2>
                    <div
                    class="filter-modal-v2-options checkbox-button-group checkbox-button-group--list"
                    >
                        ${filterItems}
                    </div>
                </div>
            </div>
        `;
    } 

    function getFiltersLists(holidayType, childrenSelected) {
        const arr = [];
        for (const item in filtersListData) {
            const listProps = filtersListData[item];
            arr.push(listTemplate(listProps, holidayType, childrenSelected));
        }
        const combinedLists = arr.join('');
        return combinedLists;
    }

    function getFiltersItems(props, holidayType, childrenSelected) {
        const items = props.items;
        const arr = [];
        if (props.title === 'Facilities') {
            if (holidayType === 'beach') {
                if (childrenSelected) {
                    for (const item in items.beach.family) {
                        const listProps = items.beach.family[item];
                        arr.push(filtersItemTemplate(listProps));
                    }
                } else {
                    for (const item in items.beach.nonFamily) {
                        const listProps = items.beach.nonFamily[item];
                        arr.push(filtersItemTemplate(listProps));
                    }
                }
            } else {
                for (const item in items.city) {
                    const listProps = items.city[item];
                    arr.push(filtersItemTemplate(listProps));
                }
            }
        } else {
            for (const item in items) {
                const listProps = items[item];
                arr.push(filtersItemTemplate(listProps));
            }
        }
        
        const combinedItems = arr.join('');
        return combinedItems;
    }

    return template;
}

module.exports =  filtersTemplate;
