/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
    console.log(
      "%c| BF142 - Holiday Details Hide Footer v2 v1.1 |",
      "background-color: #50BFE6; color: white; font-weight: bold;"
    );
    var utils = window.optlyUtils || window.globalUtils;
    utils.poll(
      [".footer-links", ".footer-v2__info-columns"],
      ([footerLinks, footerInfoColumns]) => {
        (function () {
          window.dataLayer = window.dataLayer || [];
  
          //Track selection change
          function trackFirstFooterClicks() {
            window.dataLayer.push({
              event: "footer",
              category: "Footer 1 area",
              action: "Click",
              label: "Footer 1 area clicked",
            });
          }
  
          function trackSecondFooterClicks() {
            window.dataLayer.push({
              event: "footer",
              category: "Footer 2 area",
              action: "Click",
              label: "Footer 2 area clicked",
            });
          }
  
          function addClickTracking(container, selector, callback) {
            if (container) {
              container.addEventListener("click", function (event) {
                if (event.target.closest(selector)) {
                  callback();
                }
              });
            }
          }
  
          addClickTracking(footerLinks, "a", trackFirstFooterClicks);
          addClickTracking(footerInfoColumns, "a", trackSecondFooterClicks);
          addClickTracking(footerInfoColumns, "input", trackSecondFooterClicks);
          addClickTracking(footerInfoColumns, "button", trackSecondFooterClicks);
        })();
      }
    );
  })();
  