let utils = window.optlyUtils || window.globalUtils;
let opUtils = window.optimizely.get("utils");

opUtils.waitForElement(".full-screen-loader[data-spin]:not(.full-screen-loader--active)").then(function () {
    utils.poll([".detailed-card-container .detailed-card-header", "div[data-component*='SearchResults'].wrapper"],([detailedCards, searchContainer]) => {
        resetModalCalendar();//the modal calendar is initially loaded with the current date, we want to update this with the departure date
        main();
        observeModalCalendarSelection();
        cardLoaderObserver();
        document.querySelector("body").classList.add("BF137");
    });
});

function cardLoaderObserver(){
    let loaderAdded = false;
    let cardLoaderObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.target.classList.contains('show-loader--fullscreen')) {
                loaderAdded = true;
            }
            if (loaderAdded == true && !mutation.target.classList.contains('show-loader--fullscreen')) {
                //once the loader has finished loading.
                resetModalCalendar();
                main();
            }
        });
    });
    cardLoaderObserver.observe(document.querySelector('[data-component*="ResultsContainer"]'), {
        attributes: true,
        attributeFilter:["class"]
    });
}

function main(){
    opUtils.waitForElement("#duration-month option").then(function () {
        opUtils.waitForElement("#duration-year option").then(function () {
            cloneCalendar();
            createNightsDropdown();
            observeNightsChange();
            updateSidePanelMonthAndYear();
            sidePanelDaysListener();
            updateDaysOfWeek();
            changeMonthListener(); 
        });
    });
}

function updateDaysOfWeek(){//shortens the weekay initails to 2 characters rather than 3
    let calendarDays = document.querySelectorAll(".modal.modal--filters.search-filter-modal .calendar-view__labels li");
    for(let i=0;i<calendarDays.length;i++){
        let dow = calendarDays[i].getAttribute("data-abbr");
        if(calendarDays[i].innerText != ""){
            calendarDays[i].innerText = calendarDays[i].innerText.substring(0,2);
        }else{
            calendarDays[i].innerText = dow.substring(0,2);
        }
        
        calendarDays[i].setAttribute("data-abbr", dow.substring(0,2));
    }
}

function observeModalCalendarSelection(){
    opUtils.waitForElement("#duration-year option").then(function () {
        let modalCalendarObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutationRecord) {
                let yearDropdown = document.querySelector('#duration-year');
                if(yearDropdown.options.length > 0){
                    let lastYearValue = yearDropdown.options[yearDropdown.options.length - 1].value;
                    if(mutationRecord.target.value == lastYearValue){
                        main();
                    }
                }
            });
        });

        let target = document.querySelector(".modal:not(.modal--filters.search-filter-modal) .calendar-date-selector .calendar-date-selector-controls__dropdowns");
        modalCalendarObserver.observe(target, {attributes: true, childList: true, characterData: true, subtree: true});
    });
}

function observeModalCalendar(){
    let modalCalendarObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
            if (mutationRecord.target.id == "duration-month") {
                resetModalCalendar();
                modalCalendarObserver.disconnect();
            }
        });
    });

    let target = document.querySelector(".modal:not(.modal--filters.search-filter-modal) .calendar-date-selector");
    modalCalendarObserver.observe(target, {attributes: true, childList: true, characterData: true, subtree: true});
}

function cloneCalendar(){//creates a clone of the exisitng modal calendar and places it in the filters side panel
    const node = document.querySelector(".calendar-date-selector");
    const clone = node.cloneNode(true);
    
    if(document.querySelector(".modal.modal--filters.search-filter-modal .calendar-date-selector")){
        document.querySelector(".modal.modal--filters.search-filter-modal .calendar-date-selector").remove();
    }

    if(!document.querySelector(".filter-modal-v2-content__heading--leaving-and-nights")){
        let cloneHeading = '<h2 class="filter-modal-v2-content__heading filter-modal-v2-content__heading--leaving-and-nights">Leaving & nights</h2>';
        document.querySelector(".filter-modal-v2__top").insertAdjacentHTML("beforebegin",cloneHeading);
    }

    let calendarContainer = '<div class="calendar-date-selector__wrapper"></div>';
    
    if(!document.querySelector(".calendar-date-selector__wrapper")){
        document.querySelector(".filter-modal-v2-content__heading--leaving-and-nights").insertAdjacentHTML("afterend",calendarContainer);
    }
    
    document.querySelector(".calendar-date-selector__wrapper").insertAdjacentElement("afterbegin",clone);
}

function createNightsDropdown(){//creates dropdown field in the side panel and copies in the existing nights options
    if(document.querySelector(".search-box-nights")){
        document.querySelector(".search-box-nights").closest(".form-select").remove(); //remove previously created dropdown
    }

    let nightsDropdown = '<div class="form-select form-select--light form-select--bordered form-select--nights"><select class="form-select__origin search-box-nights"></select></div>';
    //append the dropdown before the error (if it exists)
    if(document.querySelector(".modal.modal--filters.search-filter-modal .nights-dropdown-error")){
        document.querySelector(".modal.modal--filters.search-filter-modal .nights-dropdown-error").insertAdjacentHTML("beforebegin",nightsDropdown);
    }else{
        document.querySelector(".calendar-date-selector__wrapper").insertAdjacentHTML("beforeend",nightsDropdown);
    }

    let modalDropwdown = document.querySelector("#search-box-nights"); //add dropdown options
    nightsDropdown = document.querySelector(".search-box-nights");
    nightsDropdown.insertAdjacentHTML("beforeend",modalDropwdown.innerHTML);
    let nightsDropdownOptions = document.querySelectorAll(".search-box-nights option");
    for(let i=0; i<nightsDropdownOptions.length;i++){
        if(nightsDropdownOptions[i].innerText.replace(/\s/g,'') == "1"){
            nightsDropdownOptions[i].innerText = nightsDropdownOptions[i].innerText + "Night";
        }else{
            nightsDropdownOptions[i].innerText = nightsDropdownOptions[i].innerText + "Nights";
        }
    }
    
    nightsDropdown.value = modalDropwdown.value; //set side panel dropdown value to the current selected value

    removeNightsError(nightsDropdown.value);
    let dropDownPlaceholder;
    let dropdownError = '<div class="nights-dropdown-error" data-tooltip="tooltip-call-today"><p>Previous nights unavailable for chosen date.</p></div>'

    if(nightsDropdown.value == ""){
        dropDownPlaceholder = '<option value="" disabled selected>Select Nights</option>';
        if(!document.querySelector(".modal.modal--filters.search-filter-modal .nights-dropdown-error")){
            nightsDropdown.closest(".form-select--nights").insertAdjacentHTML("beforeend",dropdownError);
            let clearAllBtn = document.querySelector(".modal--to-inpage .filter-modal-v2__top > .filter-modal-v2__modal-link");
            clearAllBtn.style.top = "680px";
            let searchPanelNights = document.querySelector(".search-box-group.search-box-group--nights");
            let editSearchBtn = document.querySelector(".edit-search--mobile button.js-edit-trigger");
            searchPanelNights.insertAdjacentHTML("beforeend",dropdownError); //add error message to the search panel
            if(editSearchBtn){
                if(document.querySelector(".edit-search-panel-wrapper").style.display != "block"){ //open the search panel if it isn't already
                    editSearchBtn.click();
                }
            }
            modalTracking("Duration and departure in filter","Impression","Previous nights unavailable for chosen date.");
        }
        nightsDropdown.closest(".calendar-date-selector__wrapper").classList.add("nights-error");
    }else{
        dropDownPlaceholder = '<option value="" disabled>Select Nights</option>';
    }

    nightsDropdown.insertAdjacentHTML("afterbegin",dropDownPlaceholder);

    nightsDropdown.addEventListener('focusin', evt => { //when the dropdown is opened
        modalTracking("Duration and departure in filter","Open","Duration dropdown");
    });
}

function updateSidePanelMonthAndYear(){
    let modalCalendarMonth = document.querySelector("#duration-month").value;
    let sidePanelCalendarMonth =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-month");
    sidePanelCalendarMonth.value = modalCalendarMonth;

    let modalCalendarYear = document.querySelector("#duration-year").value;
    let sidePanelCalendarYear =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-year");
    sidePanelCalendarYear.value = modalCalendarYear;
}

function updateModalCalendar(e,month,year) {
    let modalDays = document.querySelectorAll(".modal:not(.modal--filters.search-filter-modal) .calendar-view__date .stack-select:not(.is-disabled) .stack-select__top");
    let modalCalendarMonth = document.querySelector(".modal:not(.modal--filters.search-filter-modal) #duration-month");
    let modalCalendarYear = document.querySelector(".modal:not(.modal--filters.search-filter-modal) #duration-year");

    opUtils.waitForElement('#duration-month option[value="'+month+'"]').then(function () {
        if(modalCalendarMonth.value != month){ 
            let selectedIndex = document.querySelector('#duration-month option[value="'+month+'"]').index;
            modalCalendarMonth.selectedIndex = selectedIndex;            
            let event = new Event('change');
            modalCalendarMonth.dispatchEvent(event);
        }
    });
    opUtils.waitForElement('#duration-year option[value="'+year+'"]').then(function () {
        if(modalCalendarYear.value != year){
            let selectedIndex = document.querySelector('#duration-year option[value="'+year+'"]').index;
            modalCalendarYear.selectedIndex = selectedIndex;
            let event = new Event('change');
            modalCalendarYear.dispatchEvent(event);  

            selectedIndex = document.querySelector('#duration-month option[value="'+month+'"]').index;
            modalCalendarMonth.selectedIndex = selectedIndex;            
            event = new Event('change');
            modalCalendarMonth.dispatchEvent(event);
        }
    });

    if(e){
        let sidePanelDay = e.target.innerText.replace(/\s/g,'');
        for (let i = 0; i < modalDays.length; i++) {
            if(modalDays[i].innerText.replace(/\s/g,'') == sidePanelDay){
                modalDays[i].click();
            }
        }
    }
}

function sidePanelDaysListener(){//each time the side panel calendar is created, a listener is added to each day cta to track what date has been selected.
    let sidePanelDays =  document.querySelectorAll(".modal.modal--filters.search-filter-modal .calendar-view__date");
    let month =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-month");
    let year =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-year");

    for (let i = 0; i < sidePanelDays.length; i++) {
        sidePanelDays[i].addEventListener("click", function(event){
            if(sidePanelDays[i].querySelector(".stack-select:not(.stack-select--blank):not(.is-disabled)")){
                modalTracking("Duration and departure in filter","Click","New departure date selected");
                updateModalCalendar(event,month.value,year.value);

                //replace previous selected day with new selected day
                let selectedDay = document.querySelector(".modal.modal--filters.search-filter-modal .calendar-view__date .stack-select.is-selected");
                if(selectedDay){
                    selectedDay.classList.remove("is-selected");
                }
                event.target.classList.add("is-selected");

                setTimeout(function(){ //wait 0.5 second to allow for if the nights field is emptied in top search bar
                    if(!document.querySelector(".modal.modal--filters.search-filter-modal .nights-dropdown-error")){
                        let day =  event.target.innerText.replace(/\s/g,'').split(" ")[0];
                        let selectedMonth =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-month").value;
                        let selectedYear =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-year").value;
                        let nightsDropdown = document.querySelector(".modal.modal--filters.search-filter-modal .search-box-nights");
                        
                        location.assign(updateURL(day,selectedMonth,selectedYear,nightsDropdown.value));    
                    }     
                }, 500);
            }
        });
    }
}

function observeNightsChange(){
    let originalNightsDropdown = document.getElementById('search-box-nights');
    originalNightsDropdown.onchange = (event) => {
        let sidePanelNightsDropdown = document.querySelector(".search-box-nights");
        sidePanelNightsDropdown.value = event.target.value;
        removeNightsError(event.target.value);
    }

    let cloneNightsDropdown = document.querySelector('.search-box-nights');
    cloneNightsDropdown.onchange = (event) => {

        modalTracking("Duration and departure in filter","Click","New duration selected");
        removeNightsError(event.target.value);

        let selectedDay = document.querySelector(".modal.modal--filters.search-filter-modal .calendar-view__date .stack-select.is-selected");
        let selectedMonth =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-month").value;
        let selectedYear =  document.querySelector(".modal.modal--filters.search-filter-modal #duration-year").value;
        
        if(selectedDay){
            location.assign(updateURL(selectedDay.innerText.replace(/\s/g,'').split(" ")[0],selectedMonth,selectedYear,cloneNightsDropdown.value));
        } 
    }
}

function updateURL(day,month,year,nights){
    let selectedDate = day+"-"+month+"-"+year;
    let url = new URL(location.href);

    url.searchParams.set("date", selectedDate);
    url.searchParams.set("duration", nights);
    return url;
}

function removeNightsError(dropdownValue){
    let dropdownError = document.querySelector(".calendar-date-selector__wrapper .nights-dropdown-error");

    if(dropdownError && dropdownValue && (dropdownValue != "")){
        dropdownError.closest(".calendar-date-selector__wrapper").classList.remove("nights-error");
        dropdownError.remove();
    }

    let clearAllBtn = document.querySelector(".modal--to-inpage .filter-modal-v2__top > .filter-modal-v2__modal-link");
    clearAllBtn.style.removeProperty("top");

    let searchPanelNightsError = document.querySelector(".search-box-group.search-box-group--nights .nights-dropdown-error");
    if(searchPanelNightsError){
        document.querySelector(".calendar-date-selector__wrapper").classList.remove("nights-error");
        searchPanelNightsError.remove();
    }
}

function changeMonthListener(){//on click side panel arrow, click modal arrow and then clone new calendar
    let sidePanelRightArrow = document.querySelector(".modal.modal--filters.search-filter-modal .calendar-date-selector-controls__right button");
    let sidePanelLeftArrow = document.querySelector(".modal.modal--filters.search-filter-modal .calendar-date-selector-controls__left button");

    sidePanelRightArrow.addEventListener("click", function() {
        document.querySelector(".modal:not(.modal--filters.search-filter-modal) .calendar-date-selector-controls__right button").click();
        modalTracking("Duration and departure in filter","Click","Next month arrow");
        main();
    });

    sidePanelLeftArrow.addEventListener("click", function() {
        document.querySelector(".modal:not(.modal--filters.search-filter-modal) .calendar-date-selector-controls__left button").click();
        modalTracking("Duration and departure in filter","Click","Previous month arrow");
        main();
    });
}

function resetModalCalendar(){
    let date = document.querySelector("#search-box-leaving").value.split(" ");
    let month =  getMonthFromString(date[2]);
    let year =  date[3];

    if(month && year){
        updateModalCalendar(null,month,year);
    }
}

function modalTracking(category,action,label){
    window.dataLayer.push({
        event: "filter_interaction",
        category: category,
        action: action,
        label: label
    });
}

function getMonthFromString(mon){
    return new Date(Date.parse(mon +" 1, 2012")).getMonth()+1
}