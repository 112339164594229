(() => {
    window.dataLayer = window.dataLayer || [];
    const opUtils = window.optimizely.get('utils');

    runAfterBoardBasisUpdate(appendLabels);

    function appendLabels() {
        opUtils.waitForElement('.board-basis').then((boardBasisElement) => {
            const boardOptionElements = Array.from(
                boardBasisElement.querySelectorAll('.option-item--board-basis')
            );
            let impressionString = !boardOptionElements.length ? "Not available" : "";

            boardOptionElements.forEach((option, i) => {
                const optionLabel = option.querySelector('.option-item__title').innerText.trim();
                const optionMoreInfoLink = option.querySelector('.option-item__see-more');

                if (optionMoreInfoLink) {
                    optionMoreInfoLink.addEventListener('click', e => {
                        pushToDatalayer('Board basis - more info link', `${e.target.innerText.trim() === "close" ? "Close" : "Open"}`, optionLabel);
                    });
                }

                // Add value to string that is used for tracking
                impressionString += ` ${i === 0 ? '' : '| '}${optionLabel}${option.classList.contains('option-item--selected') ? '(default)' : ''}`;
                impressionString = impressionString.trim();
            });

            runWhenElementInView(boardBasisElement, () => pushToDatalayer('Board basis available', 'Impression', impressionString));
        });
    }

    function pushToDatalayer(cat = '', action = '', label = '') {
        window.dataLayer.push({
            event: 'holiday_options',
            category: cat,
            action: action,
            label: label,
        });
    }

    function runWhenElementInView(element, callback) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback();
                    observer.disconnect();
                }
            });
        });

        observer.observe(element);
    }

    function runAfterBoardBasisUpdate(callback) {
        const opUtils = window.optimizely.get('utils');
        let hasRanCallback = false;

        // Helper function to run the callback and reset the flag
        const runCallbackOnce = () => {
            if (!hasRanCallback) {
                hasRanCallback = true;
                opUtils.waitForElement('.option-item--board-basis').then(() => {
                    callback();
                    hasRanCallback = false; // Reset the flag after the callback is executed
                });
            }
        };

        // Observe mutations removals in the results container
        const observer = new MutationObserver((mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    // If a 'detailed-card' is removed, trigger the callback as well
                    if (
                        (mutation.removedNodes && mutation.removedNodes[0] && mutation.removedNodes[0].classList && mutation.removedNodes[0].classList.contains('option-item--board-basis') ||
                            mutation.addedNodes && mutation.addedNodes[0] && mutation.addedNodes[0].classList && mutation.addedNodes[0].classList.contains('option-item--board-basis')) &&
                        !hasRanCallback
                    ) {
                        runCallbackOnce();
                    }

                }
            }
        });

        // Start the initial check when the page first loads
        opUtils.waitForElement('.option-item--board-basis').then(() => {
            runCallbackOnce(); // Run the callback on the initial load


            // Start observing changes in the 'board basis' container
            observer.observe(document.querySelector('.board-basis'), {
                childList: true,
                attributes: true,
                subtree: true
            });
        });
    }

})();




