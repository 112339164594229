(() => {
    const utils = window["optimizely"].get('utils');

    utils.waitForElement('.login-flex').then((loginContainerElement) => {
        const isLoggedIn = document.body.classList.contains('sas-logged-in');
        const title = isLoggedIn ? 'You\'re logged in to myJet2' : 'Log in to myJet2';
        pushToDatalayer('myJet2 Info Banner', 'Impression', title);
    })

    function pushToDatalayer(cat = '', action = '', label = '') {
        window.dataLayer.push({
            event: 'mmb',
            category: cat,
            action: action,
            label: label,
        });
    }
})();