function createDomElement(type, classes, content) {
    const element = document.createElement(type);
    if(classes.length > 0) {
        for (let index = 0; index < classes.length; index++) {
            const clss = classes[index];
            element.classList.add(clss);
        }
    }
    content ? element.innerHTML = content : '';
    return element;
}

module.exports =  createDomElement;
