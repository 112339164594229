console.log(
  '%c BF226 In resort fee info on SR v27',

  'background-color: #50BFE6; color: white; font-weight: bold;'
);

const utils = window.optlyUtils || window.globalUtils;
const opUtils = window.optimizely.get('utils');
let topScrollOffset = 0;
let closeIconClicked = false;

utils.poll(
  [
    '.detailed-card-wrapper .detailed-card',
    '.resort-fees-modal',
    '[data-component*="ResultsContainer"]',
  ],
  ([SearchResultCards, FeesModal, ResultsContainer]) => {
    const FeesModalCloseIcon = FeesModal.querySelector(
      '.modal-box__button-close'
    );

    FeesModalCloseIcon.addEventListener('click', () => {
      closeIconClicked = true;
      pushToDatalayer('In-resort fees modal', 'Click', 'Close icon');
    });

    SearchResultCards.forEach((card) => {
      setUpResortFeeTrigger(card, FeesModal);
    });

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.attributeName === 'style' &&
          mutation.target.classList.contains('fees-modal-box--active') &&
          mutation.target.classList.contains('velocity-animating')
        ) {
          mutation.target.classList.remove('fees-modal-box--active');
          document.body.style.top = '';
          window.scrollTo(0, topScrollOffset);
          document.body.classList.remove('is-blocked');
          document.documentElement.classList.remove('page-blocked');

          if (!closeIconClicked) {
            pushToDatalayer(
              'In-resort fees modal',
              'Click',
              'Close outside modal'
            );
          }

          closeIconClicked = false;
        } else if (
          mutation.attributeName === 'style' &&
          mutation.target.classList.contains('fees-modal-box--active') &&
          mutation.target.style.display === 'block'
        ) {
          pushToDatalayer('In-resort fees modal', 'Impression', 'Opened');
        } else if (
          mutation.attributeName === 'style' &&
          mutation.target.classList.contains('fees-modal-box--active') &&
          mutation.target.style.display === 'none'
        ) {
          mutation.target.classList.remove('fees-modal-box--active');
          document.body.style.top = '';
          window.scrollTo(0, topScrollOffset);
          document.body.classList.remove('is-blocked');
          document.documentElement.classList.remove('page-blocked');

          setTimeout(() => {
            if (!closeIconClicked) {
              pushToDatalayer(
                'In-resort fees modal',
                'Click',
                'Close outside modal'
              );
            }
          }, 100);

          closeIconClicked = false;
        }
      });
    });

    observer.observe(FeesModal, { attributes: true });

    const ResultsObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const targetElement = mutation.target;
          if (targetElement.classList.contains('show-loader')) {
            opUtils
              .waitForElement(
                '.full-screen-loader[data-spin]:not(.full-screen-loader--active)'
              )
              .then(() => {
                utils.poll(
                  [
                    '.detailed-card-wrapper .detailed-card',
                    '.resort-fees-modal',
                    '[data-component*="ResultsContainer"]',
                  ],
                  ([SearchResultCards, FeesModal, ResultsContainer]) => {
                    SearchResultCards.forEach((card) => {
                      setUpResortFeeTrigger(card, FeesModal);
                    });
                  }
                );
              });
          }
        }
      });
    });

    ResultsObserver.observe(ResultsContainer, {
      attributes: true,
      attributeFilter: ['class'],
    });

    document.body.classList.add('bf226');
  }
);

function setUpResortFeeTrigger(resultCard = false, FeesModal = false) {
  if (!resultCard) {
    return;
  }

  const ResortFeeText = resultCard.querySelector(
    'p.price-column__supplement-text'
  );

  if (ResortFeeText) {
    ResortFeeText.classList.add('resort-fee-text', 'resort-fees-modal-trigger');

    ResortFeeText.addEventListener('click', (event) => {
      let ResortFeeLinkText = event.target.innerText;
      ResortFeeLinkText = ResortFeeLinkText.replace('*', '');

      pushToDatalayer('Card link', 'Click', ResortFeeLinkText);

      if (FeesModal) {
        showResortFeesModal(FeesModal);
      }
    });
  }
}

function eventWithLabelExistsInDataLayer(eventName, label) {
  return window.dataLayer.some(
    (event) => event.event === eventName && event.label === label
  );
}

function showResortFeesModal(FeesModal) {
  FeesModal.style.display = 'block';
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  topScrollOffset = parseInt(scrollTop || '0', 10);
  document.body.style.top = `-${topScrollOffset}px`;
  document.body.classList.add('is-blocked');
  document.documentElement.classList.add('page-blocked');
  FeesModal.classList.add('fees-modal-box--active');
}

function pushToDatalayer(cat = '', action = '', label = '') {
  window.dataLayer.push({
    event: 'SR_Card',
    category: cat,
    action: action,
    label: label,
  });
}
