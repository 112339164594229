/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function() {
    //console.log("%c| MJ013 SMS Sign Up Banner J2H - Search Results V1 |", "background-color: #50BFE6; color: white; font-weight: bold;");
    var utils = window.optlyUtils || window.globalUtils;
    var optlyUtils = window["optimizely"].get("utils");
    utils.poll([".sitecore-form",".email-signup__wrapper"],([sitecoreForm,emailSignUp]) => {
        const emailSignUpForm = emailSignUp.querySelector(".email-signup__col.email-signup__col--right");
        emailSignUpForm.insertAdjacentHTML("beforeend", getHtml());
        addValidation();
        impressionEvent();
        revisitingPage();// Should be the last to call

        document.body.classList.add("MJ013");
    });
    function getHtml() {
        return `<div class="signup-container wrapper">
                    <div class="signup-container__wrapper">
                        <p class="signup-container__sub-description">Get discounts, destination updates and inspiration straight to your inbox or text messages!*</p>
                        <div class="signup-container__form-section" id="form-section">
                            <div class="signup-container__form">
                                <h1 class="signup-container__title">Sign up for our emails & texts now!</h1>
                                <p class="signup-container__description">Get discounts, destination updates and inspiration straight to your inbox or text messages!*</p>
                                <div class="signup-container__input-group">
                                <div class="signup-container__input-label"><svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="signup-container__input-label--icons">
                                <path d="M14.75 0.875H1.25C1.10082 0.875 0.957742 0.934263 0.852252 1.03975C0.746763 1.14524 0.6875 1.28832 0.6875 1.4375V11C0.6875 11.2984 0.806026 11.5845 1.017 11.7955C1.22798 12.0065 1.51413 12.125 1.8125 12.125H14.1875C14.4859 12.125 14.772 12.0065 14.983 11.7955C15.194 11.5845 15.3125 11.2984 15.3125 11V1.4375C15.3125 1.28832 15.2532 1.14524 15.1477 1.03975C15.0423 0.934263 14.8992 0.875 14.75 0.875ZM8 6.86211L2.69633 2H13.3037L8 6.86211ZM5.94055 6.5L1.8125 10.2835V2.71648L5.94055 6.5ZM6.77305 7.26289L7.6168 8.03984C7.72057 8.13511 7.85632 8.18797 7.99719 8.18797C8.13806 8.18797 8.2738 8.13511 8.37758 8.03984L9.22133 7.26289L13.2995 11H2.69633L6.77305 7.26289ZM10.0595 6.5L14.1875 2.71578V10.2842L10.0595 6.5Z" fill="black"/>
                                </svg><p>Enter your email address</p></div>
                                <input class="signup-container__input" type="email" id="email" required>
                                <p class="validation-message validation-message--active" style="display:none">Please enter a valid email address</p>
                                </div>
                                <div class="signup-container__input-group">
                                <div class="signup-container__input-label"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="signup-container__input-label--icons">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63868 0.91497C2.35338 0.401456 3.34718 0.509471 3.4618 0.509471C3.57641 0.509471 3.65973 0.499061 3.8889 0.862981C4.11807 1.2269 5.70158 3.98204 5.70158 3.98204C5.70158 3.98204 6.07666 4.38754 5.48285 4.91778C4.88903 5.44801 3.941 6.25901 3.941 6.25901C3.941 6.25901 3.55555 6.58128 3.941 7.23632C4.32644 7.89135 4.97235 9.10778 6.24331 10.397C7.51426 11.6862 9.50408 12.6635 9.50408 12.6635C9.50408 12.6635 9.8791 12.913 10.2229 12.5595C10.5667 12.2061 11.6189 10.9896 11.6189 10.9896C11.6189 10.9896 12.0043 10.5009 12.6294 10.8753C13.2544 11.2495 15.8276 12.7051 15.8276 12.7051C15.8276 12.7051 16.161 12.7779 15.9005 13.8696C15.6401 14.9612 15.4318 15.2316 14.9421 15.5019C14.4525 15.7722 13.4524 16.5 12.4002 16.5C11.348 16.5 10.4521 16.2505 9.08734 15.6994C7.72263 15.1484 5.76411 14.1815 3.20133 11.2911C0.638559 8.40076 0.003081 4.89702 0.003081 4.89702C0.003081 4.89702 -0.0593794 3.41023 0.37816 2.62006C0.815699 1.82988 1.11782 1.28924 1.63868 0.91497Z" fill="#203240"/>
                                </svg><p>Enter your mobile phone number (optional)</p></div>
                                <input class="signup-container__input" type="tel" id="phone">
                                <p class="validation-message validation-message--active" style="display:none"></p>
                                </div>
                                <button class="signup-container__button" id="signup-button">Sign up</button>
                            </div>
                        </div>
                        <div class="signup-container__form-section signup-container__form-section--success" id="success-form" style="display:none;">
                            <div class="signup-container__form">
                                <h1 class="signup-container__title">You’re signed up to get emails<span id="success-form-title-text"></span></h1>
                                <p class="signup-container__description">You’ll get discounts, destination updates and inspiration straight to your emails<span id="success-form-description-text"></span></p>
                                <button class="signup-container__button signup-container__button--success" id="signedup-button">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.367 10.867 14.5 7 14.5C3.13302 14.5 0 11.367 0 7.5C0 3.63302 3.13302 0.5 7 0.5C10.867 0.5 14 3.63302 14 7.5ZM6.13459 11.1917C6.37559 11.1808 6.59468 11.0493 6.72613 10.8466L10.4069 4.93114C10.6205 4.58607 10.5164 4.13146 10.1714 3.91784C9.82629 3.70423 9.37167 3.80829 9.15806 4.15336L6.04147 9.15962L4.8036 7.52191C4.55712 7.19875 4.09703 7.13302 3.77387 7.3795C3.4507 7.62598 3.38498 8.08607 3.63146 8.40923L5.51565 10.8959C5.65258 11.0822 5.87167 11.1862 6.10172 11.1862C6.11268 11.1917 6.12363 11.1917 6.13459 11.1917Z" fill="white"/>
                                </svg>
                                <p>Signed up</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>`;
    }
    function addValidation() {
        const emailInput = document.querySelector('.signup-container #email');
        const phoneInput = document.querySelector('.signup-container #phone');
        let emailSubmissionCheck = false,
            phoneSubmissionCheck = false;

        function validateEmail() {
            const emailInputValue = emailInput.value.trim();
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
            // Reset emailSubmissionCheck before validating
            emailSubmissionCheck = true;
            // Check if the email is empty
            if (!emailInputValue) {
                emailInput.nextElementSibling.innerHTML = "Please enter a valid email address";
                emailInput.nextElementSibling.style.display = ""; // Show the element
                emailSubmissionCheck = false;
            } else if (!emailPattern.test(emailInputValue)) {
                emailInput.nextElementSibling.innerHTML = "Please enter a valid email address";
                emailInput.nextElementSibling.style.display = ""; // Show the element
                emailSubmissionCheck = false;
            } else {
                emailInput.nextElementSibling.style.display = "none"; // Hide the element
                emailSubmissionCheck = true;
            }
        }
            
        
        function validatePhone() {
            const phoneInputValue = phoneInput.value.trim(); // Trim whitespace
            // Reset phoneSubmissionCheck before validating
            phoneSubmissionCheck = true;
            const phonePattern = /^\+?\d{11,}$/;
            if (phoneInputValue && !phonePattern.test(phoneInputValue)) {
                phoneInput.nextElementSibling.innerHTML = "Please enter a valid phone number";
                phoneInput.nextElementSibling.style.display = ""; // Show the element
                phoneSubmissionCheck = false;
            }else if (!phoneInputValue) {
                //phoneInput.nextElementSibling.style.display = ""; // Show the element
                phoneSubmissionCheck = false;
            } else {
                phoneInput.nextElementSibling.style.display = "none"; // Hide the element
                phoneSubmissionCheck = true;
            }
        }
        // Trigger validation when the user leaves the input fields
        emailInput.addEventListener('blur', function(event) {
            if (event.relatedTarget !== document.getElementById('signup-button')) {
            validateEmail();
            }
        });
        
        phoneInput.addEventListener('blur', function(event) {
            if (event.relatedTarget !== document.getElementById('signup-button')) {
            validatePhone();
            }
        });

        document.getElementById('signup-button').addEventListener('click', function(event) {
            setTimeout(function() {
                // Prevent the default form submission
                event.preventDefault();
                // Reset validation flags
                emailSubmissionCheck = true;
                phoneSubmissionCheck = true;
                validateEmail();
                validatePhone();
                let formSection = document.getElementById("form-section"),
                    successForm = document.getElementById("success-form"),
                    successFormTitleText = document.getElementById('success-form-title-text'),
                    successFormDescriptionText = document.getElementById('success-form-description-text');
                window.dataLayer = window.dataLayer || [];
                if (emailSubmissionCheck && phoneSubmissionCheck) {
                    //Both Email and Phone number are submitted
                    formSection.style.display = "none";
                    successForm.style.display = "";
                    sessionStorage.setItem('signedUpFlag', 'true');
                    successFormTitleText.innerHTML = " and texts!";
                    successFormDescriptionText.innerHTML = " and texts!";
                    transferAndSubmit();
                    window.dataLayer.push({
                        event: "email_signup",
                        category: "Email sign up",
                        action: "Successful",
                        label: "You’re signed up to get emails and texts"
                    });
                } else if (emailSubmissionCheck && !phoneSubmissionCheck) {
                    //Just Email is submitted
                    formSection.style.display = "none";
                    successForm.style.display = "";
                    successFormTitleText.innerHTML = "!";
                    successFormDescriptionText.innerHTML = "!";
                    transferAndSubmit();
                    window.dataLayer.push({
                        event: "email_signup",
                        category: "Email sign up",
                        action: "Successful",
                        label: "You’re signed up to get emails"
                    });
                } else if (!emailSubmissionCheck && phoneSubmissionCheck && phoneInput.value.length !== 0) {
                    emailInput.nextElementSibling.innerHTML = "To enter a phone number, you must also enter a valid email address";
                    emailInput.nextElementSibling.style.display = "";
                    window.dataLayer.push({
                        event: "email_signup",
                        category: "Validation Message",
                        action: "Impression",
                        label: "To enter a phone number, you must also enter a valid email address"
                    });
                } else if (!emailSubmissionCheck && !phoneSubmissionCheck){
                    emailInput.nextElementSibling.style.display = "";
                    //phoneInput.nextElementSibling.style.display = "";
                    window.dataLayer.push({
                        event: "email_signup",
                        category: "Validation Message",
                        action: "Impression",
                        label: "Please enter a valid email address"
                    });
                }
            },0);
        });

    }
    function revisitingPage() {
        optlyUtils.waitForElement("#form-section", "#success-form").then(function () {
            if (sessionStorage.getItem('signedUpFlag') === 'true') {
                document.getElementById("form-section").style.display = "none";
                document.getElementById("success-form").style.display = "";
            }
        });
    }
    function transferAndSubmit() {
        const signupInputs = document.querySelectorAll(".signup-container .signup-container__input");
        const sitecoreInputs = document.querySelectorAll(".sitecore-form .sitecore-form__input");

        if (signupInputs.length >= 2 && sitecoreInputs.length >= 2) {
            sitecoreInputs[0].value = signupInputs[0].value; // Email
            sitecoreInputs[1].value = signupInputs[1].value; // Phone number
    
            const submitButton = document.querySelectorAll(".sitecore-form .form-column")[2].querySelector("input");
            if (submitButton) {
                const clickEvent = new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                });
                submitButton.dispatchEvent(clickEvent);
            }            
        }
        
    }
    function impressionEvent() {
        const target = document.querySelector('.email-signup__conditions');

        const options = {
        root: null, // Observe relative to the viewport
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the element is visible
        };

        const callback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "email_signup",
                    category: "Email sign up",
                    action: "Impression",
                    label: "Sign up for our emails & texts now!"
                });

            observer.unobserve(entry.target);
            }
        });
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
       
    }
})();