/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function() {

    var utils = window.optlyUtils || window.globalUtils;
    utils.poll(['div[data-component*="EditSearchContainer"]','.sticky-search'],([editSearchPanel,stickySearch]) => {
        var searchBox = document.querySelector('.edit-search'),
            headerDetails = document.querySelector('.header-details');
            
        // Move the searchBox before the headerDetails
        headerDetails.parentNode.insertBefore(searchBox, headerDetails);

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    handleScreenSizeChange();
                }
            }
        };

        const observer = new MutationObserver(callback);
        const targetNode = document.querySelector(".edit-search");
        const config = {
            attributes: true, // Listen to attribute changes
            attributeFilter: ['class'], // Specifically for 'class' attribute changes
            subtree: true // Include all descendants
        };

        handleScreenSizeChange();
        observer.observe(targetNode, config);
        tabsScrollAnchorClickEvents();
        intersectionObserverFooter();

        document.body.classList.add("JD105");
    });
    function handleScreenSizeChange() {
        const screenWidth = window.innerWidth;
        const mobileThreshold = 768; 
    
        if (screenWidth < mobileThreshold) { 
            // Screen size changed to mobile
            getSearchPanelHeight('mobile');

        } else {
            const shareIcon = document.querySelector('.edit-search .sr-details');
            if (shareIcon) {
                let newContainer = document.querySelector('.header-details .header-details__bottom');
                let wrapper = document.createElement('div');
                wrapper.className = 'wrapper clearfix';
                wrapper.appendChild(shareIcon);
                newContainer.appendChild(wrapper);
            }

            // Screen size changed to desktop
            getSearchPanelHeight();
        }
    }
    function getSearchPanelHeight() {
        //Assigning height dynamically
        const editSearchPanel = document.querySelector(".edit-search");
        const tabsSticky = document.querySelector(".tabs-sticky");
        let editSearchHeight = editSearchPanel.offsetHeight;

        if (arguments[0] === 'mobile') {
            editSearchHeight = editSearchHeight - 1;
        }
        if (editSearchPanel.getAttribute('data-open-mobile') === 'false') {
            tabsSticky.style.top = `${editSearchHeight}px`;
            document.querySelector(".sticky-search").style.overflowX = 'unset';
        } else {
            document.querySelector(".sticky-search").style.overflowX = 'hidden';
            tabsSticky.style.top = '0px';
        }
    }
    function tabsScrollAnchorClickEvents() {

        const anchorTags = document.querySelectorAll('#tabs-scroll-anchor .tabs-nav__link');

        anchorTags.forEach(anchor => {
            anchor.addEventListener('click', () => {
                const editSearchPanel = document.querySelector(".edit-search");
                let editSearchHeight = editSearchPanel.offsetHeight;
                if (editSearchPanel.getAttribute('data-open-mobile') === 'false') {
                    window.scrollBy(0, -(editSearchHeight));
                }
            });
        });
    }
    function intersectionObserverFooter() {
        const editSearchPanel = document.querySelector(".edit-search");
        const navBarTabs = document.querySelector('#tabs-scroll-anchor .tabs-sticky');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    navBarTabs.style.top = '0';
                } else {
                    let editSearchHeight = editSearchPanel.offsetHeight;
                    navBarTabs.style.top = `${editSearchHeight}px`;
                }
            });
        }, { threshold: [0] });

        observer.observe(editSearchPanel);
    }
})();