/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
  console.log(
    '%c BF228 v45 - holiday details',

    'background-color: #ff1493; color: white; font-weight: bold;'
  );

  const utils = window.optlyUtils || window.globalUtils;
  const opUtils = window.optimizely.get('utils');
  let modalShown = false;

  const DISCOUNT_CODES = {
    '31/03/2025': '12D775XCD',
    '01/04/2025': '12D775XCE',
    '02/04/2025': '12D775XCF',
    '03/04/2025': '12D775XCG',
    '04/04/2025': '12D775XCH',
    '05/04/2025': '12D775XCI',
    '06/04/2025': '12D775XCJ',
    '07/04/2025': '12D775XCK',
  };

  utils.poll(
    [
      '.site-content [data-component="Content/Form/EmailSignup"] .input-button__container p:not([v-cloak])',
    ],
    ([vCheck]) => {
      // Check if user is logged into MyJet2
      const MyJet2LoggedIn = document
        .querySelector('body')
        .classList.contains('sas-logged-in');

      if (MyJet2LoggedIn) {
        console.log('User is logged into MyJet2');
        return;
      }

      // Check if the user has seen the modal
      const DateSeenModal = localStorage.getItem('DateSeenModal');

      //if modal has been seen check if it was today that they saw it
      if (DateSeenModal) {
        console.log('Modal has been seen');
        //check time and compare to today - if same day then show coupon in basket
        const CurrentTimeDate = new Date();

        const DateSeenModalDateTime = convertDateSeenModal(DateSeenModal);

        //if modal has been seen check if it was today that they saw it
        if (
          CurrentTimeDate.toDateString() ===
          DateSeenModalDateTime.toDateString()
        ) {
          console.log('modal seen and still the same day');
          // Show coupon in basket becuase they have seen the modal today and it still the same day
          opUtils
            .waitForElement('.basket-summary__totals-top .cta-countdown-block')
            .then(function (basketMessage) {
              if (basketMessage) {
                // Update the basket message with the coupon code
                updateBasketMessage(basketMessage);
              }
            })
            .catch(function (error) {
              console.error('Error waiting for element:', error);
              // Handle any errors that occur during the promise execution
            });
        } else {
          console.log('modal seen but NOT the same day');
        }
      } else {
        console.log('Modal has NOT been seen');
        createModal();
        inactivityTimer();
      }

      // Functions

      function pushToDatalayer(action = '', label = '') {
        if (action && label) {
          window.dataLayer.push({
            event: 'discount_offer_modal',
            category: 'Winter offer banner',
            action: action,
            label: label,
          });
        }
      }

      function getTodaysCode() {
        const Today = new Date();
        const FormattedDate = Today.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });

        const TodaysCode = DISCOUNT_CODES[FormattedDate];

        if (TodaysCode) {
          return TodaysCode;
        } else {
          return '';
        }
      }

      function updateBasketMessage(basketMessage = false) {
        if (basketMessage) {
          if (basketMessage.length > 1) {
            basketMessage.forEach((message) => {
              message.querySelector(
                '.cta-countdown-block__heading'
              ).innerHTML = `Save an extra £60 on this booking using code <b>${getTodaysCode()}</b> at checkout today`;
            });
          } else {
            basketMessage.querySelector(
              '.cta-countdown-block__heading'
            ).innerHTML = `Save an extra £60 on this booking using code <b>${getTodaysCode()}</b> at checkout today`;
          }
        }
      }

      // create new date object from date seen in local storage in order to compare to current date
      function convertDateSeenModal(DateSeenModal) {
        const [date, time] = DateSeenModal.split('|');
        const [day, month, year] = date.split('/');
        const [hours, minutes, seconds] = time.split(':');
        return new Date(year, month - 1, day, hours, minutes, seconds);
      }

      // Create modal html
      function createModal() {
        const ModalContent = getTodaysCode()
          ? `
          <div class="modal-img-container">
            <img class="modal-img" src="https://media.jet2.com/is/image/jet2/821267-fao_vale_de_lobo_golf_courses_636247404_getty" alt="" />
          </div>

          <div class="modal-content">
            <h2 class="heading">Save £60 if you book before midnight!</h2>

            <p class="text">Enter the below code at checkout to bag a saving on your winter holiday.</p>

            <div class="voucher-container">
              <input type="text" id="voucherCode" value="${getTodaysCode()}" readonly>
              <button id="copyBtn">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_28245_46592)"><path d="M4.16699 12.5C3.39042 12.5 3.00214 12.5 2.69585 12.3731C2.28747 12.204 1.96302 11.8795 1.79386 11.4711C1.66699 11.1649 1.66699 10.7766 1.66699 10V4.33333C1.66699 3.39991 1.66699 2.9332 1.84865 2.57668C2.00844 2.26308 2.2634 2.00811 2.57701 1.84832C2.93353 1.66667 3.40024 1.66667 4.33366 1.66667H10.0003C10.7769 1.66667 11.1652 1.66667 11.4715 1.79353C11.8798 1.96269 12.2043 2.28715 12.3735 2.69553C12.5003 3.00181 12.5003 3.3901 12.5003 4.16667M10.167 18.3333H15.667C16.6004 18.3333 17.0671 18.3333 17.4236 18.1517C17.7372 17.9919 17.9922 17.7369 18.152 17.4233C18.3337 17.0668 18.3337 16.6001 18.3337 15.6667V10.1667C18.3337 9.23325 18.3337 8.76654 18.152 8.41002C17.9922 8.09641 17.7372 7.84145 17.4236 7.68166C17.0671 7.5 16.6004 7.5 15.667 7.5H10.167C9.23357 7.5 8.76686 7.5 8.41034 7.68166C8.09674 7.84145 7.84177 8.09641 7.68198 8.41002C7.50033 8.76654 7.50033 9.23325 7.50033 10.1667V15.6667C7.50033 16.6001 7.50033 17.0668 7.68198 17.4233C7.84177 17.7369 8.09674 17.9919 8.41034 18.1517C8.76686 18.3333 9.23357 18.3333 10.167 18.3333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_28245_46592"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>
                <p>Copy</p>
              </button>
            </div>
            
            <p class="terms">Valid until midnight. Promo T&Cs apply.</p>
          </div>`
          : `<h2 class="heading">No code available today</h2>`;

        // Create the modal overlay (container)
        const ModalOverlay = document.createElement('div');
        ModalOverlay.id = 'bf228ModalOverlay';
        ModalOverlay.classList.add('bf228-modal-overlay');
        ModalOverlay.setAttribute('aria-hidden', 'true');

        // Create the modal container (empty for now, you can add content later)
        const Modal = document.createElement('div');
        Modal.classList.add('bf228modal');
        Modal.setAttribute('role', 'dialog');
        Modal.setAttribute('aria-labelledby', 'modalTitle');
        Modal.setAttribute('aria-describedby', 'modalDescription');
        Modal.innerHTML = ModalContent;

        // Close button inside the modal
        const CloseButton = document.createElement('button');
        CloseButton.id = 'closeModalBtn';
        CloseButton.classList.add('close-btn');
        CloseButton.setAttribute('aria-label', 'Close modal');
        CloseButton.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="basket-summary__list-icon-image"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use></svg>
          Close
        `;

        // Append the close button to the modal
        Modal.appendChild(CloseButton);

        // Append modal container to the modal overlay
        ModalOverlay.appendChild(Modal);

        // Append modal overlay to the body of the document
        document.body.appendChild(ModalOverlay);

        // Add event listener to close the modal when the button is clicked
        CloseButton.addEventListener('click', () => {
          ModalOverlay.style.display = 'none';
          ModalOverlay.setAttribute('aria-hidden', 'true');

          const ModalHeadingText = ModalOverlay.querySelector(
            'modal-content h2.heading'
          );

          if (ModalHeadingText) {
            pushToDatalayer('Close', `${ModalHeadingText}`);
          }

          // document.querySelector('button').focus(); // Replace with actual element you want focus to return to
        });

        // Close the modal if the user clicks outside the modal content
        ModalOverlay.addEventListener('click', (e) => {
          if (e.target === ModalOverlay) {
            CloseButton.click();
          }
        });

        // Close modal with 'Escape' key press
        document.addEventListener('keydown', (e) => {
          if (e.key === 'Escape' && ModalOverlay.style.display === 'flex') {
            CloseButton.click();
          }
        });
      }

      function openModal() {
        const ModalOverlay = document.getElementById('bf228ModalOverlay');
        ModalOverlay.style.display = 'flex';
        ModalOverlay.setAttribute('aria-hidden', 'false');
        // add event listener to copy button
        copyTextEvent();

        const ModalHeadingText = ModalOverlay.querySelector(
          'modal-content h2.heading'
        );

        if (ModalHeadingText && getTodaysCode()) {
          pushToDatalayer(
            'Impression',
            `${ModalHeadingText} | ${getTodaysCode()}`
          );
        }

        // also update the basket message
        opUtils
          .waitForElement('.basket-summary__totals-top .cta-countdown-block')
          .then(function (basketMessage) {
            if (basketMessage) {
              // Update the basket message with the coupon code
              updateBasketMessage(basketMessage);
            }
          })
          .catch(function (error) {
            console.error('Error waiting for element:', error);
            // Handle any errors that occur during the promise execution
          });
      }

      function copyTextEvent() {
        // Get the elements
        const CopyBtn = document.getElementById('copyBtn');
        const VoucherCodeInput = document.getElementById('voucherCode');
        // const SuccessMessage = document.getElementById('successMessage');

        // const ShowSuccessMessage = (SuccessMessage) => {
        //   SuccessMessage.style.display = 'block';

        //   // Hide the success message after 2 seconds
        //   setTimeout(() => {
        //     SuccessMessage.style.display = 'none';
        //   }, 2000);
        // };

        // Add event listener for the copy button
        CopyBtn.addEventListener('click', async () => {
          if (navigator.clipboard && navigator.clipboard.writeText) {
            try {
              // Use the Clipboard API to copy the value of the input field
              await navigator.clipboard.writeText(VoucherCodeInput.value);

              CopyBtn.classList.add('copied-code');
              CopyBtn.innerHTML = `
                <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5C19 14.748 14.748 19 9.5 19C4.25196 19 0 14.748 0 9.5C0 4.25196 4.25196 0 9.5 0C14.748 0 19 4.25196 19 9.5ZM8.32551 14.5102C8.65258 14.4953 8.94992 14.3169 9.12833 14.0419L14.1236 6.01369C14.4135 5.54538 14.2723 4.9284 13.804 4.6385C13.3357 4.34859 12.7187 4.48983 12.4288 4.95814L8.19914 11.7523L6.51917 9.52973C6.18466 9.09116 5.56025 9.00196 5.12167 9.33646C4.6831 9.67097 4.5939 10.2954 4.9284 10.734L7.48552 14.1088C7.67136 14.3615 7.9687 14.5027 8.28091 14.5027C8.29577 14.5102 8.31064 14.5102 8.32551 14.5102Z" fill="#fff"></path></svg>
                Copied!  
              `;
              // Show the success message
              // ShowSuccessMessage(SuccessMessage);
            } catch (err) {
              console.error('Clipboard write failed:', err);
            }
          } else {
            // Select the input field content
            VoucherCodeInput.select();
            VoucherCodeInput.setSelectionRange(0, 99999); // For mobile devices

            try {
              // Execute the copy command
              document.execCommand('copy');

              CopyBtn.innerHTML = `
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.5C19 14.748 14.748 19 9.5 19C4.25196 19 0 14.748 0 9.5C0 4.25196 4.25196 0 9.5 0C14.748 0 19 4.25196 19 9.5ZM8.32551 14.5102C8.65258 14.4953 8.94992 14.3169 9.12833 14.0419L14.1236 6.01369C14.4135 5.54538 14.2723 4.9284 13.804 4.6385C13.3357 4.34859 12.7187 4.48983 12.4288 4.95814L8.19914 11.7523L6.51917 9.52973C6.18466 9.09116 5.56025 9.00196 5.12167 9.33646C4.6831 9.67097 4.5939 10.2954 4.9284 10.734L7.48552 14.1088C7.67136 14.3615 7.9687 14.5027 8.28091 14.5027C8.29577 14.5102 8.31064 14.5102 8.32551 14.5102Z" fill="#098C09"></path></svg>
                Copied!  
              `;
              // Show the success message
              // showSuccessMessage(SuccessMessage);
            } catch (err) {
              console.error('Fallback copy failed:', err);
            }
          }

          VoucherCodeInput.blur(); // Deselect text

          const ModalHeadingText = document.querySelector(
            '#bf228ModalOverlay modal-content h2.heading'
          );

          if (ModalHeadingText && getTodaysCode()) {
            pushToDatalayer('Copy', `${ModalHeadingText} | ${getTodaysCode()}`);
          }
        });
      }

      // Inactivity timer
      function inactivityTimer() {
        let timer;
        let countdownInterval;

        function resetTimer() {
          if (modalShown) {
            return;
          }

          clearTimeout(timer);
          clearInterval(countdownInterval);

          // let countdown = 10; // 10 seconds

          // for each second, log the remaining time in the countdown
          // countdownInterval = setInterval(() => {
          //   console.log(`${countdown} seconds remaining`);
          //   countdown--;
          //   if (countdown < 0) {
          //     clearInterval(countdownInterval);
          //   }
          // }, 1000); // 1 second in milliseconds

          // Set timer to show modal after 10 seconds of inactivity
          timer = setTimeout(() => {
            const CurrentDateTime = new Date();

            const EndOfToday = new Date();
            EndOfToday.setHours(23, 50, 0, 0); // Set time to 23:50 today

            const NextDay = new Date();
            NextDay.setDate(NextDay.getDate() + 1);
            NextDay.setHours(0, 10, 0, 0); // Set time to 00:10 on next day

            // Check if current date and time is between endOfToday and nextDay before showing modal - if it is the don't show modal
            if (CurrentDateTime >= EndOfToday && CurrentDateTime <= NextDay) {
              console.log(
                'Current date and time is between endOfToday and nextDay'
              );
            } else {
              // Show modal, update basket message and set local storage item
              localStorage.setItem(
                'DateSeenModal',
                `${CurrentDateTime.toLocaleDateString(
                  'en-GB'
                )}|${CurrentDateTime.toLocaleTimeString('en-GB')}`
              );

              clearInterval(countdownInterval);

              openModal();

              modalShown = true;
            }
          }, 10000); // 10 seconds in milliseconds
        }

        // Reset timer on any interaction
        ['click', 'keypress', 'scroll', 'touchstart'].forEach((event) => {
          document.addEventListener(event, (e) => {
            if (!DateSeenModal) {
              resetTimer();
            }
          });
        });

        if (!DateSeenModal) {
          // Initialize the timer
          resetTimer();
        }
      }
    }
  );
})();
