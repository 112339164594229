const isChildrenSelected = require("./isChildrenSelected");

function checkForGuestsModal(childrenSelected) {
    const form = document.querySelector('[data-component="Feature/SearchPanels/SmartSearchPanel"]');
    const config = { attributes: false , childList: true, subtree: false };

    const hasGuestModal = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes[0]) {
                if ('gac' in mutation.addedNodes[0].dataset) {
                    if (mutation.addedNodes[0].dataset.gac.indexOf('guest-selector-modal') > -1 ) {
                        childGuestsClickHandler(observer);
                    } 
                }
            }
        }
    };

    function childGuestsClickHandler() {
        const childSelector = document.querySelector('.js-form-children-logic'),
            guestModelDoneBtn = document.querySelector('[data-gal="finished-guest-selection"]');

        guestModelDoneBtn.addEventListener('click', () => isChildrenSelected(childSelector, childrenSelected));   

        observer.disconnect();
    }

    const observer = new MutationObserver(hasGuestModal);
    observer.observe(form, config);
}

module.exports =  checkForGuestsModal;