/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
  console.log(
    "%c| BF218 - FLEXI HP VARIATION 2 v1.14 |",
    "background-color: #50BFE6; color: white; font-weight: bold;"
  );
  var utils = window.optlyUtils || window.globalUtils;
  utils.poll(["#search-box-leaving"], ([searchBoxLeaving]) => {
    (function () {
      let flexiWrapper;
      let prevDisplayState = null; // Track the last known display state of the modal

      const calendarDateModalEl = Array.from(
        document.querySelectorAll(".modal")
      ).find((modal) => modal.querySelector(".calendar-date-selector"));

      function hideFlexiCheckboxEl() {
        const flexiCheckBoxEl = calendarDateModalEl.querySelector(
          ".checkbox-button-group__item"
        );
        flexiCheckBoxEl.classList.add("hidden");
      }

      function disableFlexiCheckbox() {
        const liveFlexiContainerEl = document.querySelector(
          ".calendar-date-selector .checkbox-button-group__label"
        );
        const liveFlexiCheckboxEl = document.querySelector(
          ".calendar-date-selector [name='flexi-date-checkbox']"
        );
        if (liveFlexiCheckboxEl && liveFlexiCheckboxEl.checked) {
          liveFlexiContainerEl.click();
        }
      }

      function trackModalImpression() {
        if (isModalVisible() && isSmartSearch()) {
          console.log("Modal is visible and smart search is active.");
          const currentDisplayState = calendarDateModalEl.style.display;

          // Push to dataLayer only if the state has changed to `block`
          if (
            currentDisplayState === "block" &&
            (prevDisplayState !== "block" || prevDisplayState === null)
          ) {
            window.dataLayer.push({
              event: "search_panel",
              category: "Calendar Modal",
              action: "Impression",
              label: "Modal Open - Flexible Search",
            });
          }
          // Update the previous state
          prevDisplayState = currentDisplayState;
        } else {
          prevDisplayState = null;
        }
      }

      function isSmartSearch() {
        const calendarDateSelectorEl = document.querySelector(
          ".calendar-date-selector"
        );

        if (!calendarDateSelectorEl) {
          return false;
        }

        // Get all children with class 'checkbox-button-group__item'
        const children = calendarDateSelectorEl.querySelectorAll(
          ".checkbox-button-group__item"
        );

        // Check if all children have 'display: none' inline style
        const allChildrenHidden = Array.from(children).every((child) => {
          const displayStyle = child.style.display;
          return displayStyle !== "none";
        });

        if (!allChildrenHidden) {
          return false;
        }

        // All conditions are met
        return true;
      }

      function isModalVisible() {
        return (
          calendarDateModalEl &&
          window.getComputedStyle(calendarDateModalEl).display !== "none"
        );
      }

      function setupRadioButtons() {
        const anchor = calendarDateModalEl.querySelector(".modal-box__content");
        if (!anchor) {
          console.warn("Anchor not found, retrying in 100ms");
          setTimeout(setupRadioButtons, 100); // Retry after 100ms
          return;
        }

        if (document.querySelector(".flexi-wrapper")) return; // Prevent duplicate injection

        anchor.insertAdjacentHTML(
          "afterend",
          `<div class="flexi-wrapper">
                  <fieldset>
                    <label><input type="radio" name="options" value="notFlexi">Exact date</label>
                    <label><input type="radio" name="options" value="&flexi=1"> ± 1 day</label>
                    <label><input type="radio" name="options" value="&flexi=2"> ± 2 days</label>
                    <label><input type="radio" name="options" value="&flexi=3"> ± 3 days</label>
                  </fieldset>
                </div>`
        );

        flexiWrapper = document.querySelector(".flexi-wrapper");

        const localStorageFlexiValue = localStorage.getItem("dateFlexibility");
        if (localStorageFlexiValue) {
          const selectedRadioButtonEl = document.querySelector(
            `.flexi-wrapper input[value="${localStorageFlexiValue}"]`
          );
          if (selectedRadioButtonEl) selectedRadioButtonEl.checked = true;
        }

        document.querySelectorAll(".flexi-wrapper input").forEach((radio) => {
          radio.addEventListener("change", (e) => {
            if (e.isTrusted) {
              window.dataLayer.push({
                event: "search_panel",
                category: "Calendar Modal",
                action: "Check",
                label: e.target.parentElement.textContent,
              });
            }

            localStorage.setItem("dateFlexibility", e.target.value);
            console.log(`Local storage updated: ${e.target.value}`);
          });
        });
      }

      function observeCalendarModal() {
        const observer = new MutationObserver((mutationsList, observer) => {
          mutationsList.forEach((mutation) => {
            console.log("Mutation observed:", mutation);
           

            if (!flexiWrapper) return;

            trackModalImpression();

            flexiWrapper.style.display =
              isModalVisible() && isSmartSearch() ? "flex" : "none";
          });
        });

        // Start observing parentElement for mutations
        const modalParentEl = document.querySelector(".js-edit-search-form");
        if (modalParentEl) {
          observer.observe(modalParentEl, { attributes: true, subtree: true });
        } else {
          console.error("Parent element not found.");
        }
      }

      init();

      function init() {
        hideFlexiCheckboxEl();
        disableFlexiCheckbox();
        setupRadioButtons();
        trackModalImpression();
        observeCalendarModal();
      }
    })();
    document.body.classList.add("BF218");
  });
})();
