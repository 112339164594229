/**** NOT TO BE EDITED DIRECTLY IN OPTIMIZELY *****/
(function () {
  const utils = window.optlyUtils || window.globalUtils;

  window.dataLayer = window.dataLayer || [];

  utils.poll(['.login-container--app'], ([appPromo]) => {
    appPromo.classList.add('hide-on-desktab');

    const appPromoParent = appPromo.parentElement;

    const newAppPromoComponent = document.createElement('div');
    newAppPromoComponent.setAttribute('id', 'new-app-promo');
    newAppPromoComponent.classList.add(
      'login-container',
      'medium--6',
      'large--6',
      'login-container--app',
      'hide-on-mob'
    );

    const ksps = `
      <div class="ksps ksps-vertical margin--bottom">
        <ul class="list-icons">
          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Add seats, meals and insurance</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Make payments</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Amend your details</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Check in and get your boarding passes</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Read welcome information</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Upload and view travel documents</li>

          <li class="margin--bottom-quarter"><svg class="icon ksp-block__icon--color brand-color margin--right-half" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-tick" xmlns:xlink="http://www.w3.org/1999/xlink"></use></svg>Access 24/7 in-resort support</li>
        </ul>
      </div>
    `;

    newAppPromoComponent.innerHTML = `
        ${ksps}

        <div class="download-app-prompt">
          <div class="download-app-prompt__image">
            <img src="/-/media/Jet2holidays/Images/CRO/mmb/download-qr-1.png" alt="Scan this QR code using your camera app" />
          </div>

          <div class="download-app-prompt__summary">
            <h5>Download our app</h5>
            <p>It has everything you need to <strong>manage your holiday</strong> in one handy place</p>
          </div>
        </div>
    `;

    appPromoParent.appendChild(newAppPromoComponent);
  });
})();
