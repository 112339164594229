(function () {
  const utils = window.optlyUtils || window.globalUtils;

  window.dataLayer = window.dataLayer || [];

  const checkIfLoggedIn = document
    .querySelector('body')
    .classList.contains('sas-logged-in');

  if (!checkIfLoggedIn) {
    // otherwise pass if function to poller which looks for inner text within instant deal side bar and returns true
    utils.poll(
      ['.nav-bar .nav-bar__tabs button.nav-menu__btn'],
      ([theButton]) => {
        document
          .querySelector('body')
          .classList.add('myjet2-split-signin-mj006');

        let dropdownState = '';

        const SignInBtn = document.createElement('a');

        SignInBtn.setAttribute('data-analytics-text', 'Sign in');

        SignInBtn.setAttribute('href', '/api/jet2/login/b2clogin');

        SignInBtn.classList.add(
          'nav-menu__btn',
          'nav-menu__btn--dark',
          'nav-menu__btn--simple-small'
        );

        SignInBtn.innerHTML = `
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="nav-menu__btn-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C10.2051 3.75 8.75 5.20507 8.75 7C8.75 8.79493 10.2051 10.25 12 10.25C13.7949 10.25 15.25 8.79493 15.25 7C15.25 5.20507 13.7949 3.75 12 3.75ZM7.25 7C7.25 4.37665 9.37665 2.25 12 2.25C14.6234 2.25 16.75 4.37665 16.75 7C16.75 9.62335 14.6234 11.75 12 11.75C9.37665 11.75 7.25 9.62335 7.25 7ZM4.64124 15.6412C5.53204 14.7504 6.74022 14.25 8 14.25H16C17.2598 14.25 18.468 14.7504 19.3588 15.6412C20.2496 16.532 20.75 17.7402 20.75 19V21C20.75 21.4142 20.4142 21.75 20 21.75C19.5858 21.75 19.25 21.4142 19.25 21V19C19.25 18.138 18.9076 17.3114 18.2981 16.7019C17.6886 16.0924 16.862 15.75 16 15.75H8C7.13805 15.75 6.3114 16.0924 5.7019 16.7019C5.09241 17.3114 4.75 18.138 4.75 19V21C4.75 21.4142 4.41421 21.75 4 21.75C3.58579 21.75 3.25 21.4142 3.25 21V19C3.25 17.7402 3.75044 16.532 4.64124 15.6412Z" fill="none"></path></svg> <span class="nav-menu__btn-text">Sign in</span> 
      `;

        const theButtonParent = theButton.parentElement;

        theButtonParent.insertBefore(SignInBtn, theButton);

        SignInBtn.addEventListener('click', (event) => {
          window.dataLayer.push({
            event: 'myJet2PortalNavigation',
            category: 'Top Nav',
            action: 'Click',
            label: 'Sign in - CTA',
          });
        });

        theButton.addEventListener('click', (event) => {
          if (
            theButton
              .closest('.nav-menu__dropdown-container')
              .classList.contains('nav-menu__dropdown-container--active')
          ) {
            dropdownState = 'Open';
          } else {
            dropdownState = 'Close';
          }

          window.dataLayer.push({
            event: 'myJet2PortalNavigation',
            category: 'Top Nav',
            action: dropdownState,
            label: 'Sign in - dropdown',
          });
        });
      }
    );
  }
})();
